<template>
  <div class="row g-0">
    <div :class="simple === true ? 'col-md-12' : 'col-md-8'">
      <CFormLabel>{{ option.label }}</CFormLabel>
      <vue3-icon-picker
        v-model="option.value"
        label="Icon Picker"
        v-if="simple === false" />
      <vue3-icon-picker-simple
        v-model="option.value"
        label="Icon Picker"
        v-if="simple === true" />
    </div>
    <div :class="simple === true ? 'd-none' : 'col-md-4'">
      <div class="icon-preview">
        <i :class="option.value"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Vue3IconPicker from '@/components/icon-picker/Vue3IconPicker.vue'
import Vue3IconPickerSimple from '@/components/icon-picker/Vue3IconPickerSimple.vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'IconPickerOption',
  components: {
    Vue3IconPicker,
    Vue3IconPickerSimple,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const option = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { option, v$: useVuelidate() }
  },
}
</script>

<style scoped>
@import '//cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
.icon-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e2e2;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
