<template>
  <div class="container content">
    <h1 class="text-center">CHANGE <span class="bold-font">PASSWORD</span></h1>
    <h6 class="text-muted text-center">Enter your new password</h6>
    <CFormFloating class="mb-4 mt-4">
      <CFormInput
        type="password"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.newPassword.$model"
        @blur="v$.newPassword.$touch"
        :invalid="v$.newPassword.$error" />
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.newPassword.$error"
        :invalid="v$.newPassword.$error">
        Password must be at least 8 characters long.
      </CFormFeedback>
      <CFormLabel for="password">Password</CFormLabel>
    </CFormFloating>
    <CFormFloating class="mb-4 mt-4">
      <CFormInput
        type="password"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.newPasswordConfirm.$model"
        @blur="v$.newPasswordConfirm.$touch"
        :invalid="v$.newPasswordConfirm.$error" />
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.newPasswordConfirm.$error"
        :invalid="v$.newPasswordConfirm.$error">
        Password doesn't match.
      </CFormFeedback>
      <CFormLabel for="confirmpassword">Confirm Password</CFormLabel>
    </CFormFloating>
    <CButton
      class="gStartedbtn col-12 py-2 mb-3"
      @click="resetPassword()"
      :disabled="
        v$.newPasswordConfirm.$error ||
        v$.newPassword.$error ||
        newPasswordConfirm.length == 0 ||
        newPassword.length == 0
      "
      >CHANGE PASSWORD
    </CButton>
    <CAlert v-if="errorMessage.length > 0" color="danger"
      >{{ errorMessage }}
    </CAlert>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import ApiService from '@/services/api.service'

export default {
  name: 'ResetPassword',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      token: '',
      email: '',
      newPassword: '',
      newPasswordConfirm: '',
      errorMessage: '',
    }
  },
  mounted() {
    var url = window.location.href
    this.token = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'))
    this.email = url.substring(url.lastIndexOf('=') + 1)
  },
  methods: {
    resetPassword() {
      ApiService.resetPassword(
        this.email,
        this.newPassword,
        this.newPasswordConfirm,
        this.token,
      )
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: 'Your password has been reset!',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .then(() => {
          this.$router.push({ name: 'Login' })
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message
        })
    },
  },
  validations() {
    return {
      newPassword: {
        required,
        minLength: minLength(8),
      },
      newPasswordConfirm: {
        required,
        sameAs: sameAs(this.newPassword),
      },
    }
  },
}
</script>

<style scoped>
@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}

.content {
  margin-top: 7rem;
}
</style>
