export default {
  methods: {
    editProfileRoute(id, team) {
      document.body.style.overflow = ''
      document.body.style.paddingRight = ''

      if (team != null) {
        this.$router.push({
          name: 'EditTeamProfile',
          params: {
            teamID: team.id,
            id: id,
          },
        })
      } else {
        this.$router.push({
          name: 'EditProfile',
          params: {
            id: id,
          },
        })
      }
      // items.forEach((value) => {
      //   if (value['id'] === id) {
      //     if (value['team']) {
      //        this.$router.push({
      //       name: 'EditTeamProfile',
      //         params: {
      //       teamID: this.teamID,
      //     id: id,
      //     },
      //   })
      // } else {
      //  this.$router.push({
      //   name: 'EditProfile',
      // params: {
      // id: id,
      //              },
      //          })
      //      }
      //  }
      // })
    },
    createNewPersonalProfile() {
      this.$router.push({
        name: 'NewProfile',
      })
    },
    createNewTeamProfile() {
      this.$router.push({
        name: 'NewTeamProfile',
        params: {
          teamID: this.teamID,
        },
      })
    },
  },
}
