<template>
  <loading v-model:active="isLoading" :is-full-page="false" />
  <div class="row">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for products">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <a href="https://bl1nk.co/">
        <Bl1nkButton class="w-100 bl1nk-btn">
          <CIcon :icon="cilPlus" size="lg" />
          Shop BL1NK Products
        </Bl1nkButton>
      </a>
    </div>
    <div
      v-if="this.$store.state.linkedProductStatus.linkedProductStatus != 0"
      class="col-12 mt-3">
      <CAlert color="danger"
        >You have
        {{ this.$store.state.linkedProductStatus.linkedProductStatus }} unlinked
        product.
        <span role="button" class="product-status">Link now</span>
      </CAlert>
    </div>
    <div class="col-12 mt-3" v-for="product in productItems" :key="product.id">
      <Bl1nkHorizontalCard
        @showQr="(product) => showQR(product)"
        @triggerAssignModal="(product) => triggerAssignModal(product)"
        :items="product"></Bl1nkHorizontalCard>
    </div>
  </div>
  <Bl1nkQRModal
    :visible="isQRModalVisible.status"
    title="Share QR Code"
    :id="isQRModalVisible.id"
    :url="isQRModalVisible.url"
    @closed="this.isQRModalVisible.status = false" />
  <AssignModal @closed="triggerAssignModal(null)" :isVisible="isVisible">
    <template #modalHeader>
      <strong>Assign profile to </strong>
      <span class="ms-1">
        {{ modalItem?.owner?.first_name ?? 'N/A' }}
        {{ modalItem?.owner?.last_name ?? 'N/A' }}
      </span>
    </template>

    <template #modalBody>
      <div class="row">
        <div class="col-md-10 col-8">
          <Bl1nkInput
            v-model="modalSearchValue"
            placeholder="Search for profiles">
            <CIcon :icon="cilSearch" size="lg" />
          </Bl1nkInput>
        </div>
        <div class="col-md-2 col-4">
          <Bl1nkButton class="w-100 bl1nk-btn">
            <CIcon :icon="cilFilter" size="xs" />
            Filter
          </Bl1nkButton>
        </div>
        <div class="col-12 my-3" v-for="profile in profiles" :key="profile.id">
          <Bl1nkProfileCard
            @row-clicked="(id) => assignProfileToProduct(id, modalItem.id)"
            :profiles="profile"></Bl1nkProfileCard>
        </div>
      </div>
      <CSmartPagination
        :activePage="1"
        :pages="maxPagesModal"
        :arrows="false"
        @activePageChange="(page) => modalActivePageChange(page)" />
    </template>
  </AssignModal>
  <CSmartPagination
    :activePage="1"
    :pages="maxPages"
    :arrows="false"
    @activePageChange="(page) => activePageChange(page)" />
</template>

<script>
import { CIcon } from '@coreui/icons-vue'
import {
  cilFilter,
  cilPlus,
  cilQrCode,
  cilSearch,
  cilTrash,
} from '@coreui/icons'
import Bl1nkHorizontalCard from '@/components/bl1nk/bl1nk-card/bl1nk-horizontal-product-card'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import Bl1nkQRModal from '@/components/bl1nk/bl1nk-qr-modal/bl1nk-qr-modal'
import AssignModal from '@/components/modal/assignModal'
import Bl1nkProfileCard from '@/components/bl1nk/bl1nk-card/bl1nk-horizontal-profile-card'
import _ from 'lodash'
import ApiService from '@/services/api.service'
import moment from 'moment'
export default {
  name: 'myProducts',
  components: {
    Bl1nkHorizontalCard,
    Bl1nkInput,
    Bl1nkButton,
    CIcon,
    Bl1nkQRModal,
    AssignModal,
    Bl1nkProfileCard,
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilFilter,
      cilQrCode,
    }
  },
  data() {
    return {
      searchValue: '',
      modalSearchValue: '',
      isLoading: true,
      productItems: [],
      isQRModalVisible: {
        status: false,
        id: '',
        url: '',
      },
      isVisible: false,
      modalItem: {},
      profiles: {},
      itemsPerPage: '',
      maxPages: '',
      activePage: '',
      itemsPerPageModal: '',
      maxPagesModal: '',
      activePageModal: '',
    }
  },
  methods: {
    getProducts: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      this.isLoading = true
      ApiService.fetchProducts(itemsPerPage, searchValue, activePage).then(
        (response) => {
          console.log('products', response.data)

          self.maxPages = response.meta.last_page

          if (response.data.length > 0) {
            this.productItems = response.data
            this.emptyState = false
            this.isLoading = false
            self.productItems.forEach((value, index) => {
              self.productItems[index]['created_at'] = moment(
                self.productItems[index]['created_at'],
              ).format('MM/DD/YYYY')
              self.productItems[index]['type'] =
                self.productItems[index]['type'].name
            })
          } else {
            this.emptyState = true
            this.isLoading = false
          }
        },
      )
    },
    1000),
    getMyProfilesList: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      this.isLoading = true
      this.profiles = await ApiService.fetchMyProfiles(
        itemsPerPage,
        searchValue,
        activePage,
      )
      this.maxPagesModal = this.profiles.meta.last_page
      this.profiles = this.profiles.data
      this.isLoading = false
      this.profiles.forEach((value, index) => {
        self.profiles[index]['created_at'] = moment(
          self.profiles[index]['created_at'],
        ).format('MM/DD/YYYY')
      })
    },
    1000),
    assignProfileToProduct(profile, product) {
      ApiService.assignProfileToProduct(product, profile)
        .then((response) => {
          this.getProducts()
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Changed',
            showConfirmButton: false,
            timer: 3000,
          })
          this.isVisible = false
          this.$store.dispatch('getProducts')
        })
        .catch((error) => {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.message ?? 'An error occurred',
            showConfirmButton: false,
            timer: 3000,
          })
        })
    },
    showQR(productItems) {
      this.isQRModalVisible = {
        status: true,
        id: productItems.id,
        url: productItems.preview_url,
      }
    },
    modalItemsPerPageChange(number) {
      this.itemsPerPageModal = number
    },
    modalActivePageChange(page) {
      this.activePageModal = page
    },
    triggerAssignModal(item) {
      this.modalItem = item
      this.isVisible = !this.isVisible
    },
    activePageChange(page) {
      this.activePage = page
    },
  },
  mounted() {
    this.getProducts()
    this.getMyProfilesList()
    this.$store.dispatch('getProducts')
  },
  watch: {
    searchValue(newValue) {
      this.getProducts(null, newValue, null)
    },
    activePage(newValue) {
      this.getProducts(null, this.searchValue, newValue)
    },
    modalSearchValue(newValue) {
      this.getMyProfilesList(null, newValue, null)
    },
    activePageModal(newValue) {
      this.getMyProfilesList(null, this.modalSearchValue, newValue)
    },
  },
}
</script>

<style scoped>
.hover-white:hover path {
  fill: white;
}
a {
  text-decoration: none;
}
.product-status {
  text-decoration: underline;
}
</style>
