export const logo = [
  '556 134',
  `
  <title>coreui vue</title>
  <svg width="128" height="38" viewBox="0 0 128 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.57 37.32L61.29 14.32V7.48999H61.89L81.55 25.43V7.52999H87.17V37.32H86.57ZM67.03 26.21V37.32H61.29V21.08L67.03 26.21Z" fill="black"/>
    <path d="M45.4899 32.19V37.32H28.1899V7.52H33.8099V32.19H45.4899Z" fill="black"/>
    <path d="M38.8701 12.65V7.52H56.1701V37.32H50.5601V12.65H38.8701Z" fill="black"/>
    <path d="M110.54 37.32L104.68 30.85L108.1 26.57L118.1 37.32H110.54ZM98.0901 30.97V37.32H92.3501V7.53H98.0901V22.67L109.89 7.53H116.89C110.66 15.34 104.44 23.28 98.0901 30.97Z" fill="black"/>
    <path d="M5.74023 19.61H12.7402C13.2517 19.6358 13.7594 19.5106 14.2002 19.25C14.6902 19.13 14.9402 18.76 15.3002 18.39C15.5799 18.0262 15.787 17.612 15.9102 17.17C16.0669 16.7402 16.1513 16.2874 16.1602 15.83C16.1347 15.4117 16.0436 15 15.8902 14.61C15.7398 14.2164 15.5344 13.8461 15.2802 13.51C14.9202 13.26 14.6702 13.02 14.2802 12.78C13.8919 12.624 13.4786 12.5393 13.0602 12.53H5.73023L5.74023 19.61ZM13.8002 32.19C14.2715 32.1853 14.7333 32.0578 15.1402 31.82C15.5993 31.6823 15.9912 31.3795 16.2402 30.97C16.5931 30.5714 16.88 30.1191 17.0902 29.63C17.2364 29.1528 17.3204 28.6587 17.3402 28.16C17.353 27.6966 17.2606 27.2364 17.0701 26.8138C16.8796 26.3912 16.5959 26.0173 16.2402 25.72C15.4231 24.9743 14.3458 24.5793 13.2402 24.62H5.79024V32.19H13.8002ZM21.8902 15.7C21.8896 16.3836 21.7642 17.0614 21.5202 17.7C21.2829 18.43 20.9097 19.1086 20.4202 19.7C20.0233 20.2382 19.5279 20.6963 18.9602 21.05C18.3747 21.4751 17.6819 21.7279 16.9602 21.78C17.7314 21.8073 18.4852 22.0163 19.1602 22.39C19.8851 22.7678 20.5437 23.2609 21.1102 23.85C21.6534 24.5219 22.1072 25.2614 22.4602 26.05C22.8596 26.848 23.0684 27.7277 23.0702 28.62C23.0952 30.0382 22.8439 31.4478 22.3302 32.77C21.8548 33.8102 21.1465 34.7272 20.2602 35.45C19.3893 36.1272 18.3938 36.6267 17.3302 36.92C16.171 37.2088 14.9843 37.3731 13.7902 37.41H-0.00976562V7.53H13.0602C14.2005 7.54564 15.339 7.62577 16.4702 7.77C17.4913 8.01236 18.4492 8.46913 19.2802 9.11C20.0813 9.8131 20.7079 10.693 21.1102 11.68C21.6616 12.9467 21.9278 14.319 21.8902 15.7Z" fill="black"/>
    <path d="M121.04 8.00999C120.426 7.68303 119.93 7.17263 119.62 6.55C119.297 5.8263 119.13 5.04259 119.13 4.24999C119.13 3.45739 119.297 2.67369 119.62 1.94999C119.931 1.33028 120.427 0.823257 121.04 0.499992C121.746 0.170989 122.516 0.00050354 123.295 0.00050354C124.074 0.00050354 124.844 0.170989 125.55 0.499992C126.163 0.823257 126.66 1.33028 126.97 1.94999C127.293 2.67369 127.46 3.45739 127.46 4.24999C127.46 5.04259 127.293 5.8263 126.97 6.55C126.66 7.17263 126.164 7.68303 125.55 8.00999C124.844 8.339 124.074 8.50948 123.295 8.50948C122.516 8.50948 121.746 8.339 121.04 8.00999ZM125.04 7.18999C125.522 6.93811 125.911 6.53866 126.15 6.05C126.423 5.49025 126.557 4.87267 126.54 4.24999C126.558 3.61438 126.425 2.98361 126.15 2.41C125.91 1.92043 125.526 1.51575 125.05 1.24999C124.503 0.9867 123.903 0.849991 123.295 0.849991C122.688 0.849991 122.088 0.9867 121.54 1.24999C121.063 1.51812 120.677 1.92188 120.43 2.41C120.164 2.98599 120.034 3.61561 120.05 4.24999C120.035 4.87137 120.165 5.48767 120.43 6.05C120.675 6.53435 121.063 6.93215 121.54 7.18999C122.088 7.44693 122.685 7.58012 123.29 7.58012C123.895 7.58012 124.493 7.44693 125.04 7.18999ZM121.64 2.09H122.54V6.37999H121.64V2.09ZM122.32 4.18999H123.59C123.676 4.19582 123.762 4.18372 123.843 4.15445C123.924 4.12518 123.998 4.0794 124.06 4.02C124.123 3.95593 124.172 3.87904 124.203 3.79451C124.234 3.70998 124.247 3.61979 124.24 3.52999C124.246 3.44177 124.233 3.3533 124.202 3.27052C124.171 3.18773 124.123 3.11254 124.06 3.05C123.932 2.93391 123.763 2.87285 123.59 2.87999H122.32V2.09H123.56C123.986 2.06065 124.407 2.20082 124.73 2.48C124.994 2.76781 125.141 3.14429 125.141 3.535C125.141 3.9257 124.994 4.30219 124.73 4.59C124.406 4.86902 123.987 5.0123 123.56 4.98999H122.32V4.18999ZM123.41 4.70999L124.15 4.27L125.15 6.37H124.15L123.41 4.70999Z" fill="black"/>
  </svg>

`,
]
