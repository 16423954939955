<template>
  <loading v-model:active="isLoading" :is-full-page="false" />

  <div class="row">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for people">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <Bl1nkButton class="w-100 bl1nk-btn" @click="triggerDefaultModal()">
        <CIcon :icon="cilPlus" size="lg" /> Invite People
      </Bl1nkButton>
    </div>
    <div class="col-12">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="peopleColumns"
        :items="peopleList"
        @items-per-page-change="(number) => itemsPerPageChange(number)">
        <template v-slot="slotData">
          <CAccordion flush v-if="slotData.item.products.length !== 0">
            <CAccordionItem>
              <CAccordionHeader> Products </CAccordionHeader>
              <CAccordionBody>
                <Bl1nkButton
                  v-for="products in slotData.item.product_owner"
                  :key="products.id"
                  class="mx-2 bl1nk-btn d-inline-block m-2"
                  role="button">
                  {{ products?.username }}
                </Bl1nkButton>
                <CTooltip content="Assign Product" placement="top">
                  <template #toggler="{ on }">
                    <Bl1nkButton
                      v-on="on"
                      @click="triggerAssignModal(slotData.item)">
                      <CIcon
                        class="add-products-style"
                        :icon="cilPlus"
                        size="xl" />
                    </Bl1nkButton>
                  </template>
                </CTooltip>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <Bl1nkButton
            v-else-if="slotData.item.product_owner.length === 0"
            class="mx-2 bl1nk-btn d-inline-block"
            role="button"
            @click="triggerAssignModal(slotData.item)">
            Assign Product
          </Bl1nkButton>
        </template>
      </Bl1nkSmartTable>
    </div>
  </div>
  <default-modal
    :default-modal-visibility="defaultModalVisibility"
    @closed="triggerDefaultModal()">
    <template #modalHeader>
      <h3>Invite People</h3>
    </template>
    <template #modalBody>
      <bl1nk-tab>
        <template #tabs-name>
          <button
            v-for="tab in tabs"
            :key="tab"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab">
            {{ tab }}
          </button>
        </template>
        <!--          <template #dynamic-components>-->
        <!--            <component :is="currentTab"></component>-->
        <!--          </template>-->
      </bl1nk-tab>
    </template>
    <template #modalFooter>
      <CButton class="bl1nk-btn">Send</CButton>
    </template>
  </default-modal>
  <AssignModal
    @closed="triggerAssignModal(null)"
    :isVisible="assignModalVisibility">
    <template #modalHeader>
      <strong>Assign product to </strong>
      <span class="ms-1">
        {{ modalItem?.team_member ?? 'N/A' }}
      </span>
    </template>

    <template #modalBody>
      <div class="row">
        <div class="col-10">
          <Bl1nkInput
            v-model="modalSearchValue"
            placeholder="Search for profiles">
            <CIcon :icon="cilSearch" size="lg" />
          </Bl1nkInput>
        </div>
        <div class="col-2">
          <Bl1nkButton class="w-100 bl1nk-btn">
            <CIcon :icon="cilFilter" size="xs" />
            Filter
          </Bl1nkButton>
        </div>
        <div class="col-12">
          <Bl1nkSmartTable
            theme="blue"
            :columns="modalColumns"
            :items="teamProductList"
            :isSelectable="false"
            key="modalItem"
            @row-clicked="
              (item, index) => assignUserToProduct(item, index, modalItem)
            "
            @items-per-page-change="
              (number) => modalItemsPerPageChange(number)
            ">
          </Bl1nkSmartTable>
        </div>
      </div>
      <CSmartPagination
        :activePage="1"
        :pages="maxPagesModal"
        :arrows="false"
        @activePageChange="(page) => modalActivePageChange(page)" />
    </template>
  </AssignModal>
  <CSmartPagination
    :activePage="1"
    :pages="maxPages"
    :arrows="false"
    @activePageChange="(page) => activePageChange(page)" />
</template>

<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import DefaultModal from '@/components/modal/defaultModal'
import Bl1nkTab from '@/components/bl1nk/bl1nk-tab/bl1nk-tab'
import AssignModal from '@/components/modal/assignModal'
import { CIcon } from '@coreui/icons-vue'
import { cilTrash, cilSearch, cilPlus } from '@coreui/icons'
import ApiWorkspaceService from '@/services/api.workspace.service'
import _ from 'lodash'
export default {
  name: 'workspace-people',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    DefaultModal,
    Bl1nkTab,
    AssignModal,
  },

  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
    }
  },
  data() {
    return {
      searchValue: '',
      modalSearchValue: '',
      peopleColumns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '40px',
          },
          _props: { style: 'width: 8%' },
        },
        {
          key: 'team_member',
          label: 'Team Member Name',
          _style: {
            width: '45%',
            'min-width': '250px',
          },
          _props: { style: 'width: 45%' },
        },
        {
          key: 'products',
          label: 'Products',
          _style: {
            width: '10%',
            'min-width': '150px',
          },
          _props: { style: 'width: 17%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'profiles',
          label: 'Profiles',
          _style: {
            width: '10%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      peopleList: [],
      defaultModalVisibility: false,
      tabs: ['Email Invitation', 'Invitation Link'],
      currentTab: 'Email Invitation',
      assignModalVisibility: false,
      modalItem: null,
      modalColumns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '70px',
          },
          _props: { style: 'width: 10%' },
        },

        {
          key: 'products_link',
          label: 'Product Link',
          _style: {
            width: '20%',
            'min-width': '150px',
          },
          _props: { style: 'width: 20%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'products_profile',
          label: 'Product’s Profile',
          _style: {
            width: '50%',
            'min-width': '250px',
          },
          _props: { style: 'width: 50%' },
        },
        {
          key: 'type',
          label: 'Type',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      teamProductList: [],
      isLoading: true,
      teamID: this.$store.state.auth.user?.teams[0].id,
      itemsPerPage: '',
      maxPages: '',
      activePage: '',
      itemsPerPageModal: '',
      maxPagesModal: '',
      activePageModal: '',
    }
  },
  methods: {
    itemsPerPageChange(number) {
      this.itemsPerPage = number
    },
    activePageChange(page) {
      this.activePage = page
    },
    modalItemsPerPageChange(number) {
      this.itemsPerPageModal = number
    },
    modalActivePageChange(page) {
      this.activePageModal = page
    },
    triggerDefaultModal() {
      this.defaultModalVisibility = !this.defaultModalVisibility
    },
    triggerAssignModal(item) {
      this.modalItem = item
      this.assignModalVisibility = !this.assignModalVisibility
      this.fetchTeamProducts()
    },
    getWorkspaceMembers: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      this.isLoading = true
      ApiWorkspaceService.getWorkspaceMembers(
        this.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.isLoading = false
          this.maxPages = response.meta.last_page
          this.peopleList = response.data

          this.peopleList.map(
            (element) => (
              (element.team_member = element?.first_name.concat(
                ' ',
                element?.last_name,
              )),
              (element.product_owner = element?.products),
              (element.products = element?.products?.length),
              (element.profiles = element?.profiles?.length)
            ),
          )
        })
        .catch((error) => {
          self.isLoading = false
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 3000,
          })
        })
    },
    1000),
    fetchTeamProducts: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      this.isLoading = true
      ApiWorkspaceService.fetchTeamProducts(
        this.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.isLoading = false
          this.maxPagesModal = response.meta.last_page
          this.teamProductList = response.data
          this.teamProductList.forEach((value) => {
            value['products_profile'] = value['profile']?.name
            value['products_link'] = value?.username
            value['type'] = value['type']?.name
          })
        })
        .catch((error) => {
          this.isLoading = false
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 3000,
          })
        })
    },
    1000),
    assignUserToProduct(item, index, modalItem) {
      const productID = item.id
      ApiWorkspaceService.assignUserToProduct(
        this.teamID,
        productID,
        modalItem.id,
      )
        .then(() => {
          this.$swal.fire({
            icon: 'success',
            title: 'Password Successfully Changed',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assignModalVisibility = false
          this.getWorkspaceMembers()
        })
        .catch((error) => {
          this.$swal.fire({
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assignModalVisibility = false
          this.getWorkspaceMembers()
        })
    },
  },
  mounted() {
    this.getWorkspaceMembers()
  },
  watch: {
    modalSearchValue(newValue) {
      this.fetchTeamProducts(null, newValue, null)
    },
    activePageModal(newValue) {
      this.fetchTeamProducts(
        this.itemsPerPageModal,
        this.modalSearchValue,
        newValue,
      )
    },
    itemsPerPageModal(newValue) {
      this.fetchTeamProducts(
        newValue,
        this.modalSearchValue,
        this.activePageModal,
      )
    },
    searchValue(newValue) {
      this.getWorkspaceMembers(null, newValue, null)
    },
    itemsPerPage(newValue) {
      this.getWorkspaceMembers(newValue, this.searchValue, this.activePage)
    },
    activePage(newValue) {
      this.getWorkspaceMembers(this.itemsPerPage, this.searchValue, newValue)
    },
  },
}
</script>

<style scoped>
.add-products-style {
  color: var(--bl1nk-secondary-color);
  padding: 2px;
  border-radius: 50%;
  background-color: var(--bl1nk-secondary-light-color);
  transition: 0.5s;
}
.add-products-style:hover {
  color: var(--bl1nk-secondary-light-color);
  background-color: var(--bl1nk-secondary-color);
}
</style>
