<template>
  <CNavbar class="bg-color">
    <CContainer fluid>
      <CNavbarBrand href="#" class="mx-auto">
        <img
          src="../assets/png/Bl1nk-header.png"
          alt=""
          class="d-inline-block align-top"
          @click="this.$router.push({ path: '/' })" />
      </CNavbarBrand>
    </CContainer>
  </CNavbar>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'GuestLayout',
}
</script>

<style scoped>
.bg-color {
  background: #0c0c0c;
}
</style>
