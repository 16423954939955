<template>
  <loading v-model:active="isLoading" :is-full-page="false" />

  <h6 class="text-muted">Manage Team</h6>
  <h3>{{ subTeam_title }}</h3>
  <div class="row">
    <div class="col-9">
      <Bl1nkInput v-model="searchValue" placeholder="Search for profiles">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-3">
      <Bl1nkButton class="w-100 bl1nk-btn" @click="triggerAssignModal()">
        <CIcon :icon="cilPlus" size="lg" /> Add People to Team
      </Bl1nkButton>
    </div>
    <div class="col-12" v-if="!emptyState">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="items">
        <template v-slot="slotData">
          <Bl1nkButton
            class="mx-2 bl1nk-danger-btn d-inline-block"
            @click="removeTeamMember(slotData.item)"
            role="button">
            Remove Member
          </Bl1nkButton>
        </template>
      </Bl1nkSmartTable>
    </div>
    <div class="col-12 mt-3" v-else-if="emptyState">
      <div class="dashed-border text-center">
        <Bl1nkButton class="w-100 grey-color">
          No Team Members in this Team
        </Bl1nkButton>
      </div>
    </div>
  </div>
  <AssignModal
    @closed="triggerAssignModal(null)"
    :isVisible="isAssignModalVisible">
    <template #modalHeader>
      <strong>Add people to </strong>
      <span class="ms-1">{{ subTeam_title }}</span>
      <!--      <span class="ms-1">-->
      <!--        {{ modalItem?.team_member ?? 'N/A' }}-->
      <!--      </span>-->
    </template>

    <template #modalBody>
      <div class="row">
        <div class="col-12">
          <Bl1nkInput
            v-model="modalSearchValue"
            placeholder="Search for people">
            <CIcon :icon="cilSearch" size="lg" />
          </Bl1nkInput>
        </div>
        <div class="col-12">
          <Bl1nkSmartTable
            theme="blue"
            :columns="addPeopleColumns"
            :items="addPeopleItems"
            :isSelectable="false"
            key="modalItem"
            @row-clicked="(item, index) => addMemberToSubTeam(item, index)">
          </Bl1nkSmartTable>
        </div>
      </div>
    </template>
  </AssignModal>
</template>

<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import AssignModal from '@/components/modal/assignModal'
import { cilTrash, cilSearch, cilPlus } from '@coreui/icons'
import ApiWorkspaceService from '@/services/api.workspace.service'
import _ from 'lodash'

export default {
  name: 'manageTeam',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    AssignModal,
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
    }
  },
  data() {
    return {
      searchValue: '',
      modalSearchValue: '',
      defaultModalVisibility: false,
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '40px',
          },
          _props: { style: 'width: 8%' },
        },
        {
          key: 'team_member',
          label: 'Team Member Name',
          _style: {
            width: '40%',
            'min-width': '250px',
          },
          _props: { style: 'width: 45%' },
        },
        {
          key: 'products',
          label: 'Products',
          _style: {
            width: '15%',
            'min-width': '150px',
          },
          _props: { style: 'width: 17%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'profiles',
          label: 'Profiles',
          _style: {
            width: '10%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      items: [],
      addPeopleColumns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '70px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'team_member_name',
          label: 'Team Member Name',
          _style: {
            width: '90%',
            'min-width': '250px',
          },
          _props: { style: 'width: 90%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      addPeopleItems: [],
      isAssignModalVisible: false,
      isLoading: true,
      subTeam_title: '',
      teamID: this.$store.state.auth.user?.teams[0].id,
      subTeamID: this.$route.params.id,
    }
  },
  methods: {
    removeTeamMember(item) {
      this.$swal
        .fire({
          html:
            'Are you sure you want to remove ' +
            '<span style="color: #F33939">' +
            item.team_member +
            '</span> from ' +
            '<span style="color: #F33939">' +
            this.subTeam_title +
            '</span> ?',
          showConfirmButton: false,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: `Delete`,
        })
        .then((result) => {
          if (result.isDenied) {
            this.isLoading = true
            ApiWorkspaceService.deleteMemberFromSubTeam(
              this.teamID,
              this.subTeamID,
              item.id,
            ).then(() => {
              this.items = this.items.filter(function (value) {
                return value.id !== item.id
              })
              this.isLoading = false
            })
          }
        })
    },
    triggerAssignModal() {
      this.isAssignModalVisible = !this.isAssignModalVisible
      this.getWorkspaceMember()
    },
    addMemberToSubTeam(item) {
      ApiWorkspaceService.addMemberToSubTeam(
        this.teamID,
        this.subTeamID,
        item.id,
      ).then((response) => {
        this.$swal.fire({
          icon: 'success',
          title: 'Member Successfully Added',
          showConfirmButton: false,
          timer: 1500,
        })
        this.isAssignModalVisible = !this.isAssignModalVisible
        this.getSubTeamMembers()
      })
    },
    getSubTeamMembers() {
      this.isLoading = true
      ApiWorkspaceService.getSubTeamMembers(
        this.$store.state.auth.user?.teams[0].id,
        this.$route.params.id,
      ).then((response) => {
        this.isLoading = false
        this.items = response.data
        this.checkItemsLength()
        this.items.forEach((value, index) => {
          this.items[index]['team_member'] =
            this.items[index]['first_name'] +
            ' ' +
            this.items[index]['last_name']
          // TODO:ask ayman about this if statement if its working or not
          this.items[index].products = this.items[index].products.length
          this.items[index].profiles = this.items[index].profiles.length
        })
      })
    },
    showSubTeam() {
      this.isLoading = true
      ApiWorkspaceService.showSubTeam(
        this.$store.state.auth.user?.teams[0].id,
        this.$route.params.id,
      ).then((response) => {
        this.isLoading = false
        this.subTeam_title = response.data.name
      })
    },
    getWorkspaceMember: _.throttle(async function (searchValue) {
      this.isLoading = true
      ApiWorkspaceService.getWorkspaceMembers(
        this.teamID,
        null,
        searchValue,
      ).then((response) => {
        this.isLoading = false
        this.addPeopleItems = response.data
        this.addPeopleItems.forEach((value, index) => {
          this.addPeopleItems[index]['team_member_name'] =
            this.addPeopleItems[index]['first_name'] +
            ' ' +
            this.addPeopleItems[index]['last_name']
        })
      })
    }, 1000),
    checkItemsLength() {
      if (this.items.length === 0) {
        this.emptyState = true
      } else {
        this.emptyState = false
      }
    },
  },
  mounted() {
    this.getSubTeamMembers()
    this.showSubTeam()
    // this.checkItemsLength()
  },
  watch: {
    items() {
      this.checkItemsLength()
    },
    modalSearchValue(newVal) {
      this.getWorkspaceMember(newVal)
    },
  },
}
</script>

<style scoped></style>
