<template>
  <CInputGroup class="bl1nk-input">
    <CInputGroupText v-if="hasDefaultSlot"><slot></slot></CInputGroupText>
    <CFormInput v-bind="$attrs" />
  </CInputGroup>
</template>

<script>
export default {
  name: 'bl1nk-button',
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
  },
}
</script>

<style scoped>
.input-group-text {
  color: #acacac;
  background-color: var(--bl1nk-tertiary-color);
  border: 0;
  border-radius: 5px;
}
</style>
