import apiClient from '@/api-client'
import axios from 'axios'

export default class ApiService {
  static login(emailAddress, password) {
    return apiClient.post(
      '/login',
      {
        email: emailAddress,
        password: password,
      }
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          return false
        }),
    )
  }

  static fetchMyConnections(limit = null, searchValue = null) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient.get('user/connections?' + paramsURL).then((response) => {
      return response.data
    })
  }

  static fetchProfile(profileID) {
    return apiClient
      .get('/user/profiles/' + profileID)
      .then((response) => {
        return response.data.data
      })
      .catch(() => {
        return false
      })
  }

  static deleteMyProfile(profileID) {
    return apiClient.delete('/user/profiles/' + profileID).then((response) => {
      return response.data
    })
  }

  static fetchProfilePreview(inputsJson) {
    return axios
      .post(process.env.VUE_APP_WEB_URL + '/profile/preview', {
        inputs_json: inputsJson,
      })
      .then((response) => {
        return response.data
      })
  }

  static fetchProducts(limit = null, searchValue = null, activePage = null) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient.get('/user/products?' + paramsURL).then((response) => {
      return response.data
    })
  }

  static createFreshProfile() {
    return apiClient
      .post('/user/profiles')
      .then((response) => {
        return response.data.data
      })
      .catch(() => {
        return false
      })
  }

  static fetchMyProfiles(limit = null, searchValue = null, activePage = null) {
    console.log(limit, searchValue, activePage)
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient.get('/user/profiles?' + paramsURL).then((response) => {
      return response.data
    })
  }

  static assignProfileToProduct(productID, profileID) {
    return apiClient
      .post('/user/products/' + productID + '/profile/' + profileID)
      .then((response) => {
        return response.data
      })
  }

  static fetchTemplates() {
    return apiClient.get('/templates').then((response) => {
      return response.data.data
    })
  }

  static saveProfile(profileName, templateID, profileID, profileInputs) {
    return apiClient
      .put(`/user/profiles/${profileID}`, {
        name: profileName,
        template_id: templateID,
        inputs_json: profileInputs,
      })
      .then((response) => {
        return response.data.data
      })
  }

  static updateProfilePreview(templateID, profileID, profileInputs) {
    return apiClient
      .put(`/user/profiles/${profileID}/preview`, {
        template_id: templateID,
        inputs_json: profileInputs,
        is_preview: true,
      })
      .then((response) => {
        return response.data.data
      })
  }

  static fetchInputs() {
    return apiClient.get('/inputs').then((response) => {
      return response.data.data
    })
  }

  static fetchAssets(name) {
    return apiClient.get('/assets/' + name).then((response) => {
      return response.data
    })
  }

  static resetPassword(email, newPassword, newPasswordConfirm, token) {
    return apiClient
      .post('/reset-password', {
        email: email,
        password: newPassword,
        password_confirmation: newPasswordConfirm,
        token: token,
      })
      .then((response) => {
        return response.data
      })
  }
}
