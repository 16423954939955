<template>
  <CFormCheck
    :id="this.$utils.uuid()"
    v-model="option.value"
    :label="option.label" />
</template>

<script>
import { computed } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'CheckboxOption',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const option = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { option, v$: useVuelidate() }
  },
}
</script>

<style scoped></style>
