<template>
  <loading v-model:active="isLoading" :is-full-page="false" />

  <div class="row">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for people">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <a href="https://bl1nk.co">
        <Bl1nkButton class="w-100 bl1nk-btn">
          <CIcon :icon="cilPlus" size="lg" /> Shop BL1NK Products
        </Bl1nkButton>
      </a>
    </div>
    <div class="col-12" v-if="!emptyState">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="teamProducts"
        @items-per-page-change="(number) => itemsPerPageChange(number)">
        <template v-slot:badge="slotData">
          <td>
            <Bl1nkButton
              class="w-100 ellipsis bl1nk-btn d-inline-block"
              role="button"
              @click="triggerAssignModal(slotData.item)">
              {{ slotData.item?.profile?.name ?? 'Assign Profile' }}
            </Bl1nkButton>
          </td>
        </template>
        <template v-slot="slotData">
          <Bl1nkButton
            class="w-auto mx-2 bl1nk-btn d-inline-block"
            @click="showQR(slotData.item)"
            :id="slotData.item.id">
            <CIcon :icon="cilQrCode" size="lg" />
          </Bl1nkButton>
          <Bl1nkQRModal
            :visible="isQRModalVisible.status"
            title="Share QR Code"
            :id="isQRModalVisible.id"
            :url="isQRModalVisible.url"
            @closed="this.isQRModalVisible.status = false" />
        </template>
      </Bl1nkSmartTable>
    </div>

    <div class="col-12 mt-3" v-else-if="emptyState">
      <div class="dashed-border text-center">
        <Bl1nkButton class="w-100 grey-color">
          <CIcon :icon="cilPlus" size="lg" />
          Shop BL1NK Products
        </Bl1nkButton>
      </div>
    </div>
    <AssignModal @closed="triggerAssignModal(null)" :isVisible="isVisible">
      <template #modalHeader>
        <strong>Assign profile to </strong>
        <span class="ms-1">
          {{ modalItem?.owner?.first_name ?? 'N/A' }}
          {{ modalItem?.owner?.last_name ?? 'N/A' }}
        </span>
      </template>

      <template #modalBody>
        <div class="row">
          <div class="col-10">
            <Bl1nkInput
              v-model="modalSearchValue"
              placeholder="Search for profiles">
              <CIcon :icon="cilSearch" size="lg" />
            </Bl1nkInput>
          </div>
          <div class="col-2">
            <Bl1nkButton class="w-100 bl1nk-btn">
              <CIcon :icon="cilFilter" size="xs" />
              Filter
            </Bl1nkButton>
          </div>
          <div class="col-12">
            <Bl1nkSmartTable
              theme="blue"
              :columns="modalColumns"
              :items="teamProfilesList"
              :isSelectable="false"
              key="modalItem"
              @row-clicked="
                (item, index) => clickedProfile(item, index, modalItem)
              "
              @items-per-page-change="
                (number) => modalItemsPerPageChange(number)
              ">
            </Bl1nkSmartTable>
          </div>
        </div>
        <CSmartPagination
          :activePage="1"
          :pages="maxPagesModal"
          :arrows="false"
          @activePageChange="(page) => modalActivePageChange(page)" />
      </template>
    </AssignModal>
    <CSmartPagination
      :activePage="1"
      :pages="maxPages"
      :arrows="false"
      @activePageChange="(page) => activePageChange(page)" />
  </div>
</template>
<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import ApiWorkspaceService from '@/services/api.workspace.service'
import Bl1nkQRModal from '@/components/bl1nk/bl1nk-qr-modal/bl1nk-qr-modal'
import AssignModal from '@/components/modal/assignModal'
import { CIcon } from '@coreui/icons-vue'
import { cilTrash, cilSearch, cilPlus, cilQrCode } from '@coreui/icons'

import store from '@/stores'

import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'workspace-products',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    Bl1nkQRModal,
    AssignModal,
  },

  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilQrCode,
    }
  },
  data() {
    return {
      searchValue: '',
      modalSearchValue: '',
      teamID: store.state.auth.user?.teams[0].id,
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '5%',
            'min-width': '70px',
          },
          _props: { style: 'width: 5%' },
        },
        {
          key: 'username',
          label: 'Product Link',
          _style: {
            width: '30%',
            'min-width': '250px',
          },
          _props: { style: 'width: 30%' },
        },
        {
          key: 'product_owner',
          label: 'Product Owner',
          _style: {
            width: '15%',
            'min-width': '150px',
          },
          _props: {
            style: 'width: 15%',
          },
        },
        {
          key: 'created_at',
          label: 'Creation Date',
          _style: {
            width: '15%',
            'min-width': '150px',
          },
          _props: { style: 'width: 15%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'badge',
          filter: false,
          sorter: false,
          label: 'Profiles',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      teamProducts: [],
      isQRModalVisible: {
        status: false,
        id: '',
        url: '',
      },
      modalColumns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'name',
          label: 'Profile Name',
          _style: {
            width: '100%',
            'min-width': '250px',
          },
          _props: { style: 'width: 100%' },
        },
        {
          key: 'created_at',
          label: 'Creation Date',
          _style: {
            width: '25%',
            'min-width': '200px',
          },
          _props: { style: 'width: 25%; min-width: 200px' },
          filter: false,
          sorter: false,
        },
        {
          key: 'badge',
          filter: false,
          sorter: false,
          label: 'Product',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      teamProfilesList: [],
      modalItem: null,
      isVisible: false,
      emptyState: false,
      isLoading: true,
      QRModalID: '',
      itemsPerPage: '',
      maxPages: '',
      activePage: '',
      itemsPerPageModal: '',
      maxPagesModal: '',
      activePageModal: '',
    }
  },
  methods: {
    itemsPerPageChange(number) {
      console.log(number)
      this.itemsPerPage = number
    },
    modalItemsPerPageChange(number) {
      this.itemsPerPageModal = number
    },
    activePageChange(page) {
      this.activePage = page
    },
    modalActivePageChange(page) {
      this.activePageModal = page
    },
    fetchTeamProducts: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      self.isLoading = true
      ApiWorkspaceService.fetchTeamProducts(
        self.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.maxPages = response.meta.last_page
          if (response.length !== 0) {
            self.isLoading = false
            self.teamProducts = response.data
            self.teamProducts.forEach((value, index) => {
              self.teamProducts[index]['product_owner'] =
                self.teamProducts[index]['owner']?.username ??
                self.teamProducts[index]['team']?.name
              self.teamProducts[index]['type'] =
                self.teamProducts[index]['type'].name
              self.teamProducts[index]['created_at'] = moment(
                self.teamProducts[index]['created_at'],
              ).format('MM/DD/YYYY')
            })
          } else {
            this.emptyState = true
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.emptyState = true
          self.isLoading = false
          self.$swal.fire({
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    1000),
    fetchTeamProfiles: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      self.isLoading = true
      ApiWorkspaceService.fetchTeamProfiles(
        self.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      ).then((response) => {
        this.isLoading = false
        this.maxPagesModal = response.meta.last_page
        self.teamProfilesList = response.data
        self.teamProfilesList.forEach((value, index) => {
          self.teamProfilesList[index]['created_at'] = moment(
            self.teamProfilesList[index]['created_at'],
          ).format('MM/DD/YYYYY')
        })
      })
    },
    1000),
    showQR(slotData) {
      this.isQRModalVisible = {
        status: true,
        id: slotData.id,
        url: slotData.preview_url,
      }
    },
    triggerAssignModal(item) {
      this.modalItem = item
      this.isVisible = !this.isVisible
      this.fetchTeamProfiles()
    },
    clickedProfile(item, index, slotData) {
      this.$swal
        .fire({
          html:
            'Are you sure you want to assign ' +
            '<span style="color: #1c86ff">' +
            item.name +
            '</span> to ' +
            '<span style="color: #1c86ff">' +
            slotData.username +
            '</span>',
          showConfirmButton: true,
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Link`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.assignTeamProfileToProduct(item, slotData)
          }
        })
    },
    assignTeamProfileToProduct(profile, product) {
      ApiWorkspaceService.assignTeamProfileToProduct(
        this.teamID,
        product.id,
        profile.id,
      )
        .then((response) => {
          this.fetchTeamProducts()
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Changed',
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch((error) => {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.message ?? 'An error occurred',
            showConfirmButton: false,
            timer: 3000,
          })
        })
    },
  },
  mounted() {
    this.fetchTeamProducts()
  },
  watch: {
    searchValue(newValue) {
      this.fetchTeamProducts(null, newValue, null)
    },
    itemsPerPage(newValue) {
      this.fetchTeamProducts(newValue, this.searchValue, this.activePage)
    },
    activePage(newValue) {
      this.fetchTeamProducts(this.itemsPerPage, this.searchValue, newValue)
    },
    modalSearchValue(newValue) {
      this.fetchTeamProfiles(null, newValue, null)
    },
    activePageModal(newValue) {
      this.fetchTeamProfiles(
        this.itemsPerPageModal,
        this.modalSearchValue,
        newValue,
      )
    },
    itemsPerPageModal(newValue) {
      this.fetchTeamProfiles(
        newValue,
        this.modalSearchValue,
        this.activePageModal,
      )
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
