<template>
  <div class="row">
    <div class="col-12">
      <Bl1nkInput v-model="searchValue" placeholder="Search for profiles">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-12">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="items">
        <template v-slot="slotData">
          <Bl1nkButton
            class="mx-2 bl1nk-btn d-inline-block"
            @click="debugTest(slotData.item)"
            role="button">
            View Profile Analytics
          </Bl1nkButton>
        </template>
      </Bl1nkSmartTable>
    </div>
  </div>
</template>

<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'

import { CIcon } from '@coreui/icons-vue'
import { cilTrash, cilSearch, cilPlus } from '@coreui/icons'
export default {
  name: 'analytics-by-products',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
  },

  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
    }
  },
  data() {
    return {
      searchValue: '',
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '8%',
            'min-width': '40px',
          },
          _props: { style: 'width: 8%' },
        },
        {
          key: 'profile_name',
          label: 'Profile Name',
          _style: {
            width: '45%',
            'min-width': '250px',
          },
          _props: { style: 'width: 45%' },
        },
        {
          key: 'link',
          label: 'L1nk',
          _style: {
            width: '17%',
            'min-width': '150px',
          },
          _props: { style: 'width: 17%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'template',
          label: 'Template',
          _style: {
            width: '10%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      items: [
        {
          id: 1,
          profile_name: 'Josef Abi Aoun - Work’s Card',
          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world and a very long line!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
        {
          id: 2,
          profile_name: 'Josef Abi Aoun - Work’s Card',
          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
        {
          id: 3,
          profile_name: 'Josef Abi Aoun - Work’s Card',
          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
        {
          id: 4,
          profile_name: 'Josef Abi Aoun - Work’s Card',
          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
        {
          id: 5,
          name: '/Fady',
          profile_name: 'Josef Abi Aoun - Work’s Card',

          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
        {
          id: 6,
          profile_name: 'Josef Abi Aoun - Work’s Card',
          link: '/ABRJosefAbiAoun',
          description: 'Hello, this is a lovely world!',
          template: 'Default',
          _cellProps: { description: { class: 'ellipsis' } },
        },
      ],
    }
  },
}
</script>

<style scoped></style>
