<template>
  <CModal
    class="modal-width"
    alignment="center"
    :visible="isVisible"
    @close="$emit('closed')">
    <CModalHeader>
      <slot name="modalHeader"></slot>
    </CModalHeader>
    <CModalBody>
      <slot name="modalBody"></slot>
    </CModalBody>
    <CModalFooter>
      <slot name="modalFooter"></slot>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'assignModal',
  props: ['isVisible'],
  emits: ['closed'],
  data() {
    return {
      visibleVerticallyCenteredDemo: true,
    }
  },
}
</script>

<style scoped>
.modal-width {
  width: 100%;
  max-width: 80%;
}
</style>
