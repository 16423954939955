<template>
  <CRow class="g-2 px-3 py-2">
    <hr />
    <h4>Options</h4>
    <CCol md="6" v-for="(option, index) in this.options" :key="option">
      <component
        :is="option.type"
        v-model="this.options[index]"
        :key="option"></component>
    </CCol>
  </CRow>
</template>

<script>
import { computed } from 'vue'

import TextOption from '@/components/Blocks/Options/TextOption'
import CheckboxOption from '@/components/Blocks/Options/CheckboxOption'
import IconPickerOption from '@/components/Blocks/Options/IconPickerOption'
import PositionOption from '@/components/Blocks/Options/PositionOption'

export default {
  name: 'OptionsWrapper',
  emits: ['update:modelValue'],
  components: {
    TextOption,
    IconPickerOption,
    PositionOption,
    CheckboxOption,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const options = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { options }
  },
}
</script>

<style scoped></style>
