import apiClient from '@/api-client'

export default class ApiWorkspaceService {
  static fetchTeamProfile(teamID, profileID) {
    return apiClient
      .get('/team/' + teamID + '/profiles/' + profileID)
      .then((response) => {
        return response.data.data
      })
  }
  static fetchTeamProfiles(
    teamID,
    limit = null,
    searchValue = null,
    activePage = null,
  ) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient
      .get('/team/' + teamID + '/profiles?' + paramsURL)
      .then((response) => {
        return response.data
      })
  }

  static createFreshTeamProfile(teamID) {
    return apiClient
      .post('/team/' + teamID + '/profiles')
      .then((response) => {
        return response.data.data
      })
      .catch(() => {
        return false
      })
  }

  static assignTeamProfileToProduct(teamID, products, profile) {
    return apiClient
      .post('/team/' + teamID + '/products/' + products + '/profile/' + profile)
      .then((response) => {
        return response.data
      })
  }
  static deleteTeamProfile(teamID, profileID) {
    return apiClient
      .delete('/team/' + teamID + '/profiles/' + profileID)
      .then((response) => {
        return response.data
      })
  }

  static getWorkspaceMembers(
    teamID,
    limit = null,
    searchValue = null,
    activePage = null,
  ) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient
      .get('/user/teams/' + teamID + '/members?' + paramsURL)
      .then((response) => {
        return response.data
      })
  }
  static fetchTeamProducts(
    teamID,
    limit = null,
    searchValue = null,
    activePage = null,
  ) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient
      .get('/team/' + teamID + '/products?' + paramsURL)
      .then((response) => {
        return response.data
      })
  }
  static getSubTeams(
    teamID,
    limit = null,
    searchValue = null,
    activePage = null,
  ) {
    let params = {
      ...(limit && { limit: limit }),
      ...(searchValue && { 'filter[q]': searchValue }),
      ...(activePage && { page: activePage }),
    }
    let paramsURL = new URLSearchParams(params).toString()
    return apiClient
      .get('/team/' + teamID + '/sub-teams?' + paramsURL)
      .then((response) => {
        return response.data
      })
  }

  static createSubTeam(teamID, teamName) {
    return apiClient
      .post('/team/' + teamID + '/sub-teams', {
        name: teamName,
      })
      .then((response) => {
        return response.data
      })
  }

  static getSubTeamMembers(teamID, subTeamID) {
    return apiClient
      .get('/team/' + teamID + '/sub-teams/' + subTeamID + '/members')
      .then((response) => {
        return response.data
      })
  }

  static showSubTeam(teamID, subTeamID) {
    return apiClient
      .get('/team/' + teamID + '/sub-teams/' + subTeamID)
      .then((response) => {
        return response.data
      })
  }

  static addMemberToSubTeam(teamID, subTeamID, memberID) {
    return apiClient
      .post(
        '/team/' +
          teamID +
          '/sub-teams/' +
          subTeamID +
          '/members/add/' +
          memberID,
      )
      .then((response) => {
        return response.data
      })
  }
  static deleteMemberFromSubTeam(teamID, subTeamID, memberID) {
    return apiClient
      .post(
        '/team/' +
          teamID +
          '/sub-teams/' +
          subTeamID +
          '/members/remove/' +
          memberID,
      )
      .then((response) => {
        return response.data
      })
  }

  static assignUserToProduct(teamID, productID, userID) {
    return apiClient
      .post('/team/' + teamID + '/products/' + productID + '/user/' + userID)
      .then((response) => {
        return response.data
      })
  }

  static getAnalytics() {
    return apiClient.get('/analytics').then((response) => {
      return response.data.data
    })
  }
}
