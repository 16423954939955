<template>
  <loading v-model:active="isLoading" :is-full-page="false" />
  <div class="row">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for people">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <Bl1nkButton class="w-100 bl1nk-btn" @click="createNewTeamProfile()">
        <CIcon :icon="cilPlus" size="lg" /> Create New Profile
      </Bl1nkButton>
    </div>
    <div class="col-12" v-if="!emptyState">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="teamProfiles"
        @items-per-page-change="(number) => itemsPerPageChange(number)">
        <template v-slot="slotData">
          <Bl1nkButton
            class="w-auto mx-2 delete-btn d-inline-block"
            @click="deleteTeamProfile(slotData.item.id, slotData.item)">
            <img src="@/assets/v3-icons/delete-icon.svg" alt="" />
          </Bl1nkButton>
          <Bl1nkButton
            class="w-auto mx-2 bl1nk-btn d-inline-block"
            @click="editProfileRoute(slotData.item.id, this.teamProfiles)"
            role="button">
            Edit Profile
          </Bl1nkButton>
          <!--          <Bl1nkButton-->
          <!--            class="w-auto mx-1 bl1nk-btn d-inline-block"-->
          <!--            role="button"-->
          <!--            @click="triggerAssignModal(slotData.item)">-->
          <!--            {{ slotData.item }}-->
          <!--          </Bl1nkButton>-->
        </template>
      </Bl1nkSmartTable>
    </div>
    <div class="col-12 mt-3" v-else-if="emptyState">
      <div class="dashed-border text-center">
        <Bl1nkButton class="w-100 grey-color">
          <CIcon :icon="cilPlus" size="lg" />
          Create New Profile
        </Bl1nkButton>
      </div>
    </div>
    <AssignModal @closed="triggerAssignModal(null)" :isVisible="isVisible">
      <template #modalHeader>
        <strong>Assign Product to </strong>
        <span class="ms-1">
          {{ modalItem.name ?? 'N/A' }}
        </span>
      </template>

      <template #modalBody>
        <div class="row">
          <div class="col-10">
            <Bl1nkInput
              v-model="modalSearchValue"
              placeholder="Search for profiles">
              <CIcon :icon="cilSearch" size="lg" />
            </Bl1nkInput>
          </div>
          <div class="col-2">
            <Bl1nkButton class="w-100 bl1nk-btn">
              <CIcon :icon="cilFilter" size="xs" />
              Filter
            </Bl1nkButton>
          </div>
          <div class="col-12">
            <Bl1nkSmartTable
              :search-value="modalSearchValue"
              theme="blue"
              :columns="modalColumns"
              :items="teamProducts"
              :isSelectable="false"
              key="modalItem"
              @row-clicked="
                (item, index) => assignProductToProfile(item, index, modalItem)
              "
              @items-per-page-change="
                (number) => modalItemsPerPageChange(number)
              ">
            </Bl1nkSmartTable>
          </div>
        </div>
        <CSmartPagination
          :activePage="1"
          :pages="maxPagesModal"
          :arrows="false"
          @activePageChange="(page) => modalActivePageChange(page)" />
      </template>
    </AssignModal>
    <CSmartPagination
      :activePage="1"
      :pages="maxPages"
      :arrows="false"
      @activePageChange="(page) => activePageChange(page)" />
  </div>
</template>
<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import AssignModal from '@/components/modal/assignModal'

import ApiWorkspaceService from '@/services/api.workspace.service'
import { CIcon } from '@coreui/icons-vue'
import { cilTrash, cilSearch, cilPlus, cilZoom } from '@coreui/icons'
import store from '@/stores'
import moment from 'moment'
import editProfileRoute from '@/mixins/editProfileRoute'
import _ from 'lodash'
import ApiService from '@/services/api.service'
import router from '@/router'
export default {
  name: 'workspace-profiles',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    AssignModal,
  },
  mixins: [editProfileRoute],
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilZoom,
    }
  },
  data() {
    return {
      searchValue: '',
      modalSearchValue: '',
      teamID: store.state.auth.user?.teams[0].id,
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '70px',
          },
          _props: { style: 'width: 7%' },
        },
        {
          key: 'name',
          label: 'Profile Name',
          _style: {
            width: '30%',
            'min-width': '250px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'created_at',
          label: 'Creation Date',
          _style: {
            width: '15%',
            'min-width': '150px',
          },
          _props: { style: 'width: 15%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'status',
          label: 'Status',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      teamProfiles: [],
      modalColumns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '30%',
            'min-width': '70px',
          },
          _props: { style: 'width: 7%' },
        },
        {
          key: 'username',
          label: 'Product Name',
          _style: {
            width: '70%',
            'min-width': '250px',
          },
          _props: { style: 'width: 35%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      teamProducts: [],
      isVisible: false,
      isLoading: true,
      emptyState: false,
      itemsPerPage: '',
      maxPages: '',
      activePage: '',
      itemsPerPageModal: '',
      maxPagesModal: '',
      activePageModal: '',
    }
  },
  methods: {
    fetchTeamProfiles: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      //TODO: ask ayman to add products to teamprofiles
      this.isLoading = true
      let self = this
      ApiWorkspaceService.fetchTeamProfiles(
        this.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.maxPages = response.meta.last_page
          if (response.data.length !== 0) {
            this.emptyState = false
            self.isLoading = false
            self.teamProfiles = response.data
            self.teamProfiles.forEach((value, index) => {
              self.teamProfiles[index]['created_at'] = moment(
                self.teamProfiles[index]['created_at'],
              ).format('MM/DD/YYYY')
              self.teamProfiles[index]['status'] =
                self.teamProfiles[index]?.products?.length > 0
                  ? 'Active'
                  : 'Inactive'
            })
          } else {
            this.isLoading = false
            this.emptyState = true
          }
        })
        .catch((error) => {
          this.emptyState = true
          this.isLoading = false
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error?.response?.data?.message ?? 'An error occurred',
            showConfirmButton: false,
            timer: 3000,
          })
        })
    },
    1000),
    fetchTeamProducts: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      self.isLoading = true
      ApiWorkspaceService.fetchTeamProducts(
        self.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.maxPages = response.meta.last_page
          if (response.length !== 0) {
            self.isLoading = false
            self.teamProducts = response.data
            self.teamProducts.forEach((value, index) => {
              self.teamProducts[index]['product_owner'] =
                self.teamProducts[index]['owner']?.username ??
                self.teamProducts[index]['team']?.name
              self.teamProducts[index]['type'] =
                self.teamProducts[index]['type'].name
              self.teamProducts[index]['created_at'] = moment(
                self.teamProducts[index]['created_at'],
              ).format('MM/DD/YYYY')
            })
          } else {
            this.emptyState = true
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.emptyState = true
          self.isLoading = false
          self.$swal.fire({
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    1000),
    assignProductToProfile(item) {
      ApiService.assignProfileToProduct(item.id, this.teamProducts.id).then(
        () => {
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Changed',
            showConfirmButton: false,
            timer: 2000,
          })
          this.isVisible = false
          this.getMyProfilesList()
        },
      )
    },
    triggerAssignModal(item) {
      this.modalItem = item
      this.isVisible = !this.isVisible
      this.fetchTeamProducts()
    },
    deleteTeamProfile(profileID, item) {
      this.$swal
        .fire({
          html:
            'Are you sure you want to delete ' +
            '<span style="color: #F33939">' +
            item.name +
            '</span> ?',
          showConfirmButton: false,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: `Delete`,
        })
        .then((result) => {
          if (result.isDenied) {
            ApiWorkspaceService.deleteTeamProfile(this.teamID, profileID).then(
              () => {
                this.teamProfiles = this.teamProfiles.filter(function (value) {
                  return value.id !== profileID
                })
              },
            )
          }
        })
    },
  },
  mounted() {
    this.fetchTeamProfiles()
  },
  watch: {
    teamProfiles(newValue) {
      if (newValue.length <= 0) {
        this.emptyState = true
      }
    },
    searchValue(newValue) {
      this.fetchTeamProfiles(null, newValue, null)
    },
    itemsPerPage(newValue) {
      this.fetchTeamProfiles(newValue, this.searchValue, this.activePage)
    },
    activePage(newValue) {
      this.fetchTeamProfiles(this.itemsPerPage, this.searchValue, newValue)
    },
    modalSearchValue(newValue) {
      this.fetchTeamProducts(null, newValue, null)
    },
    activePageModal(newValue) {
      this.fetchTeamProducts(
        this.itemsPerPageModal,
        this.modalSearchValue,
        newValue,
      )
    },
    itemsPerPageModal(newValue) {
      this.fetchTeamProducts(
        newValue,
        this.modalSearchValue,
        this.activePageModal,
      )
    },
  },
}
</script>

<style scoped></style>
