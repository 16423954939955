<template></template>

<script>
import ApiService from '@/services/api.service'
import router from '@/router'

export default {
  name: 'signOut',
  mounted() {
    this.$swal
      .fire({
        html: 'Are you sure you want to SignOut? ',
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: `Cancel`,
        confirmButtonText: 'SignOut',
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('logout').then(() => {
            this.$router.push({ name: 'Login' })
          })
        }
        if (result.isDenied) {
          router.go(-1)
        }
      })
  },
}
</script>

<style scoped></style>
