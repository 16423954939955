<template>
  <CSmartTable
    class="bl1nk-smart-table"
    :class="
      (isSelectable ? 'is-selectable' : '') + (theme ? ' theme-' + theme : '')
    "
    :tableProps="{
      striped: false,
      hover: false,
      responsive: true,
      borderless: true,
      fixed: true,
    }"
    :activePage="1"
    :items="items"
    :columns="columns"
    :table-filter-label="null"
    :items-per-page="5"
    :items-per-page-options="[5, 10, 15]"
    :sorterValue="{ column: 'status', state: 'asc' }"
    itemsPerPageSelect
    header
    columnSorter
    clickableRows
    @items-per-page-change="(number) => $emit('items-per-page-change', number)"
    @row-click="(item, index) => $emit('row-clicked', item, index)"
    :selectable="isSelectable">
    <template #show_details="{ item, index }">
      <td class="py-0 d-table-cell d-md-none row-settings">
        <CButton
          color="link"
          square
          size="sm"
          class="px-0"
          @click="toggleDetails(item, index)">
          <CIcon :icon="cilOptions" size="xl" />
        </CButton>
      </td>
      <td
        class="ps-3 py-0 d-table-cell d-none d-md-table-cell align-middle text-center text-md-start">
        <div class="v-data">
          <slot :item="item"></slot>
        </div>
      </td>
    </template>
    <template #details="{ item }">
      <CCollapse :visible="this.details.includes(item._id)">
        <CCardBody>
          <div class="my-2 sticky-left">
            <slot :item="item"></slot>
          </div>
        </CCardBody>
      </CCollapse>
    </template>
    <template #badge="{ item }">
      <slot name="badge" :item="item"></slot>
    </template>
  </CSmartTable>
</template>
<script>
import { CIcon } from '@coreui/icons-vue'
import { cilOptions } from '@coreui/icons'

export default {
  props: {
    searchValue: String,
    columns: Object,
    items: Object,
    isSelectable: Boolean,
    theme: String,
  },
  emits: ['row-clicked', 'items-per-page-change'],
  components: {
    CIcon,
  },
  setup() {
    return {
      cilOptions,
    }
  },
  data: () => {
    return {
      currentSearchValue: '',
      name: 'bl1nk-smart-table',
      details: [],
    }
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case 'Active':
          return 'success'
        case 'Inactive':
          return 'secondary'
        case 'Pending':
          return 'warning'
        case 'Banned':
          return 'danger'
        default:
          'primary'
      }
    },
    toggleDetails(item) {
      if (this.details.includes(item._id)) {
        this.details = this.details.filter((_item) => _item !== item._id)
        return
      }
      this.details.push(item._id)
    },
    rowClicked(item, index) {
      // console.log(item)
      // console.log(index)
    },
  },
  watch: {
    searchValue(newValue) {},
  },
}
</script>

<style>
.bl1nk-smart-table {
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border-spacing: 0 15px;
}

.bl1nk-smart-table > div {
  margin-bottom: 10px;
}

.bl1nk-smart-table table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  margin-bottom: 5px;
}

.bl1nk-smart-table .page-item .page-link {
  color: var(--bl1nk-primary-color);
  cursor: pointer;
}

.bl1nk-smart-table .page-item.active .page-link {
  color: var(--bl1nk-secondary-color);
}

.bl1nk-smart-table tbody tr {
  position: relative;
}

.bl1nk-smart-table.theme-blue tbody tr {
  background-color: var(--bl1nk-secondary-light-color);
  color: var(--bl1nk-secondary-color);
  position: relative;
}
.bl1nk-smart-table.theme-blue tbody tr:hover,
.bl1nk-smart-table.theme-blue tbody tr:hover td {
  background-color: var(--bl1nk-secondary-color);
  color: var(--bl1nk-background-color);
  position: relative;
}

.bl1nk-smart-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: var(--bl1nk-background-color);
}

.bl1nk-smart-table tbody tr td.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bl1nk-smart-table.is-selectable thead tr th:first-child {
  width: 40px;
}

.bl1nk-smart-table tbody tr td:first-child {
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bl1nk-smart-table tbody tr td:last-child {
  background-color: white;
}

.bl1nk-smart-table tbody tr td:nth-last-child(3) {
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bl1nk-smart-table tbody tr td:not(:first-child, :last-child) {
  border-left: 0;
  border-right: 0;
}

.sticky-left {
  width: fit-content;
  left: 0;
  position: sticky;
}

@media (min-width: 768px) {
  .bl1nk-smart-table table {
    table-layout: fixed;
  }
}

@media (max-width: 768px) {
  .bl1nk-smart-table .row-settings {
    position: sticky;
    bottom: 0;
    right: 0;
    background-color: var(--bl1nk-tertiary-color);
  }
}
.bl1nk-smart-table.theme-blue tbody tr td {
  background-color: var(--bl1nk-secondary-light-color);
  color: var(--bl1nk-secondary-color);
  position: relative;
}
.bl1nk-smart-table.theme-blue tbody tr:hover td {
  background-color: var(--bl1nk-secondary-color);
  color: var(--bl1nk-background-color);
  position: relative;
}
</style>
