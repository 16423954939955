<template>
  <div class="d-flex px-0 px-md-3 py-1">
    <CHeaderToggler
      class="ps-1 d-none d-md-block"
      @click="$store.dispatch('toggle_sidebar')">
      <CIcon icon="cil-menu" size="xl" />
    </CHeaderToggler>
    <CBreadcrumb class="d-md-down-none me-auto mb-0 ms-2">
      <CBreadcrumbItem
        v-for="item in breadcrumbs"
        :key="item"
        :href="item.active ? '' : item.path"
        :active="item.active">
        {{ item.name }}
      </CBreadcrumbItem>
    </CBreadcrumb>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active:
            route.path === router.currentRoute.value.fullPath ||
            route.meta.hideBreadPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>

<style>
.breadcrumb a {
  text-decoration: none;
  color: var(--bl1nk-primary-color);
}
</style>
