<template>
  <div
    class="d-flex mt-3"
    :class="{ 'normal-right-gutter': block.settings.removable !== true }">
    <div class="handle" role="button" v-if="block.settings.draggable !== false">
      <CIcon :icon="cilCursorMove" size="md" class="text-white" />
    </div>
    <component
      :is="block.component_type"
      v-model="block"
      :key="block"></component>

    <div
      class="input-btns normal-right-gutter"
      v-if="block.settings.removable !== false">
      <div
        class="btn-ghost"
        role="button"
        @click="handleDeleteEvent"
        v-if="block.settings.removable !== false">
        <CIcon :icon="cilTrash" size="xl" class="text-danger" />
      </div>
    </div>

    <div
      class="btn btn-sm btn-ghost handle ms-md-2 text-info"
      @click="visibleOptions = !visibleOptions"
      v-if="Object.keys(this.block.options).length > 0">
      <CIcon :icon="cilCog" size="xl" />
    </div>
  </div>

  <Transition>
    <div class="container" v-if="visibleOptions">
      <OptionsWrapper v-model="this.block.options" :key="this.block" />
    </div>
  </Transition>
</template>

<script>
import { ref } from 'vue'

import { CIcon } from '@coreui/icons-vue'
import {
  cilMove,
  cilTrash,
  cilCog,
  cilMenu,
  cilCursorMove,
} from '@coreui/icons'

import TestBlock from '@/components/Blocks/TestBlock'
import TextBlock from '@/components/Blocks/TextBlock'
import EmailBlock from '@/components/Blocks/EmailBlock'
import ParagraphBlock from '@/components/Blocks/ParagraphBlock'
import ParagraphTitleBlock from '@/components/Blocks/ParagraphTitleBlock'
import VCardBlock from '@/components/Blocks/VCardBlock'
import LinkTreeBlock from '@/components/Blocks/LinkTreeBlock'
import SocialLinksBlock from '@/components/Blocks/SocialLinksBlock'
import PhoneBlock from '@/components/Blocks/PhoneBlock'
import PhotoUploadBlock from '@/components/Blocks/PhotoUploadBlock'
import PinturaUploadBlock from '@/components/Blocks/PinturaUploadBlock'
import HeaderImageBlock from '@/components/Blocks/HeaderImageBlock'
import OptionsWrapper from '@/components/Blocks/OptionsWrapper'

export default {
  name: 'BlockWrapper',
  emits: ['delete'],
  data() {
    return {
      visibleOptions: false,
    }
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  components: {
    OptionsWrapper,
    CIcon,
    TextBlock,
    ParagraphBlock,
    ParagraphTitleBlock,
    TestBlock,
    PhoneBlock,
    EmailBlock,
    PhotoUploadBlock,
    PinturaUploadBlock,
    HeaderImageBlock,
    VCardBlock,
    LinkTreeBlock,
    SocialLinksBlock,
  },
  methods: {
    handleDeleteEvent() {
      this.$store.commit('removeValidator', {
        name: this.block.type,
        id: this.block._uuid,
      })
      this.$emit('delete', this.block)
    },
  },
  setup(props, context) {
    const showDrawer = ref(false)

    const toggleDrawer = () => {
      showDrawer.value = !showDrawer.value
    }

    return {
      cilMove,
      cilTrash,
      cilCog,
      cilMenu,
      cilCursorMove,
      showDrawer,
      toggleDrawer,
    }
  },
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.handle {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #acacac;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #acacac;
  padding-left: 10px;
  padding-right: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
