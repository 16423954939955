import apiClient from '@/api-client'

export const state = {
  linkedProductStatus: null,
}
export const actions = {
  getProducts(context) {
    return apiClient.get('/user/products').then((response) => {
      let product = response.data.data
      let count = 0
      product.forEach((value, index) => {
        if (product[index].profile === null) {
          count += 1
        }
      })
      context.commit('setLinkedProductStatus', count)
    })
  },
}
export const mutations = {
  setLinkedProductStatus(state, count) {
    state.linkedProductStatus = count
  },
}
export const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
