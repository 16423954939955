export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Profiles',
    to: '/profiles',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Products',
    to: '/products',
    icon: 'cil-layers',
  },
  {
    component: 'CNavItem',
    name: 'Connections',
    to: '/connections',
    icon: 'cil-bell',
  },
  {
    component: 'CNavItem',
    name: 'Workspace',
    to: '/workspace',
    icon: 'cil-applications-settings',
  },
  {
    component: 'CNavItem',
    name: 'Account',
    to: '/account',
    icon: 'cilSettings',
  },
  {
    component: 'CNavItem',
    name: 'Sign Out',
    class: 'mt-5',
    to: '/SignOut',
    icon: 'cil-lock-locked',
  },
]
