<template>
  <CRow class="w-100 px-2">
    <div
      class="d-flex align-items-center justify-content-between flex-wrap text-center">
      <h3>{{ block.data.text_label?.value ?? 'Link Tree' }}</h3>
      <Bl1nkButton role="button" class="bl1nk-btn" @click="add">
        <CIcon :icon="cilPlus" size="lg" class="me-1" /> Add Link
      </Bl1nkButton>
    </div>
    <CCol md="12">
      <span v-if="blockValues.length === 0" class="no-social-links"
        >Link Tree Empty</span
      >
      <draggable
        :list="blockValues"
        item-key="name"
        handle=".handle"
        group="linkTree">
        <template #item="{ element }">
          <div class="d-flex mt-3 flex-sm-row flex-column">
            <div class="handle" role="button">
              <CIcon :icon="cilCursorMove" size="md" class="text-white" />
            </div>
            <CFormInput
              class="no-border-radius gray-border"
              placeholder="URL"
              aria-label="URL"
              v-model="element.url" />
            <CFormInput
              class="no-border-radius gray-border w-md-50"
              placeholder="Display Text"
              aria-label="Display Text"
              v-model="element.label" />
            <IconPickerOption
              class="no-border-radius gray-border"
              v-model="element.icon"
              :simple="true" />
            <div class="input-btns">
              <div
                class="btn-ghost"
                role="button"
                @click="removeElement(element)">
                <CIcon :icon="cilTrash" size="lg" class="text-danger" />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </CCol>
  </CRow>
</template>

<script>
import { computed } from 'vue'

import useVuelidate from '@vuelidate/core'
import draggable from 'vuedraggable'
import IconPickerOption from '@/components/Blocks/Options/IconPickerOption'

import { CIcon } from '@coreui/icons-vue'
import {
  cilCog,
  cilMove,
  cilMenu,
  cilTrash,
  cilXCircle,
  cilLibraryAdd,
  cilCursorMove,
  cilPlus,
} from '@coreui/icons'

export default {
  name: 'LinkTreeBlock',
  emits: ['update:modelValue'],
  components: {
    CIcon,
    draggable,
    IconPickerOption,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    removeElement(element) {
      this.block['value'] = this.block['value'].filter((value) => {
        return value !== element
      })
    },
    add: function () {
      this.blockValues.push({
        url: '',
        label: '',
        icon: { value: 'fas fa-link' },
      })
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value.split('T')[0]),
    })

    return {
      block,
      v$: useVuelidate(),
      cilMove,
      cilTrash,
      cilCog,
      cilMenu,
      cilLibraryAdd,
      cilCursorMove,
      cilXCircle,
      cilPlus,
    }
  },
  computed: {
    blockValues() {
      if (!this.block['value']) {
        this.block['value'] = [
          { label: '', url: '', icon: { value: 'fas fa-link' } },
          { label: '', url: '', icon: { value: 'fas fa-link' } },
        ]
      }
      return this.block['value']
    },
  },
  validations() {
    let validationRules = {
      // $autoDirty: true,
    }

    // if (this.block.settings.required) {
    //   validationRules = { ...validationRules, ...{ required } }
    // }
    return {
      block: {
        value: validationRules,
      },
    }
  },
}
</script>

<style scoped>
.no-social-links {
  text-align: center;
  display: block;
  color: #9da5b1;
  font-style: italic;
}

.button {
  margin-top: 35px;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

input {
  display: inline-block;
  width: 50%;
}

.text {
  margin: 20px;
}

.handle {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #acacac;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
