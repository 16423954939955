<template>
  <CModal :visible="visible" @close="closeQR" alignment="center">
    <CModalHeader>
      <h4>{{ title }}</h4>
    </CModalHeader>
    <CModalBody class="mx-4 text-center">
      <QrcodeVue :value="url" size="150" />
      <div class="text-center col-10 mx-auto mt-3">
        <div class="inputField">
          <Bl1nkInput
            readonly="true"
            class="mx-auto"
            v-on:focus="$event.target.select()"
            :value="url"
            @click="copy(url)">
            <CIcon :icon="cilCopy" size="lg" />
          </Bl1nkInput>
        </div>
      </div>
    </CModalBody>
  </CModal>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import { cilCopy } from '@coreui/icons'

export default {
  name: 'bl1nk-qr-modal',
  emits: ['closed'],
  components: {
    QrcodeVue,
    Bl1nkInput,
  },
  props: ['visible', 'title', 'url'],
  setup() {
    return {
      cilCopy,
    }
  },
  methods: {
    closeQR() {
      this.$emit('closed')
    },
    copy(value) {
      const el = document.createElement('textarea')
      el.value = value
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
}
</script>

<style scoped></style>
