<template>
  <loading v-model:active="isLoading" :is-full-page="false" />
  <div class="row">
    <div class="col-12">
      <Bl1nkInput v-model="searchValue" placeholder="Search for products">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-12" v-if="!emptyState">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="connectionItems"
        @items-per-page-change="handleItemsPerPageChange">
        <template v-slot="slotData">
          <Bl1nkButton
            class="mx-2 bl1nk-btn d-inline-block"
            @click="toggleDefaultModal(slotData.item)"
            role="button">
            View Connection Details
          </Bl1nkButton>
        </template>
      </Bl1nkSmartTable>
    </div>
    <div class="col-12 mt-3" v-else-if="emptyState">
      <div class="dashed-border text-center">
        <Bl1nkButton class="w-100 grey-color">
          You don't have connections yet, Let's start bl1nking!
        </Bl1nkButton>
      </div>
    </div>
    <default-modal
      :defaultModalVisibility="defaultModalVisibility"
      @close="
        () => {
          defaultModalVisibility = false
        }
      ">
      <template #modalHeader>
        <h5 class="grey-color pe-2">From:</h5>
        <h5>{{ modalItem.name }}</h5>
      </template>

      <template #modalBody>
        <div class="d-flex justify-content-between">
          <h5 class="blue-text">
            <a :href="'mailto:' + modalItem.email">
              {{ modalItem.email }}
            </a>
          </h5>
          <h5 class="blue-text">
            <a :href="'tel:' + modalItem.phone_number">
              {{ modalItem.phone_number }}
            </a>
          </h5>
        </div>
        <div class="white-box p-4 mt-2">
          {{ modalItem?.description ?? 'Description Empty' }}
        </div>
      </template>
    </default-modal>
  </div>
</template>

<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import DefaultModal from '@/components/modal/defaultModal'

import { cilTrash, cilSearch, cilPlus } from '@coreui/icons'
import ApiService from '@/services/api.service'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'connections-by-products',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    DefaultModal,
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
    }
  },
  data() {
    return {
      searchValue: '',
      isLoading: true,
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '8%',
            'min-width': '40px',
          },
          _props: { style: 'width: 8%' },
        },
        {
          key: 'name',
          label: 'Connection Name',
          _style: {
            width: '20%',
            'min-width': '250px',
          },
          _props: { style: 'width: 20%' },
        },
        {
          key: 'products_profile',
          label: "Product's Profile",
          _style: {
            width: '20%',
            'min-width': '150px',
          },
          _props: { style: 'width: 20%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'email',
          label: 'Email',
          _style: {
            width: '20%',
            'min-width': '150px',
          },
          _props: { style: 'width: 20%' },
          filter: false,
          sorter: false,
        },
        {
          key: 'date',
          label: 'Date',
          _style: {
            width: '10%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      connectionItems: [],
      emptyState: false,
      defaultModalVisibility: false,
      modalItem: null,
    }
  },
  methods: {
    debugTest(item) {
      console.log(item.id)
    },
    fetchMyConnections: _.throttle(async function (limit, searchValue) {
      this.isLoading = true
      ApiService.fetchMyConnections(limit, searchValue).then((response) => {
        if (response.data.length > 0) {
          this.isLoading = false
          this.emptyState = false
          this.connectionItems = response.data
          this.connectionItems.forEach((value, index) => {
            value['products_profile'] = value['product']['profile'].name
            value['_cellProps'] = {
              products_profile: { class: 'ellipsis' },
              name: { class: 'ellipsis' },
            }
            value['date'] = moment(value['created_at']).format('MM/DD/YYYY')
          })
        } else {
          this.isLoading = false
          this.emptyState = true
        }
      })
    }, 1000),
    toggleDefaultModal(items) {
      this.modalItem = items
      this.defaultModalVisibility = true
    },
    handleItemsPerPageChange(pageFilterNumber) {
      this.fetchMyConnections(pageFilterNumber, null)
    },
  },
  watch: {
    searchValue(newValue) {
      this.fetchMyConnections(null, newValue)
    },
  },
  mounted() {
    this.fetchMyConnections()
  },
}
</script>

<style scoped>
.white-box {
  background-color: #ffffff;
  border-radius: 5px;
}
.blue-text a {
  color: #1c86ff;
}
</style>
