<template>
  <CRow>
    <CCol md="8">
      <CSpinner class="d-block mx-auto mt-5" v-if="this.isInitialLoading" />

      <template v-if="selectedGroupedTemplate">
        <NewElementModal
          v-if="1 === 2"
          :inputs="this.allInputs"
          element-group="General"
          @add-element="addElement"></NewElementModal>

        <div class="builder-sections mt-3">
          <section
            class="builder-section"
            v-for="(inputs, group) in selectedGroupedTemplate"
            :key="group">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap text-center">
              <h2 class="mb-2">{{ group }}</h2>
              <NewElementModal
                :inputs="this.allInputs"
                :filter-inputs="getGroupElements(group)"
                :element-group="group"
                @add-element="addElement"
                v-if="getGroupElements(group).length > 0"></NewElementModal>
            </div>
            <draggable itemKey="id" :list="inputs" handle=".handle">
              <template #item="{ element }">
                <div
                  class="list-group-item mt-3"
                  :class="{
                    'minus-gutter': element.settings.draggable !== false,
                  }">
                  <BlockWrapper
                    :block="element"
                    @delete="deleteBlock(element)"
                    :key="element" />
                </div>
              </template>
            </draggable>
          </section>
        </div>

        <CAlert
          color="danger"
          class="my-2"
          v-for="error in this.serverErrors"
          :key="error"
          >{{ error }}
        </CAlert>

        <CLoadingButton
          color="primary"
          @click="saveProfile"
          class="my-4 d-block save-btn mx-auto"
          :loading="isFormLoading"
          :disabled="!isFormValid"
          >Save
        </CLoadingButton>
      </template>
    </CCol>
    <CCol md="4">
      <h3 class="text-center">Live Preview</h3>
      <div class="smartphone" v-if="previewURL">
        <iframe
          v-if="previewURL"
          :src="previewURL"
          frameborder="0"
          width="100%"
          height="100%">
        </iframe>
      </div>

      <!--      <pre>{{ selectedGroupedTemplate }}</pre>-->
    </CCol>
  </CRow>
</template>
<script>
import _ from 'lodash'

import draggable from 'vuedraggable'
import { CIcon } from '@coreui/icons-vue'
import { cilImage, cilPlus } from '@coreui/icons'

import ProfileModal from './ProfileModal'
import NewElementModal from './NewElementModal'
import BlockWrapper from '@/components/Blocks/BlockWrapper'

import ApiService from '@/services/api.service'

export default {
  name: 'profile',
  components: {
    draggable,
    BlockWrapper,
    NewElementModal,
  },
  data() {
    return {
      profileName: this.$store.getters.getLayoutName,
      allTemplates: null,
      allInputs: null,
      selectedTemplate: null,
      selectedGroupedTemplate: null,
      visibleProfileModal: false,
      isFormValid: true,
      isFormLoading: false,
      isInitialLoading: true,
      serverErrors: [],
      previewURL: null,
    }
  },
  setup() {
    const groupTemplate = (array, groupBy) => {
      return _.groupBy(array, groupBy)
    }

    return {
      cilImage,
      cilPlus,
      groupTemplate,
    }
  },
  methods: {
    saveProfile: _.throttle(function () {
      this.$store.state.validation.validators.forEach(function (v) {
        v.validator.$validate()
      })

      if (!this.profileName) {
        this.serverErrors = ['Profile Name is required']
        return false
      }

      let self = this
      let profileID = self.$route.props.currentProfile.id
      let templateID = self.selectedTemplate.id
      const profileInputs = Object.values(self.selectedGroupedTemplate).flatMap(
        (element) => element,
      )

      self.isFormValid = false
      self.isFormLoading = true
      ApiService.saveProfile(
        this.profileName,
        templateID,
        profileID,
        profileInputs,
      )
        .then(() => {
          self.isFormValid = true
          self.isFormLoading = false
          self.$swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Information Saved Successfully',
            showConfirmButton: false,
            timer: 3000,
          })

          if (self.$route.name !== 'EditProfile') {
            self.$router.push({
              name: 'EditProfile',
              params: {
                id: profileID,
              },
            })
          }
        })
        .catch(function (error) {
          self.isFormValid = true
          self.isFormLoading = false
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            self.serverErrors = Object.values(error.response.data.errors)
          } else {
            self.serverErrors = [error]
          }
        })
    }, 2000),
    updatePreview: _.throttle(function () {
      let self = this
      let profileID = this.$route.props.currentProfile.id
      let templateID = this.selectedTemplate.id
      const profileInputs = Object.values(this.selectedGroupedTemplate).flatMap(
        (element) => element,
      )

      ApiService.updateProfilePreview(templateID, profileID, profileInputs)
        .then((response) => {
          if (response.url) {
            this.previewURL = response.url
          }
        })
        .catch(function (error) {
          self.isFormValid = true
          self.isFormLoading = false
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            self.serverErrors = Object.values(error.response.data.errors)
          } else {
            self.serverErrors = [error]
          }
        })
    }, 2000),
    deleteBlock(block) {
      this.selectedGroupedTemplate[block['group']] =
        this.selectedGroupedTemplate[block['group']].filter((value) => {
          return value !== block
        })

      if (this.selectedGroupedTemplate[block['group']].length === 0) {
        delete this.selectedGroupedTemplate[block['group']]
      }
    },
    addElement(element) {
      if (!element.group) {
        element.group = 'General'
      }

      if (!this.selectedGroupedTemplate[element.group]) {
        this.selectedGroupedTemplate[element.group] = []
      }

      this.selectedGroupedTemplate[element.group].push(
        Object.assign({}, element),
      )
    },
    // async getTemplates() {
    //   let self = this
    //   self.allTemplates = await ApiService.fetchTemplates()
    //   self.allTemplates.forEach((value, index) => {
    //     self.allTemplates[index]['thumbnail_url'] = this.$utils.imagePath(
    //       value['thumbnail_path'],
    //     )
    //   })
    // },
    async getInputs() {
      let self = this
      self.allInputs = await ApiService.fetchInputs()
      self.allInputs.forEach((value, index) => {
        self.allInputs[index]['thumbnail_url'] = this.$utils.imagePath(
          value['thumbnail_path'],
        )
      })
      return true
    },
    getGroupElements(group) {
      if (this.selectedTemplate.inputs_json?.elements[group]) {
        return this.selectedTemplate.inputs_json.elements[group]
      }
      return []
    },
    loadProfile() {
      //This is enough protection as the router is validating the id
      if (this.$route.props && this.$route.props.currentProfile) {
        let currentProfile = this.$route.props.currentProfile
        // this.profileName = currentProfile.name ?? 'My Profile'
        if (currentProfile?.template?.id) {
          //This is no longer needed as we're selecting only 1 template in the appearance Tab
          // this.selectedTemplate = this.allTemplates.find(
          //   (e) => e.id === currentProfile.template.id,
          // )
          this.selectedTemplate.inputs_json.inputs = currentProfile.inputs_json
        }
      }
    },
  },
  async mounted() {
    // await this.getTemplates()
    await this.getInputs()
    this.loadProfile()
    this.isInitialLoading = false
  },
  watch: {
    '$store.state.templates.selectedTemplate': {
      immediate: true,
      deep: true,
      handler(newTemplate) {
        if (newTemplate && !this.selectedTemplate) {
          this.selectedTemplate = newTemplate
          this.$store.commit('clearValidator')
          this.getInputs().then(() => {
            this.loadProfile()
            if (newTemplate.inputs_json) {
              this.selectedGroupedTemplate = this.groupTemplate(
                newTemplate.inputs_json.inputs,
                'group',
              )

              //Fill the default options of the loaded template
              Object.entries(this.selectedGroupedTemplate).forEach(
                ([, fields]) => {
                  fields.forEach((field) => {
                    let thisInput = this.allInputs.find(
                      (e) => e.type === field['type'],
                    )
                    field.component_type = thisInput.component_type

                    if (thisInput.options) {
                      // I commented this so if the template had no options,
                      // but the input had, the element would be loaded without options
                      //
                      // field.options = _.merge(
                      //   Object.assign({}, field.options),
                      //   Object.assign({}, thisInput.options),
                      // )
                      field.options = Object.assign({}, field.options)
                    }
                  })
                },
              )
            } else {
              this.selectedGroupedTemplate = null
            }
          })

          this.isInitialLoading = false
        }
      },
    },
    '$store.getters.getLayoutName': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.profileName = newValue
        }
      },
    },
    '$store.state.validation.validators': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.isFormValid = true
        newValue.every(async (v) => {
          if (v.validator.block.$invalid === true) {
            this.isFormValid = false
            v.validator.$validate()
            return false
          }
        })
      },
    },
    selectedGroupedTemplate: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.updatePreview()
        }
      },
    },
  },
}
</script>
<style scoped>
@import url('maz-ui/css/main.css');

.save-btn {
  width: 100%;
  max-width: 200px;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

.not-disabled:disabled {
  color: inherit;
  background-color: var(--cui-form-select-bg, #fff);
  border-color: inherit;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.builder-sections {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px 20px;
}

.builder-section:not(:first-child) {
  border-top: 1px solid #acacac;
  margin-top: 40px;
  padding-top: 40px;
}

/* The device with borders */
.smartphone {
  position: sticky;
  top: 10px;
  max-width: 320px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>

<style>
.sidebar-backdrop {
  display: none !important;
}

.builder-section h2,
.builder-section h3,
.builder-section h4 {
  color: #0c0c0c;
}

.builder-section h2 {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
}

.builder-section h3 {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.builder-section h4 {
  font-weight: 700;
  font-size: 20px;
}

.builder-section .maz-rounded-lg {
  border-radius: 0;
}

.builder-section .--default-border {
  border-color: #acacac !important;
}

.builder-section .maz-border {
  border-width: 1px;
  border-right: 0;
}

.builder-section .no-border-radius,
.builder-section .no-border-radius .form-control {
  border-radius: 0;
}

.builder-section .no-x-border,
.builder-section .no-x-border .form-control {
  border-left: none !important;
  border-right: none !important;
}

.builder-section .gray-border,
.builder-section .gray-border .form-control {
  border: 1px solid #acacac;
}

.builder-section .no-border-top,
.builder-section .no-border-top .form-control {
  border-top: 0;
}

.builder-section .minus-gutter {
  margin-left: -20px;
  margin-right: -20px;
}

.builder-section .normal-right-gutter {
  margin-right: 20px;
}

.builder-section span {
  color: #acacac;
}
</style>
