<template>
  <div class="container content">
    <h1 class="text-center">CHANGE <span class="bold-font">PASSWORD</span></h1>
    <h6 class="text-muted text-center">Enter your new password</h6>
    <CAlert color="danger" v-if="errorMessage">
      {{ errorMessage }}
    </CAlert>
    <CFormFloating class="mb-4 mt-4">
      <CFormInput
        type="password"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.oldPassword.$model"
        @blur="v$.oldPassword.$touch"
        :invalid="v$.oldPassword.$error" />
      <CFormLabel for="password">Old Password</CFormLabel>
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.oldPassword.$error"
        :invalid="v$.oldPassword.$error">
        This field is required.
      </CFormFeedback>
    </CFormFloating>
    <CFormFloating class="mb-4 mt-4">
      <CFormInput
        type="password"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.newPassword.$model"
        @blur="v$.newPassword.$touch"
        :invalid="v$.newPassword.$error" />
      <CFormLabel for="password">New Password</CFormLabel>
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.newPassword.$error"
        :invalid="v$.newPassword.$error">
        Password must be at least 8 characters long.
      </CFormFeedback>
    </CFormFloating>
    <CFormFloating class="mb-4 mt-4">
      <CFormInput
        type="password"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.confirmNewPassword.$model"
        @blur="v$.confirmNewPassword.$touch"
        :invalid="v$.confirmNewPassword.$error" />
      <CFormLabel for="confirmpassword">Confirm New Password</CFormLabel>
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.confirmNewPassword.$error"
        :invalid="v$.confirmNewPassword.$error">
        Password doesn't match.
      </CFormFeedback>
    </CFormFloating>
    <loading v-model:active="isLoading" :is-full-page="true" />
    <CButton
      class="gStartedbtn col-12 py-2 mb-3"
      @click="changeAccountPassword"
      :disabled="
        v$.oldPassword.$error ||
        oldPassword.length == 0 ||
        v$.confirmNewPassword.$error ||
        v$.newPassword.$error ||
        confirmNewPassword.length == 0 ||
        newPassword.length == 0
      "
      >CHANGE PASSWORD</CButton
    >
  </div>
</template>

<script>
import { required, minLength, sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
export default {
  name: 'changePassword',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: null,
      isLoading: false,
    }
  },
  validations() {
    return {
      oldPassword: {
        required,
        minLength: minLength(8),
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      confirmNewPassword: {
        required,
        sameAs: sameAs(this.newPassword),
      },
    }
  },
  methods: {
    changeAccountPassword() {
      this.isLoading = true
      this.errorMessage = null
      let self = this
      this.$axios
        .post('/auth/change-password', {
          old_password: self.oldPassword,
          password: self.newPassword,
          password_confirmation: self.confirmNewPassword,
        })
        .then(() => {
          self.isLoading = false
          this.$swal.fire({
            icon: 'success',
            title: 'Password Successfully Changed',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch((error) => {
          self.isLoading = false
          if (error.response.status === 403) {
            this.errorMessage = 'Old password do not match our records'
          }
        })
    },
  },
}
</script>

<style scoped>
@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}
.content {
  margin-top: 7rem;
}
</style>
