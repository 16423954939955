import _ from 'lodash'

const state = {
  displayMessages: [],
  validators: [],
}

const getters = {
  getDisplayMessages: (state) => state.displayMessages,
  getValidators: (state) => state.validators,
}

const actions = {}

const mutations = {
  clearValidator: (state) => (state.validators = []),
  addDisplayMessage: (state, message) => state.displayMessages.push(message),
  removeValidator: (state, validator) => {
    var index = 0
    while (index !== -1) {
      index = _.findIndex(state.validators, (searchValidator) => {
        return (
          // searchValidator.name === validator.name &&
          searchValidator.id === validator.id
        )
      })
      if (index !== -1) {
        state.validators.splice(index, 1)
      }
    }
  },
  addValidator: (state, validator) => {
    mutations.removeValidator(state, validator)

    state.validators.push(validator)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
