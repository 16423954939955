<template>
  <CRow class="w-100 g-0">
    <div
      class="d-flex flex-md-row flex-column align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between">
        <h6 class="ms-2 me-2">
          {{ block.data.text_label?.value ?? 'Social Links' }}
        </h6>
        <CFormSwitch
          v-model="this.block['isDirect']"
          v-c-tooltip="
            'Bl1nk Direct allows you to open a link directly whenever you tap with anyone'
          "
          label="Direct"
          id="socialLinkDirect" />
      </div>
      <Bl1nkButton role="button" class="bl1nk-btn" @click="visibleModal = true">
        <CIcon :icon="cilPlus" size="lg" class="me-1" /> Add Social Link
      </Bl1nkButton>
    </div>
    <CCol md="12">
      <CAlert color="light" v-if="this.block['isDirect']">
        Drag the link you would like to directly open to the first item slot
      </CAlert>

      <span v-if="blockValues.length === 0" class="no-social-links"
        >Social Links Empty</span
      >
      <draggable
        :list="blockValues"
        item-key="name"
        handle=".handle"
        group="socialLinksBlock"
        :class="{ 'is-direct': this.block['isDirect'] }">
        <template #item="{ element, index }">
          <div class="d-flex align-items-center social-links">
            <div class="handle mx-2" role="button">
              <CIcon :icon="cilMenu" size="xl" />
            </div>

            <IconLinkBlock v-model="blockValues[index]" :key="element" />

            <div
              class="btn-ghost mx-2"
              role="button"
              @click="removeElement(element)">
              <CIcon :icon="cilXCircle" size="xl" />
            </div>
          </div>
        </template>
      </draggable>
    </CCol>
  </CRow>

  <CModal
    :visible="visibleModal"
    @close="
      () => {
        visibleModal = false
      }
    ">
    <CModalHeader>
      <CModalTitle>Social Links</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <h3 class="m-0">Customize your Bl1nk</h3>
      <p>Add social media links to your bl1nk page</p>
      <div class="elements-grid">
        <CCard
          v-for="platform in socialPlatforms"
          :key="platform"
          class="m-1 my-2"
          @click="add(platform)">
          <CCardBody role="button">
            <img :src="platform.iconURL" alt="" class="w-100" />
            <CCardTitle class="m-0">{{ platform.platform }}</CCardTitle>
          </CCardBody>
        </CCard>
      </div>
    </CModalBody>
  </CModal>
</template>

<script>
import { computed } from 'vue'

import useVuelidate from '@vuelidate/core'
import draggable from 'vuedraggable'

import ApiService from '@/services/api.service'

import IconLinkBlock from '@/components/Blocks/IconLinkBlock'

import { CIcon } from '@coreui/icons-vue'
import {
  cilCog,
  cilLibraryAdd,
  cilMenu,
  cilMove,
  cilPlus,
  cilTrash,
  cilXCircle,
} from '@coreui/icons'
import _ from 'lodash'

export default {
  name: 'SocialLinksBlock',
  emits: ['add-social', 'update:modelValue'],
  components: {
    CIcon,
    draggable,
    IconLinkBlock,
  },
  data() {
    return {
      visibleModal: false,
      socialPlatforms: {},
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.fetchIcons()
    this.block['isDirect'] = false
  },
  methods: {
    async fetchIcons() {
      let self = this
      let socialIcons = await ApiService.fetchAssets('social-icons')

      socialIcons.forEach(function (icon) {
        let fileName = icon
          .split('/')[2]
          .replace('.png', '')
          .replace('-', ' ')
          .replace('_', ' ')
        self.socialPlatforms[fileName] = {
          iconURL: process.env.VUE_APP_IMAGE_HOST_URL + icon,
          platform: fileName.replace(/(^.|\s+.)/g, (m) => m.toUpperCase()),
        }
      })
    },
    removeElement(element) {
      this.block['value'] = this.block['value'].filter((value) => {
        return value !== element
      })
    },
    add: function (platform) {
      this.visibleModal = false
      this.blockValues.push({
        value: {
          url: '',
          icon: platform.iconURL,
          platform: platform.platform,
        },
        data: {
          url_label: {
            name: 'url_label',
            value: 'Profile URL',
          },
          label_label: {
            name: 'label_label',
            value: 'Label',
          },
        },
        settings: {
          required: false,
        },
      })
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value.split('T')[0]),
    })

    return {
      block,
      v$: useVuelidate(),
      cilMove,
      cilTrash,
      cilCog,
      cilMenu,
      cilPlus,
      cilLibraryAdd,
      cilXCircle,
    }
  },
  computed: {
    blockValues() {
      if (!this.block['value']) {
        this.block['value'] = []
      }
      return this.block['value']
    },
  },
  validations() {
    let validationRules = {
      // $autoDirty: true,
    }

    // if (this.block.settings.required) {
    //   validationRules = { ...validationRules, ...{ required } }
    // }
    return {
      block: {
        value: validationRules,
      },
    }
  },
}
</script>

<style scoped>
.is-direct .social-links:nth-child(1) {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 5px;
  margin-bottom: 15px;
}

.no-social-links {
  text-align: center;
  display: block;
  color: #9da5b1;
  font-style: italic;
}

.button {
  margin-top: 35px;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

input {
  display: inline-block;
  width: 50%;
}

.text {
  margin: 20px;
}

.elements-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
}

@media (min-width: 992px) {
  .elements-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card:hover {
  opacity: 0.8;
  background-color: #e5e5e5;
}

.card-body {
  padding: 1rem 0.5rem;
  text-align: center;
}

.card-body img {
  max-width: 60px;
  margin-bottom: 10px;
}

.card-text:last-child {
  font-size: 14px;
}
</style>
