<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6">
        <analytics-widgets
          :icon-path="require('@/assets/v3-icons/hand-icon.svg')"
          widget-header-title="Taps"
          :widget-body="analytics['total_taps']"
          widget-footer="Total of times your BL1NK products have been tapped."></analytics-widgets>
      </div>
      <div class="col-md-6 mt-md-0 mt-3">
        <analytics-widgets
          :icon-path="require('@/assets/v3-icons/interactions.svg')"
          widget-header-title="Interactions"
          :widget-body="analytics['total_social_links']"
          widget-footer="Total of times an interaction was made with your BL1NK products."></analytics-widgets>
      </div>
      <div class="col-md-6 mt-3">
        <analytics-widgets
          :icon-path="require('@/assets/v3-icons/active_products.svg')"
          widget-header-title="Active Products"
          :widget-body="analytics['active_products']"
          widget-footer="Total of active BL1NK products."></analytics-widgets>
      </div>
      <div class="col-md-6 mt-3">
        <analytics-widgets
          :icon-path="require('@/assets/v3-icons/saves.svg')"
          widget-header-title="Saves"
          :widget-body="analytics['total_saves']"
          widget-footer="Total of times your BL1NK profiles have been saved."></analytics-widgets>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import ApiService from '@/services/api.workspace.service'
import AnalyticsWidgets from '@/components/widgets/analytics-widgets'
export default {
  name: 'dashboard',
  components: {
    AnalyticsWidgets,
  },
  data() {
    return {
      analytics: {},
    }
  },
  mounted() {
    this.getAnalytics()
  },
  methods: {
    async getAnalytics() {
      this.analytics = await ApiService.getAnalytics()
      console.log(this.analytics)
    },
  },
}
</script>

<style scoped>
.table {
  border-bottom-width: 0px;
}

.widget1 {
  background-color: #624df0;
  border-radius: 10px;
}

.widget2 {
  background: #2480fa;
  border-radius: 10px;
}

.widget3 {
  background: #1ab0a3;
  border-radius: 10px;
}

.widget4 {
  background: #f36239;
  border-radius: 10px;
}

.table-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(28, 134, 255, 0.1);
}

.greyBox {
  background: #f9f9f9;
  border-radius: 10px;
}

.number {
  color: #1c86ff;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 5px 3px;
}

.description {
  color: #0c0c0c;
  font-weight: bold;
  font-size: 0.8rem;
}

.white {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(28, 134, 255, 0.1);
  border-radius: 10px;
}

.link-style {
  color: #1c86ff;
  text-decoration: none;
}

/*h4{*/
/*  font-weight: bold;*/
/*}*/
.bold-font {
  font-weight: bold;
}

.normal-font {
  font-weight: normal;
}
</style>
