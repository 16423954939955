<template>
  <loading v-model:active="isLoading" :is-full-page="true" />

  <div class="container text-center">
    <CRow class="justify-content-center content">
      <h1>VERIFY YOUR <span class="bold-font">EMAIL ADDRESS</span></h1>
      <h6 class="text-muted mt-3 mb-3">
        We’ve just sent you an email to verify your account, check your inbox:
      </h6>
      <div v-if="resend">
        <CFormFloating class="mb-4">
          <CFormInput
            type="email"
            id="email"
            placeholder="name@example.com"
            v-model="emailaddress" />
          <CFormLabel for="email">Email address or Username</CFormLabel>
          <CButton class="gStartedbtn mt-2" @click="sendEmailFromRegistration">
            RESEND</CButton
          >
        </CFormFloating>
      </div>
      <h6 v-if="!resend">
        Did not receive a code?
        <span class="link-text" @click="resendEmail">Resend</span>
      </h6>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'verifyEmail',
  data() {
    return {
      testinput: '',
      resend: false,
      emailaddress: '',
      isLoading: false,
    }
  },
  methods: {
    resendEmail() {
      if (localStorage.getItem('user')) {
        this.isLoading = true
        this.$axios.post('/verify-resend').then((response) => {
          this.isLoading = false
          this.$swal.fire({
            icon: 'success',
            title: 'Email sent',
            showConfirmButton: false,
            timer: 1500,
          })
        })
      } else {
        this.resend = true
      }
    },
    sendEmailFromRegistration() {
      let self = this
      this.isLoading = true
      this.$axios
        .post('/verify-resend', {
          email: self.emailaddress,
        })
        .then((response) => {
          this.isLoading = false
          self.$swal.fire({
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch((error) => {
          self.isLoading = false
          if (error.response) {
            self.$swal.fire({
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
    },
    // next(e) {
    //   e.target?.nextSibling?.focus()
    // },
  },
}
</script>

<style scoped>
@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}
.content {
  margin-top: 10rem;
}
.inputs input {
  width: 56px;
  height: 56px;
  box-shadow: 0px 4px 15px rgba(28, 134, 255, 0.1);
  border-radius: 10px;
  border: none;
  margin: 0 5px;
  text-align: center;
}
.inputs input:focus {
  outline: none;
}
</style>
