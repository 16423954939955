<template>
  <div class="content px-0 py-3">
    <div class="row ps-3 ps-md-5">
      <h6 class="ps-md-0">Hi, {{ userInfo.username }} !</h6>
    </div>
    <div class="row mt-4">
      <div class="col-md-1 ps-md-5 text-center">
        <img class="w-100 profile-img" src="/img/avatar.30898b6f.png" alt="" />
      </div>
      <div class="col-md-3 mx-md-3 ps-4 ps-md-0">
        <h6>{{ userInfo.username }}</h6>
        <h6 class="text-muted">
          {{ userInfo.phone_number }} <br />{{ userInfo.email }}
        </h6>
      </div>
      <div class="px-5">
        <hr />
      </div>
      <h6 class="ps-md-5 ps-4 btn-style" @click="changePassword">
        CHANGE PASSWORD
      </h6>
      <div class="px-5">
        <!--        <hr />-->
      </div>
      <!--      <h6 class="ps-5 btn-style" @click="signOut">SIGN OUT</h6>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'account',
  methods: {
    changePassword() {
      this.$router.push({ name: 'ChangeAccountPassword' })
    },
    // signOut() {
    //   this.$store.dispatch('logout').then(() => {
    //     this.$router.push({ name: 'Login' })
    //   })
    // },
  },
  computed: {
    userInfo() {
      return this.$store.getters.user
    },
  },
}
</script>

<style scoped>
.profile-img {
  max-width: 70px;
  border-radius: 50%;
  margin: 5px;
}

.content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(28, 134, 255, 0.1);
}
h6 {
  margin-bottom: 0.1rem;
}
.btn-style {
  cursor: pointer;
}
</style>
