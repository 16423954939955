import { createRouter, createWebHistory } from 'vue-router'
import store from '@/stores'

import FluidLayout from '@/layouts/FluidLayout'
import GuestLayout from '@/layouts/GuestLayout'

import Page404 from '@/views/page404'

import RegisterPage from '@/views/Login/registerPage'
import LoginPage from '@/views/Login/loginPage'
import VerifyEmail from '@/views/Login/verifyEmail'
import GetStarted from '@/views/Login/getStarted'
import SecretCode from '@/views/Login/secretCode'
import ForgotPassword from '@/views/Login/forgotPassword'
import ResetPassword from '@/views/Login/resetPassword'

import SelectTemplate from '@/views/GetStarted/selectTemplate'
import PersonalInfo from '@/views/GetStarted/personalInfo'

import Dashboard from '@/views/Dashboard/dashboard'
import MyProducts from '@/views/Dashboard/myProducts'
import MyProfiles from '@/views/Dashboard/myProfiles'
import Connections from '@/views/Dashboard/connections'
import Workspace from '@/views/Dashboard/workspace'
import Account from '@/views/Dashboard/account'
import Analytics from '@/views/Dashboard/analytics'
import ChangeAccountPassword from '@/views/Dashboard/changeAccountPassword'
import EditProfile from '@/views/ProfileBuilder/editProfile'
import ProfileBuilder from '@/views/ProfileBuilder/profile-builder'
import ManageTeam from '@/views/Dashboard/manageTeam'
import NoTeamPage from '@/views/Dashboard/NoTeamPage'

import Testing from '@/views/testing'

import SignOut from '@/views/signOut'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: FluidLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            title: 'Dashboard',
            subTitle: 'Here’s an overall look into your account.',
          },
        },
        {
          path: 'products',
          name: 'MyProducts',
          component: MyProducts,
          meta: {
            title: 'Products',
            subTitle: 'Manage and link your BL1NK products.',
          },
        },
        {
          path: 'team/:id/profiles',
          name: 'MyTeamProfiles',
          component: MyProfiles,
          meta: { title: 'My Team Profiles' },
        },
        {
          path: 'connections',
          name: 'Connections',
          component: Connections,
          meta: {
            title: 'Connections',
            subTitle: 'Manage all your BL1NK connections.',
          },
        },
        {
          path: 'account',
          name: 'account',
          component: Account,
          meta: { title: 'Account' },
        },
        {
          path: 'account/change-password',
          name: 'ChangeAccountPassword',
          component: ChangeAccountPassword,
          meta: { title: 'Change Password', subTitle: 'Change your password' },
        },
        {
          path: 'analytics',
          name: 'Analytics',
          component: Analytics,
          meta: { title: 'Analytics' },
        },
        {
          path: 'profile-builder-test',
          name: 'Name',
          component: ProfileBuilder,
        },
        {
          path: 'NotPartOfTeam',
          name: 'Not Part Of Team',
          component: NoTeamPage,
        },
        {
          path: 'signOut',
          name: 'SignOut',
          component: SignOut,
        },
        {
          path: 'profiles',
          name: 'MyProfiles',
          component: MyProfiles,
          meta: {
            title: 'Profiles',
            subTitle: 'Manage and create your BL1NK profiles.',
          },
        },
        {
          path: '/profile',
          redirect: 'profiles',
          name: 'Profiles',
          component: ProfileBuilder,
          meta: { requiresAuth: true },
          children: [
            {
              path: 'new',
              name: 'NewProfile',
              meta: { title: 'Profile' },
            },
            {
              path: 'edit/:id',
              name: 'EditProfile',
              meta: {
                title: 'Profile Builder',
                subTitle: 'Start editing your BL1NK profile.',
              },
            },
          ],
        },
        {
          path: '/team/:teamID/profile',
          redirect: '/profiles',
          name: 'TeamEditProfile',
          meta: { requiresAuth: true },
          children: [
            {
              path: 'new',
              name: 'NewTeamProfile',
              component: ProfileBuilder,
              meta: { title: 'New Profile', hideBreadPath: true },
            },
            {
              path: 'edit/:id',
              name: 'EditTeamProfile',
              component: ProfileBuilder,
              meta: { title: 'New Profile', hideBreadPath: true },
            },
          ],
        },
      ],
    },
    {
      path: '/workspace',
      name: 'Workspace',
      component: FluidLayout,
      meta: {
        requiresTeam: true,
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: Workspace,
          meta: {
            title: 'Workspace',
            subTitle: 'Manage your company’s workspace.',
          },
        },
        {
          path: 'manage-team/:id',
          name: 'Manage Team',
          component: ManageTeam,
          meta: {
            title: 'Manage Team',
            subTitle: 'Manage your BL1NK account',
            hideBreadPath: true,
          },
        },
      ],
    },

    {
      path: '/',
      component: GuestLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/build-profile/template',
          name: 'SelectTemplate',
          component: SelectTemplate,
          meta: { title: 'Select Template' },
        },
        {
          path: '/build-profile/personal-info',
          name: 'PersonalInfo',
          component: PersonalInfo,
          meta: { title: 'Personal Information' },
        },
      ],
    },
    {
      path: '/auth',
      redirect: '/login',
      component: GuestLayout,
      meta: { isGuest: true },
      children: [
        { path: '/register', name: 'Register', component: RegisterPage },
        { path: '/login', name: 'Login', component: LoginPage },
        { path: '/verify-email', name: 'VerifyEmail', component: VerifyEmail },
        { path: '/get-started', name: 'GetStarted', component: GetStarted },
        { path: '/secret-code', name: 'SecretCode', component: SecretCode },
        {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
        },
        {
          path: '/reset-password/:token',
          name: 'ResetPassword',
          component: ResetPassword,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: Page404,
    },
  ],
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('access_token')

  if (to.meta.requiresAuth && !loggedIn) {
    next({ name: 'Login' })
  } else if (to.meta.isGuest && loggedIn) {
    next({ name: 'Dashboard' })
  } else if (to.meta.requiresAuth && loggedIn) {
    store.dispatch('init').then((response) => {
      if (to.meta.requiresTeam && response.teams.length === 0) {
        next({ name: 'Not Part Of Team' })
      }
      //  alert('before !response')
      if (!response) {
        next({ name: 'Login' })
      } else {
        next()
      }
    })
  } else {
    next()
  }
})

export default router
