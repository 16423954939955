<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="sidebarVisibilityChanged">
    <CSidebarBrand>
      <img
        class="py-4 navbar-brand-logo ms-5"
        src="../assets/png/Navbar-Logo.png"
        alt="" />
    </CSidebarBrand>

    <AppSidebarNav class="appsidebar pt-2" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import logoNegative from '@/assets/brand/logo-white.svg'
import sygnet from '@/assets/brand/logo-white-sm.svg'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  methods: {
    sidebarVisibilityChanged(newValue) {
      this.$store.dispatch('update_sidebar_visibility', newValue)
    },
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.getters.sidebarUnfoldable),
      sidebarVisible: computed(() => store.getters.sidebarVisible),
    }
  },
  // watch: {
  //   '$store.state.theme.sidebarVisible': {
  //     immediate: true,
  //     deep: true,
  //     handler(newValue) {
  //       // console.log('sidebarVisible handler', newValue)
  //     },
  //   },
  //   sidebarVisible(newValue) {
  //     // console.log('sidebarVisible', newValue)
  //   },
  // },
}
</script>
<style scoped>
.navbar-brand-logo {
  width: 35%;
}
</style>
