<template>
  <div class="m-1 mt-2 w-100">
    <h6>{{ block.data.text_label?.value ?? 'Image Upload' }}</h6>
    <div class="dotted-content text-start">
      <CRow>
        <CCol md="10" class="align-self-center">
          <a @click="toggleShow">
            <CButton color="info" class="text-white">Select Image</CButton>
          </a>
        </CCol>
        <CCol md="2">
          <CImage
            v-if="imgDataUrl && !block.value"
            rounded
            thumbnail
            :src="imgDataUrl"
            width="150" />

          <CImage
            v-if="block.value"
            rounded
            thumbnail
            :src="this.$utils.imagePath(block.value)"
            width="150" />
        </CCol>
      </CRow>

      <my-upload
        field="image"
        :url="
          this.$apiAddress +
          '/user/profiles/' +
          this.$route.params.id +
          '/builder/upload/image'
        "
        lang-type="en"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
        :width="300"
        :height="300"
        :params="params"
        :headers="headers"
        :no-square="false"
        :no-circle="true"
        :no-rotate="false"
        img-format="png"></my-upload>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import myUpload from 'vue-image-crop-upload'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'PhotoUploadBlock',
  emits: ['update:modelValue'],
  components: { 'my-upload': myUpload },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      show: false,
      params: {
        token: '123456798',
        name: 'avatar',
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
      imgDataUrl: '', // the datebase64 url of created image
    }
  },
  methods: {
    testMe() {
      this.block.value = 'Tested!'
    },
    toggleShow() {
      this.show = !this.show
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      this.block.value = jsonData.file
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {},
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block, v$: useVuelidate() }
  },
  validations() {
    return {
      block: {
        value: {
          required,
          email,
          $autoDirty: true,
        },
      },
    }
  },
}
</script>

<style scoped></style>
