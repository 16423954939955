<template>
  <CRow class="w-100 g-0">
    <CCol md="12">
      <TextBlock
        class="no-border-radius gray-border no-x-border"
        v-model="blockValues['title']"
        :key="blockValues['title']" />
    </CCol>
    <CCol md="12">
      <ParagraphBlock
        class="no-border-radius no-x-border gray-border no-border-top"
        v-model="blockValues['paragraph']"
        :key="blockValues['paragraph']" />
    </CCol>
  </CRow>
</template>

<script>
import { computed } from 'vue'

import TextBlock from '@/components/Blocks/TextBlock'
import ParagraphBlock from '@/components/Blocks/ParagraphBlock'

export default {
  name: 'ParagraphTitleBlock',
  emits: ['update:modelValue'],
  components: {
    TextBlock,
    ParagraphBlock,
  },
  data() {
    return {
      inputs: {
        title: {
          label: 'Title',
          placeholder: 'About Us',
          required: false,
        },
        paragraph: {
          label: 'Paragraph',
          required: false,
        },
      },
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  beforeMount() {
    this.inputs.for
    for (const [key, value] of Object.entries(this.inputs)) {
      this.blockValues[key] = {
        ...this.blockValues[key],
        data: {
          text_label: {
            value: value.label,
          },
        },
        settings: {
          required: value.required,
        },
      }
    }
  },
  computed: {
    blockValues() {
      if (!this.block['value']) {
        this.block['value'] = {}
      }
      return this.block['value']
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block }
  },
}
</script>

<style scoped></style>
