<template>
  <CSpinner class="d-block mx-auto mt-5" v-if="this.isInitialLoading" />
  <div class="container-fluid appearance">
    <CRow>
      <CCol class="white-background" md="9">
        <CRow>
          <CCol md="6">
            <h3>Layout Information</h3>
            <span>This information won't show on your profile</span>
            <div class="mt-3 mb-3">
              <CFormInput
                :invalid="!layoutName"
                feedbackInvalid="Layout name is required"
                class="border-input"
                type="text"
                id="profileName"
                placeholder="Name Your Layout (Ex: Work, Personal, etc.)"
                v-model="layoutName" />
            </div>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol md="12">
            <h3>Layout Template</h3>
            <span>
              Discover and choose our easy to customize templates, made by the
              BL1NK team.
            </span>

            <div class="row mt-3">
              <div
                class="col-6 col-md-3"
                v-for="template in allTemplates"
                :key="template">
                <img
                  :src="template.thumbnail_url"
                  :class="{
                    selected: template?.id === $store.getters.getTemplate?.id,
                  }"
                  alt=""
                  class="w-100 template-img"
                  @click="selectTemplate(template)" />
                <h6 class="text-center mt-3">{{ template.name }}</h6>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCol>
      <CCol md="3">
        <div class="col-10" v-for="template in allTemplates" :key="template">
          <h6 class="text-center">{{ template.name }}</h6>
          <img
            :src="template.thumbnail_url"
            :id="template.id"
            class="w-100 template-preview-img mt-4" />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ApiService from '@/services/api.service'

export default {
  name: 'appearance',
  data() {
    return {
      allTemplates: null,
      isInitialLoading: true,
      layoutName: this.$store.getters.getLayoutName,
    }
  },
  async mounted() {
    await this.getTemplates()
    if (this.allTemplates.length > 0) {
      this.selectTemplate(this.allTemplates[0])
    }
    this.isInitialLoading = false
  },
  methods: {
    async getTemplates() {
      let self = this
      self.allTemplates = await ApiService.fetchTemplates()
      console.log('templates', self.allTemplates)
      self.allTemplates.forEach((value, index) => {
        self.allTemplates[index]['thumbnail_url'] = this.$utils.imagePath(
          value['thumbnail_path'],
        )
      })
    },
    selectTemplate(template) {
      template.id = template.id.toString()
      this.$store.dispatch('set_template', template)
      this.$emit('selected-template', template)
    },
  },
  watch: {
    layoutName(newValue) {
      this.$store.dispatch('set_layout_name', newValue)
    },
  },
}
</script>

<style scoped>
.appearance {
  border-radius: 5px;
  margin-top: 15px;
}
.white-background {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
}

h3 {
  margin-bottom: 2px;
}

span {
  color: #acacac;
}

.template-img {
  outline: 2px dashed #0c0c0c;
  border-radius: 15px;
}
.template-preview-img {
  outline: 7px solid #0c0c0c;
  border-radius: 15px;
}
.template-img:hover {
  cursor: pointer;
  outline: 4px solid #1c86ff;
  border-radius: 15px;
}

.template-img.selected {
  outline: 4px solid #1c86ff;
  border-radius: 15px;
}
h6 {
  font-size: 14px;
  color: #0c0c0c;
}
</style>
