<template>
  <div
    class="d-flex align-items-start justify-content-between flex-grow-1 flex-sm-row flex-column">
    <img
      :src="this.block.value?.icon ?? '#'"
      class="w-100 link-icon my-2 mx-auto mx-sm-2" />
    <CFormFloating class="my-2 mx-0 mx-sm-1 w-100" novalidate :validated="true">
      <CFormInput
        id="urlLink"
        type="url"
        v-model="v$.block.value.url.$model"
        :invalid="v$.block.value.url.$error" />

      <CFormFeedback
        class="d-flex ms-2"
        :invalid="v$.block.value.url.$error"
        v-for="error of v$.$errors"
        :key="error.$uid">
        <strong>{{ error.$message }}</strong>
      </CFormFeedback>

      <CFormLabel for="urlLink">{{
        this.block.data.url_label?.value ?? 'Link Block'
      }}</CFormLabel>
    </CFormFloating>

    <CFormFloating class="my-2 mx-0 mx-sm-1 w-100" novalidate :validated="true">
      <CFormInput
        id="platformLabel"
        type="url"
        v-model="this.block.value.label" />

      <CFormLabel for="platformLabel">{{
        this.block.data.label_label?.value ?? 'Link Label'
      }}</CFormLabel>
    </CFormFloating>
  </div>
</template>

<script>
import { computed } from 'vue'

import blockValidation from '@/mixins/blockValidation'
import useVuelidate from '@vuelidate/core'
import { required, url } from '@vuelidate/validators'

export default {
  name: 'IconLinkBlock',
  emits: ['update:modelValue'],
  mixins: [blockValidation],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block, v$: useVuelidate() }
  },
  beforeMount() {
    if (!this.block.value.label) {
      this.block.value.label = this.block.value.platform
    }
  },
  validations() {
    let validationRules = {
      url,
    }

    if (this.block.settings.required) {
      validationRules = { ...validationRules, ...{ required } }
    }

    return {
      block: {
        value: {
          url: validationRules,
        },
      },
    }
  },
}
</script>

<style scoped>
.link-icon {
  max-width: 50px;
}
</style>
