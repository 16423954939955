<template>
  <bl1nk-tab>
    <template #tabs-name>
      <button
        v-for="tab in tabs"
        :key="tab"
        :class="['tab-button', 'link', { active: currentTab === tab }]"
        @click="currentTab = tab"
        :disabled="tab === 'Builder' && isBuilderEnabled !== true">
        {{ tab }}
      </button>
    </template>
    <template #additional-btn v-if="currentTab === 'Appearance'">
      <button
        :disabled="isBuilderEnabled !== true"
        @click="currentTab = 'Builder'"
        class="bl1nk-btn float-end w-100 justify-content-center">
        Next
      </button>
    </template>
    <template #dynamic-components>
      <KeepAlive>
        <component :is="currentTab"></component>
      </KeepAlive>
    </template>
  </bl1nk-tab>
</template>

<script>
import Bl1nkTab from '@/components/bl1nk/bl1nk-tab/bl1nk-tab'
import Appearance from '@/views/ProfileBuilder/appearance'
import Builder from '@/views/ProfileBuilder/editProfile'
import ApiService from '@/services/api.service'
import ApiWorkspaceService from '@/services/api.workspace.service'
import store from '@/stores'

export default {
  name: 'profile-builder',
  components: {
    Bl1nkTab,
    Builder,
    Appearance,
  },
  data() {
    return {
      tabs: ['Appearance', 'Builder'],
      currentTab: 'Appearance',
    }
  },
  mounted() {
    if (this.$route.props.currentProfile?.inputs_json?.length > 0) {
      this.currentTab = 'Builder'
    }
  },
  watch: {
    '$store.state.templates.selectedTemplate': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          console.log('Template Changed', newValue)
        }
      },
    },
  },
  computed: {
    isBuilderEnabled() {
      return (
        this.$store.getters.getTemplate !== null &&
        this.$store.getters.getTemplate !== '' &&
        !!this.$store.getters.getLayoutName
      )
    },
  },
  async beforeRouteEnter(to, from, next) {
    let profile = null
    let self = this
    if (to.params.id) {
      if (to.params.teamID) {
        await ApiWorkspaceService.fetchTeamProfile(
          to.params.teamID,
          to.params.id,
        )
          .then((response) => {
            profile = response
          })
          .catch(function (error) {
            self.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error?.response?.data?.message ?? 'An Error Occurred',
              showConfirmButton: false,
              timer: 3000,
            })
          })
        // profile = await ApiWorkspaceService.fetchTeamProfile(
        //   to.params.teamID,
        //   to.params.id,
        // )
      } else {
        profile = await ApiService.fetchProfile(to.params.id)
      }
    } else {
      if (to.params.teamID) {
        profile = await ApiWorkspaceService.createFreshTeamProfile(
          to.params.teamID,
        )
      } else {
        profile = await ApiService.createFreshProfile()
      }
    }

    if (profile) {
      store.dispatch('set_layout_name', profile?.name)
      to.props = {
        currentProfile: profile,
      }
      next()
    } else {
      next({ name: '404' })
    }
  },
}
</script>

<style scoped>
.tab-button[disabled]:hover {
  border-bottom: 3px solid #8fc3fa;
  color: #acacac;
}
.bl1nk-btn[disabled]:hover,
.bl1nk-btn[disabled] {
  color: #ffffff;
  cursor: pointer;
  background-color: #acacac;
}
</style>
