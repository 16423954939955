<template>
  <CFormLabel>{{ option.label }}</CFormLabel>
  <CFormSelect aria-label="Default select example" v-model="option.value">
    <option value="">Select Position</option>
    <option value="left">Left</option>
    <option value="right">Right</option>
    <option value="center">Center</option>
  </CFormSelect>
</template>

<script>
import { computed } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'PositionOption',
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const option = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { option, v$: useVuelidate() }
  },
}
</script>

<style scoped></style>
