<template>
  <CContainer fluid class="bl1nk-bg py-2 d-block d-md-none">
    <CHeaderToggler class="ps-1" @click="$store.dispatch('toggle_sidebar')">
      <CIcon icon="cil-menu" size="xl" />
    </CHeaderToggler>
  </CContainer>
  <CContainer fluid class="breadcrumb">
    <AppBreadcrumb></AppBreadcrumb>
  </CContainer>
  <CHeader class="px-0 px-md-3">
    <CContainer fluid class="app-header-underline py-3 mb-3 flex-nowrap">
      <div class="row">
        <div class="col-12">
          <h1>{{ this.$route.meta.title ?? this.$route.name }}</h1>
        </div>
        <div class="col-12">
          <h6 class="grey-color">
            {{ this.$route.meta.subTitle ?? 'Bl1nk Dashboard' }}
          </h6>
        </div>
      </div>
      <CHeaderNav class="ms-auto me-1"> </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownNotif />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderTeam from './AppHeaderTeam'
import { logo } from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownNotif,
  },
  setup() {
    return {
      logo,
    }
  },
  // watch: {
  //   '$store.state.theme.sidebarVisible': {
  //     immediate: true,
  //     deep: true,
  //     handler(newValue) {
  //       // console.log('sidebarVisible handler', newValue)
  //     },
  //   },
  // },
}
</script>

<style scoped>
.breadcrumb {
  background-color: #fff;
  padding: 0;
  margin: 0;
}

h6,
h1 {
  padding: 0;
  margin: 0;
}

.grey-color {
  color: #acacac;
  font-weight: normal;
}

.app-header-underline {
  border-bottom: 1px solid #acacac;
}
</style>
