import axios from 'axios'

// import TokenService from './services/token.service'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

headers['bl1nk-team-id'] = localStorage.getItem('team_id')

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ADDRESS,
  withCredentials: false, // This is the default
  headers: headers,
  timeout: 15000,
})

apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  // const token = TokenService.getLocalAccessToken()
  let bearerToken = localStorage.getItem('access_token')
  config.headers['Authorization'] = 'Bearer ' + bearerToken
  return config
})

apiClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // check if it's a server error
    if (!error.response) {
      console.log('📡 API | Network/Server error')
      return Promise.reject(error)
    }

    // all the error responses
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message)
        console.log('📡 API | Nothing to display', 'Data Not Found')
        break

      case 401: // authentication error, logout the user
        console.log('📡 API | Please login again', 'Session Expired')
        localStorage.removeItem('user')
        break

      case 403:
        console.error(error.response.status, error.message)
        console.log('📡 API | Access denied', 'Data Not Found')
        break

      case 404:
        console.error(error.response.status, error.message)
        console.log('📡 API | Dataset not found', 'Data Not Found')
        break

      case 422:
        console.error(
          error.response.status,
          error.message,
          error.response.data.detail,
        )
        console.log('📡 API | Validation error', 'Unprocessable Content')
        break

      default:
        console.error(error.response.status, error.message)
    }
    return Promise.reject(error)
  },
)

export default apiClient
