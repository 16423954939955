<template>
  <CFormFloating class="w-100">
    <MazPhoneNumberInput
      v-model.trim="v$.block.value.$model"
      :default-country-code="block.countryCode ?? 'LB'"
      @update="onPhoneUpdate"
      show-code-on-list
      color="info"
      :error="v$.block.isValid.$error"
      :preferred-countries="['LB', 'FR', 'DE', 'US', 'GB']" />
  </CFormFloating>
  <CFormInput
    id="TextBlock"
    class="rounded-0 border d-none d-md-block w-25"
    type="number"
    placeholder="Phone Extension"
    v-model.trim="block.phoneExtension" />
  <CFormFeedback
    class="d-flex ms-2 d-none"
    v-for="error of v$.$errors"
    :invalid="v$.block.isValid.$error"
    :key="error.$uid">
    <strong>{{ error.$message }}</strong>
  </CFormFeedback>
</template>

<script>
import { computed, ref } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import blockValidation from '@/mixins/blockValidation'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import TextBlock from '@/components/Blocks/TextBlock'

export default {
  name: 'PhoneBlock',
  emits: ['update:modelValue'],
  mixins: [blockValidation],
  components: [MazPhoneNumberInput, TextBlock],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.block.phoneExtension = ''
  },
  methods: {
    onPhoneUpdate(result) {
      this.v$.block.$touch()
      this.block.isValid = result.isValid
      this.block.phoneExtension = result.phoneExtension
      this.block.countryCode = result.countryCode
      this.block.countryCallingCode = result.countryCallingCode
      this.block.nationalNumber = result.nationalNumber
      this.block.formatInternational = result.formatInternational
      this.block.formatNational = result.formatNational
      this.block.uri = result.uri
      this.block.e164 = result.e164
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => {
        return props.modelValue
      },
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block, v$: useVuelidate() }
  },
  validations() {
    return {
      block: {
        value: {
          $autoDirty: false,
        },
        isValid: {
          $autoDirty: false,
        },
      },
    }
  },
  watch: {
    block: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
@import url('maz-ui/css/main.css');
</style>
