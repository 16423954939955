<template>
  <div class="container content">
    <h1 class="mb-4 text-center">
      FORGOT YOUR <span class="bold-font">PASSWORD</span>?
    </h1>
    <h6 class="text-muted text-center">
      Don’t worry! Enter your email to reset your password
    </h6>
    <CFormFloating class="mb-4 mt-5">
      <CFormInput
        type="email"
        id="email"
        placeholder="name@example.com"
        v-model.trim="v$.email.$model"
        :invalid="v$.email.$error"
        @blur="v$.email.$touch" />
      <CFormLabel for="email">Email address or Username</CFormLabel>
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.email.$error"
        :invalid="v$.email.$error">
        Please enter a valid email
      </CFormFeedback>
    </CFormFloating>
    <CButton
      :disabled="v$.email.$error || email.length == 0"
      class="gStartedbtn col-12 py-2 mb-3"
      @click="confirmPassword"
      >SEND</CButton
    >
    <h5>
      Did not recieve and email?
      <span class="link-text" @click.self="confirmPassword">Resend</span>
    </h5>
    <loading v-model:active="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
  name: 'forgotPassword',
  data() {
    return {
      email: '',
      isLoading: false,
      message: '',
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  methods: {
    confirmPassword() {
      this.isLoading = true
      let self = this
      this.$axios
        .post('/forgot-password', {
          email: self.email,
        })
        .then(() => {
          this.isLoading = false
          this.$swal.fire({
            icon: 'success',
            title: 'We have emailed your password reset link!',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch(function (error) {
          self.isLoading = false
          if (error.response) {
            self.message = error.response.data.message

            self.$swal.fire({
              icon: 'error',
              title: self.message,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
}
</script>

<style scoped>
@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}
.content {
  margin-top: 7rem;
}
</style>
