<template>
  <router-view />
</template>

<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      random: '2',
    }
  },
  setup() {
    const store = useStore()
    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
  // mounted() {
  //   let self = this
  //   this.$axios.interceptors.response.use(
  //     (res) => {
  //       return res
  //     },
  //     async (error) => {
  //       if (error.response.status === 401 || error.response.status === 419) {
  //         // // store.dispatch('logout')
  //         self.$router.push({ name: 'Login' })
  //       }
  //
  //       return Promise.reject(error)
  //     },
  //   )
  // },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'BL1NK'
      },
    },
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
//@import "assets/scss/style";
@import 'styles/style';

body {
  background-color: var(--bl1nk-background-color);
  font-family: 'Inter', sans-serif;
}
</style>
