import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/stores/auth.module'
import theme from '@/stores/theme.module'
import validation from '@/stores/validation.module'
import products from '@/stores/products.module'
import templates from '@/stores/templates.module'
import workspaceTabs from '@/stores/workspaceTabs.module'
import linkedProductStatus from '@/stores/linkedProductStatus.module'
const store = createStore({
  modules: {
    auth,
    theme,
    validation,
    products,
    templates,
    workspaceTabs,
    linkedProductStatus,
  },
  plugins: [createPersistedState()],
})

export default store
