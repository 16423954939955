<template>
  <loading v-model:active="isLoading" :is-full-page="false" />

  <div class="row">
    <div class="col-9">
      <Bl1nkTab>
        <template #tabs-name>
          <button
            v-for="tab in tabs"
            :key="tab"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab">
            {{ tab }}
          </button>
        </template>
      </Bl1nkTab>
    </div>
    <div class="col-3">
      <Bl1nkButton
        class="w-auto mx-2 bl1nk-btn d-inline-block float-end"
        @click="debugTest(slotData.item)"
        role="button">
        View All Connections
      </Bl1nkButton>
    </div>
  </div>

  <div class="row mt-3">
    <Bl1nkTab>
      <template #dynamic-components>
        <component :is="currentTab"></component>
      </template>
    </Bl1nkTab>
  </div>
</template>
<script>
import { ref } from 'vue'
import _ from 'lodash'
import ApiService from '@/services/api.service'
import Bl1nkTab from '@/components/bl1nk/bl1nk-tab/bl1nk-tab'
import Products from '@/components/Tabs/connections-by-products'
import Profiles from '@/components/Tabs/connections-by-profiles'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import moment from 'moment'
export default {
  name: 'Connections',
  components: {
    Products,
    Profiles,
    Bl1nkButton,
    Bl1nkTab,
  },
  data() {
    return {
      connectionsList: ref([]),
      isConnectionModalVisible: false,
      isFilterModalVisible: false,
      maxPages: null,
      activePage: 1,
      modelList: ref([]),
      searchValue: '',
      filterType: '',
      filterAPI: '',
      isLoading: false,
      tabs: ['Products'],
      currentTab: 'Products',
    }
  },
  computed: {
    createdAt(connection) {
      return connection
    },
  },
}
</script>

<style scoped>
.connection-icon {
  max-width: 70px;
}
.input-group-text {
  background-color: white;
  border: none;
  border-radius: 10px;
}

.search-bar::placeholder {
  color: #1c86ff;
}

.search-bar {
  border: none;
}

.search-bar:focus {
  outline: none;
}

CTableDataCell {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(28, 134, 255, 0.1);
}

.table-bg {
  background-color: #f9f9f9 !important;
}

.content {
  background-color: #ffffff;
  border-radius: 10px;
}

.jobTitle {
  color: #1c86ff;
  font-size: 14px;
}

.filter-by {
  color: #1c86ff;
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

.search-size {
  width: 100%;
}

.filter-container {
  position: relative;
}

.filter-logo {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  cursor: pointer;
}

.content {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(28, 134, 255, 0.1);
  border-radius: 10px;
}

.overflow {
  overflow: hidden;
  resize: none;
  height: 170px !important;
}
</style>
