export const state = {
  tabState: 'People',
}
export const actions = {
  ['setTabState'](context, currentTab) {
    context.commit('setTabState', currentTab)
  },
}
export const mutations = {
  ['setTabState'](state, currentTab) {
    state.tabState = currentTab
  },
}
export const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
