<template>
  <div class="container mt-5">
    <h1>LET'S BUILD YOUR <span class="bold-font">PROFILE</span>.</h1>
    <p class="text-muted mt-3 mb-5">
      Start filling the information you would like to see on your card.
    </p>
    <div class="row justify-content-center text-center align-items-center">
      <div class="col-1 stepper">
        <div class="my-2">1</div>
      </div>
      <div class="col-4">
        <div class="line-design"></div>
      </div>
      <div class="col-1 active-stepper">
        <div class="my-2">2</div>
      </div>
      <div class="col-4">
        <div class="line-design"></div>
      </div>
      <div class="col-1 stepper">
        <div class="my-2">3</div>
      </div>
    </div>

    <div
      class="row justify-content-between text-center align-items-center mt-3 mx-md-4">
      <div class="col-3 text">
        <h6 class="">PICK YOUR TEMPLATE</h6>
      </div>

      <div class="col-2 active-text">
        <h6>PERSONAL INFORMATION</h6>
      </div>

      <div class="col-3 text ps-md-5">
        <h6 class="">LINKS</h6>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 mx-auto">
        <h3>PERONAL INFORMATION</h3>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="text" id="name" placeholder="Display Name" />
          <CFormLabel for="name">Display Name</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="text" id="jobtitle" placeholder="Job Title" />
          <CFormLabel for="jobtitle">Job Title</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="text" id="company" placeholder="Company" />
          <CFormLabel for="company">Company</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="email" id="email" placeholder="Email" />
          <CFormLabel for="email">Email</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="text" id="address" placeholder="Address" />
          <CFormLabel for="address">Address</CFormLabel>
        </CFormFloating>
        <CFormFloating class="mb-4 mt-4 col-md-10">
          <CFormInput type="email" id="Slogan" placeholder="Slogan" />
          <CFormLabel for="Slogan">Slogan</CFormLabel>
        </CFormFloating>
        <div class="imageinput-field col-md-10 py-4 px-3">
          <h6>Profile Image</h6>
          <div class="dotted-content text-center mx-5 pt-4">
            <a @click="toggleShow">
              <img src="../../assets/png/imagecropper.png" alt="" />
              <p class="text-muted">Drag & Drop your image here.(Max: 5MB)</p>
            </a>
            <my-upload
              field="img"
              lang-type="en"
              @crop-success="cropSuccess1"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              v-model="show"
              :width="300"
              :height="300"
              url="/upload"
              :params="params"
              :headers="headers"
              img-format="png"></my-upload>
            <img :src="imgDataUrl1" />
          </div>
        </div>
        <div class="imageinput-field col-md-10 py-4 px-3 mt-4">
          <h6>Company Image</h6>
          <div class="dotted-content text-center mx-5 pt-4">
            <a @click="toggleShow">
              <img src="../../assets/png/imagecropper.png" alt="" />
              <p class="text-muted">Drag & Drop your image here.(Max: 5MB)</p>
            </a>
            <my-upload
              field="img"
              lang-type="en"
              @crop-success="cropSuccess2"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
              v-model="show"
              :width="300"
              :height="300"
              url="/upload2"
              :params="params"
              :headers="headers"
              img-format="png"></my-upload>
            <img :src="imgDataUrl2" />
          </div>
        </div>

        <div class="row my-5">
          <div class="col-md-10">
            <CButton class="cancel-btn col-5" @click="prevPage()"
              >PREVIOUS</CButton
            >
            <CButton class="gStartedbtn col-5 float-end" @click="nextPage()"
              >NEXT</CButton
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="">
          <h3>LIVE PREVIEW</h3>
          <div class="container text-center">
            <img
              class="w-50 position"
              src="../../assets/png/iphone.png"
              alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'

// import TemplateImageInput from '@/views/GetStarted/TemplateImageInput'
export default {
  name: 'personalInfo',
  components: {
    // TemplateImageInput,
    'my-upload': myUpload,
  },
  data() {
    return {
      show: false,
      imgDataUrl1: '',
      imgDataUrl2: '',
      phone: '',
      templateid: this.$store.state.templateId,
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show
    },
    cropSuccess1(imgDataUrl, field) {
      this.imgDataUrl1 = imgDataUrl
    },
    cropSuccess2(imgDataUrl, field) {
      this.imgDataUrl1 = imgDataUrl
    },
    cropUploadSuccess(jsonData, field) {
      //pass
    },
    cropUploadFail(status, field) {
      //pass
    },
    nextPage() {
      this.$router.push({ path: '/socialLinks' })
    },
    prevPage() {
      this.$router.push({ path: '/template' })
    },
    getTemplateById() {
      this.$axios
        .get(
          this.$apiAddress +
            '/templates/template-information/' +
            this.templateid,
        )
        .then((response) => {
          console.log(response.data)
        })
    },
  },
  mounted() {
    this.getTemplateById()
  },
  computed: {
    getid() {
      return this.$store.state.templateId
    },
  },
}
</script>

<style scoped>
.imageinput-field {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgb(28 134 255/10%);
}
.dotted-content {
  border: 2px dashed #a7a7a7;
  cursor: pointer;
}
.position {
  position: sticky;
  top: 100px;
}
.active-stepper {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #1c86ff;
  box-shadow: 0px 4px 15px rgba(28, 134, 255, 0.1);
}
.stepper {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #a7a7a7;
  box-shadow: 0px 4px 15px rgb(244 237 253);
}
.line-design {
  background-color: #a7a7a7;
  height: 1px;
}
h6 {
  font-size: 14px;
}
.active-text h6 {
  color: #1c86ff;
}
.text h6 {
  color: #a7a7a7;
}
</style>
