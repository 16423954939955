<template>
  <div class="container mt-5">
    <h1>LET'S BUILD YOUR <span class="bold-font">PROFILE</span></h1>
    <p class="text-muted mt-3 mb-5">
      Select which design profile template you would like to see on your card.
      <card class=""></card>
    </p>
    <div class="row justify-content-center text-center align-items-center">
      <div class="col-1 active-stepper">
        <div class="my-2">1</div>
      </div>
      <div class="col-4">
        <div class="line-design"></div>
      </div>
      <div class="col-1 stepper">
        <div class="my-2">2</div>
      </div>
      <div class="col-4">
        <div class="line-design"></div>
      </div>
      <div class="col-1 stepper">
        <div class="my-2">3</div>
      </div>
    </div>
    <div
      class="row justify-content-between text-center align-items-center mt-3 mx-md-4">
      <div class="col-3 active-text">
        <h6 class="">PICK YOUR TEMPLATE</h6>
      </div>

      <div class="col-2 text">
        <h6>PERSONAL INFORMATION</h6>
      </div>

      <div class="col-3 text ps-md-5">
        <h6 class="">LINKS</h6>
      </div>
    </div>

    <div class="template mt-4 mb-5">
      <h3>PROFILE TEMPLATE</h3>
      <div
        class="row text-center mt-5 templateProfiles justify-content-between">
        <div
          @click="setTemplate(index)"
          class="content col-7 mx-auto mx-xl-0 col-xl-2 py-4 mb-4"
          v-for="(item, index) in templates"
          :key="item.id">
          <img
            :class="`${
              this.selectedTemplate && item.id !== this.selectedTemplate.id
                ? 'opacity'
                : ''
            }`"
            :src="item.thumbnail_url"
            alt="" />
        </div>
      </div>
    </div>
    <div class="footerPage text-center">
      <CButton
        class="gStartedbtn my-5 col-12 col-md-5"
        @click="nextPage()"
        :disabled="!(selectedTemplate != null)"
        >NEXT
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectTemplate',
  // eslint-disable-next-line vue/no-unused-icon-picker
  data() {
    return {
      templates: [],
      selectedTemplate: null,
    }
  },
  methods: {
    nextPage() {
      this.$router.push({ name: 'PersonalInfo' })
    },
    setTemplate(id) {
      this.selectedTemplate = this.templates[id]
      this.$store.dispatch('set_template', this.selectedTemplate)
    },
    getTemplate() {
      let self = this
      this.$axios.get('/templates').then((response) => {
        self.templates = response.data.data
        self.templates.forEach((value, index) => {
          self.templates[index]['thumbnail_url'] = this.$utils.imagePath(
            value['thumbnail_path'],
          )
        })
      })
    },
  },
  mounted() {
    this.getTemplate()
  },
}
</script>

<style scoped>
.opacity {
  opacity: 30%;
}
.content:hover {
  outline: 5px solid #1c86ff;
  transition: 0.2s;
}

.content {
  border-radius: 10px;
  max-height: 500px;
  background-color: white;
  cursor: pointer;
}
.active-stepper {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #1c86ff;
  box-shadow: 0px 4px 15px rgba(28, 134, 255, 0.1);
}
.stepper {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #a7a7a7;
  box-shadow: 0px 4px 15px rgb(244 237 253);
}
.line-design {
  background-color: #a7a7a7;
  height: 1px;
}
h6 {
  font-size: 14px;
}
.active-text h6 {
  color: #1c86ff;
}
.text h6 {
  color: #a7a7a7;
}
</style>
