<template>
  <CFormFloating class="w-100" novalidate :validated="true">
    <CFormInput
      id="TextBlock"
      type="text"
      :placeholder="this.block.data.text_label?.placeholder ?? 'Something Cool'"
      v-model="v$.block.value.$model"
      :invalid="v$.block.value.$error" />

    <CFormFeedback
      class="d-flex ms-2"
      :invalid="v$.block.value.$error"
      v-for="error of v$.$errors"
      :key="error.$uid">
      <strong>{{ error.$message }}</strong>
    </CFormFeedback>

    <CFormLabel for="TextBlock">{{
      this.block.data.text_label?.value ?? 'Text Block'
    }}</CFormLabel>
  </CFormFloating>
</template>

<script>
import { computed } from 'vue'

import blockValidation from '@/mixins/blockValidation'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'TextBlock',
  emits: ['update:modelValue'],
  mixins: [blockValidation],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    testMe() {
      this.$emit('validate', this.v$)
      this.block.value = 'Tested!'
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block, v$: useVuelidate() }
  },
  validations() {
    let validationRules = {
      // $autoDirty: true,
    }

    if (this.block.settings.required) {
      validationRules = { ...validationRules, ...{ required } }
    }
    return {
      block: {
        value: validationRules,
      },
    }
  },
}
</script>

<style scoped></style>
