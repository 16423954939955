export const state = {
  sidebarVisible: true,
  sidebarUnfoldable: false,
  theme: 'default',
}

export const actions = {
  ['toggle_sidebar'](context) {
    context.commit('toggleSidebar')
  },
  ['update_sidebar_visibility'](context, payload) {
    context.commit('updateSidebarVisible', payload)
  },
}

export const mutations = {
  toggleSidebar: (state) => {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable: (state) => {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible: (state, payload) => {
    state.sidebarVisible = payload
  },
}

export const getters = {
  ['sidebarVisible'](state) {
    return state.sidebarVisible
  },
  ['sidebarUnfoldable'](state) {
    return state.sidebarUnfoldable
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
