<template>
  <div class="container">
    <div
      role="button"
      @click="$emit('rowClicked', profiles.id)"
      class="row white-card-background py-3 align-items-center">
      <div class="col-md-4 position-relative">
        <img class="image-card" :src="coverImg" alt="" />
        <div
          class="profile-image position-absolute translate-middle top-50 start-50">
          <img :src="profileImg" alt="" />
        </div>
      </div>
      <div class="col-md-8 my-3 my-md-0">
        <h6>{{ profiles?.name ?? 'N/A' }}</h6>
        <h4>
          {{ profiles.inputs_json[1]?.value?.first_name?.value ?? 'N/A' }}
          {{ profiles.inputs_json[1]?.value?.last_name?.value ?? 'N/A' }}
        </h4>
        <h5>{{ profiles.inputs_json[1]?.value?.title?.value ?? 'N/A' }}</h5>
        <div class="row py-2">
          <div class="col-4">
            <h4>-</h4>
            <h5>Taps</h5>
          </div>
          <div class="col-4">
            <h4>{{ profiles.products?.length ?? '0' }}</h4>
            <h5>Active Products</h5>
          </div>
          <div class="col-4">
            <h4>-</h4>
            <h5>Saves</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bl1nk-horizontal-profile-card',
  props: {
    profiles: Object,
  },
  emits: ['rowClicked'],
  computed: {
    coverImg() {
      let headerBlock = this.$utils.getInputByType(
        this.inputs_json,
        'HeaderImageBlock',
      )

      let cardCoverImage = this.$utils.getInputByType(
        headerBlock?.value,
        'cover_image',
        'imageType',
      )
      if (cardCoverImage?.serverID) {
        return (
          this.$imgHostURL +
          '/api/v1/filepond/api?load=' +
          cardCoverImage?.serverID
        )
      } else {
        return require('@/assets/card-assets/default-company-pic.png')
      }
    },
    profileImg() {
      let headerBlock = this.$utils.getInputByType(
        this.inputs_json,
        'HeaderImageBlock',
      )
      let cardProfileImage = this.$utils.getInputByType(
        headerBlock?.value,
        'profile_image',
        'imageType',
      )
      if (cardProfileImage?.serverID) {
        return (
          this.$imgHostURL +
          'api/v1/filepond/api?load=' +
          cardProfileImage?.serverID
        )
      } else {
        return require('@/assets/card-assets/default-profile-pic.png')
      }
    },
  },
}
</script>

<style scoped>
h6 {
  font-weight: 400;
  color: #1c86ff;
  font-size: 14px;
}
h4 {
  font-size: 20px;
  color: #0c0c0c;
  font-weight: 700;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #acacac;
}
.image-card {
  min-width: 144px;
  min-height: 140px;
  width: 100%;
  border-radius: 15px;
}
.white-card-background {
  background-color: #ffffff;
  border-radius: 15px;
}
.profile-image img {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  border-radius: 50%;
}
</style>
