<template>
  <div class="d-flex justify-content-center">
    <Bl1nkButton role="button" class="bl1nk-btn" @click="isVisible = true">
      <CIcon :icon="cilLibraryAdd" size="lg" class="me-1" /> Add Element
    </Bl1nkButton>
  </div>

  <CModal
    :visible="isVisible"
    size="lg"
    @close="
      () => {
        isVisible = false
      }
    ">
    <CModalHeader>
      <CModalTitle>Add Element</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <h3 class="m-0">Customize your Bl1nk</h3>
      <p>Add custom elements to your bl1nk page</p>
      <div class="elements-grid">
        <CCard v-for="input in myInputs" :key="input" class="m-2">
          <CCardBody role="button" @click="addElement(input)">
            <div class="row">
              <div class="col-md-2">
                <img :src="input.thumbnail_path" alt="" class="w-100" />
              </div>
              <div class="col-md-10">
                <CCardTitle>{{ input.title }}</CCardTitle>
                <CCardText>{{ input.description }}</CCardText>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </CModalBody>
  </CModal>
</template>

<script>
import _ from 'lodash'
import { CIcon } from '@coreui/icons-vue'
import { cilPlus, cilLibraryAdd } from '@coreui/icons'

export default {
  name: 'NewElementModal',
  emits: ['add-element', 'update:modelValue'],
  components: [CIcon],
  data() {
    return {
      isVisible: false,
      myInputs: null,
    }
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    inputs: {
      type: Object,
      default: null,
      required: false,
    },
    elementGroup: {
      type: String,
      default: null,
    },
    filterInputs: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    if (this.filterInputs?.length > 0) {
      this.myInputs = this.inputs.filter((input) => {
        return this.filterInputs.includes(input.type)
      })
    } else {
      this.myInputs = this.inputs
    }
  },
  methods: {
    addElement(element) {
      this.isVisible = false
      element.settings = {
        removable: true,
      }

      if (this.elementGroup) {
        element.group = this.elementGroup
      } else {
        element.group = 'General'
      }

      this.$emit('add-element', _.cloneDeep(element))
    },
    selectTemplate(template) {
      template.id = template.id.toString()
      this.isVisible = false
      this.selectedTemplate = template
      this.$emit('selected-element', template)
    },
  },
  setup() {
    return { cilPlus, cilLibraryAdd }
  },
}
</script>

<style scoped>
.elements-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 12px;
}

@media (min-width: 992px) {
  .elements-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card:hover {
  opacity: 0.8;
  background-color: #e5e5e5;
}

.card-body {
  padding: 1rem 1rem;
}
.card-text:last-child {
  font-size: 14px;
}
</style>
