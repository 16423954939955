export const state = {
  selectedTemplate: null,
  layoutName: 'My Layout',
}

export const actions = {
  ['set_template'](context, payload) {
    context.commit('setTemplate', payload)
  },
  ['set_layout_name'](context, payload) {
    context.commit('setLayoutName', payload)
  },
}
export const mutations = {
  setTemplate: (state, template) => {
    state.selectedTemplate = template
  },
  setLayoutName: (state, layoutName) => {
    state.layoutName = layoutName
  },
}
export const getters = {
  getTemplate: (state) => state.selectedTemplate,
  getLayoutName: (state) => state.layoutName,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
