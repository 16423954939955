<template>
  <div class="container my-5">
    <div class="text-center">
      <h1>LET'S GET <span class="bold-font">STARTED</span></h1>
      <h6 class="text-muted mt-3 mb-5">
        Create your BL1NK account, or login to <br />
        an existing one.
      </h6>
    </div>
    <CAlert color="danger" v-for="error in errors" :key="error">
      {{ error[0] ?? error }}
    </CAlert>

    <CForm class="row g-1" @submit.prevent="signUp">
      <CCol md="12">
        <CFormFloating class="mb-4">
          <CFormInput
            type="email"
            id="email"
            placeholder="name@example.com"
            v-model.trim="v$.emailAddress.$model"
            @blur="v$.emailAddress.$touch"
            :invalid="v$.emailAddress.$error" />
          <CFormLabel for="email">Email address</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.emailAddress.$error"
            :invalid="v$.emailAddress.$error">
            Please enter a valid email.
          </CFormFeedback>
          <CFormFeedback class="d-flex ms-2" v-if="errors.email">
            {{ errors.email }}
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol md="12">
        <CFormFloating class="mb-4">
          <CFormInput
            type="text"
            id="username"
            placeholder="User Name"
            v-model.trim="v$.username.$model"
            :invalid="v$.username.$error"
            @blur="v$.username.$touch" />
          <CFormLabel for="username">User Name</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.username.$error"
            :invalid="v$.username.$error">
            This field is required.
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol xs>
        <CFormFloating class="mb-4">
          <CFormInput
            type="text"
            id="first_name"
            placeholder="First Name"
            v-model.trim="v$.firstName.$model"
            :invalid="v$.firstName.$error"
            @blur="v$.firstName.$touch" />
          <CFormLabel for="first_name">First Name</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.firstName.$error"
            :invalid="v$.firstName.$error">
            This field is required.
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol xs>
        <CFormFloating class="mb-4">
          <CFormInput
            type="text"
            id="last_name"
            placeholder="Last Name"
            v-model.trim="v$.lastName.$model"
            :invalid="v$.lastName.$error"
            @blur="v$.lastName.$touch" />
          <CFormLabel for="username">Last Name</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.lastName.$error"
            :invalid="v$.lastName.$error">
            This field is required.
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol md="12">
        <CFormFloating class="mb-4">
          <MazPhoneNumberInput
            id="userPhoneNumber"
            :fetch-country="true"
            v-model.trim="v$.phone.$model"
            show-code-on-list
            color="info"
            :preferred-countries="['LB', 'FR', 'DE', 'US', 'GB']"
            @blur="v$.phone.$touch" />
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.phone.$error"
            :invalid="v$.phone.$error">
            This field is required.
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol md="12">
        <CFormFloating class="mb-4 passwordContainer">
          <CFormInput
            :type="passwordFieldType"
            id="password"
            placeholder="Password"
            v-model.trim="v$.password.$model"
            :invalid="v$.password.$error"
            @blur="v$.password.$touch" />
          <CFormLabel for="password">Password</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.password.$error"
            :invalid="v$.password.$error">
            Password must be at least 8 characters long.
          </CFormFeedback>
          <span
            v-if="passwordFieldType === 'password'"
            @click="showPassword"
            class="visibilityContainer">
            <img src="@/assets/vectors/Visibility.svg" alt="" />
          </span>
          <span
            v-if="passwordFieldType === 'text'"
            @click="showPassword"
            class="visibilityContainer">
            <img src="@/assets/vectors/VIsibility2.svg" alt="" />
          </span>
        </CFormFloating>
      </CCol>

      <CCol md="12">
        <CFormFloating class="mb-4">
          <CFormInput
            type="password"
            id="confirmpassword"
            placeholder="Confirm Password"
            v-model.trim="v$.passwordConfirm.$model"
            :invalid="v$.passwordConfirm.$error"
            @blur="v$.passwordConfirm.$touch" />
          <CFormLabel for="confirmpassword">Confirm Password</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.passwordConfirm.$error"
            :invalid="v$.passwordConfirm.$error">
            Password doesn't match.
          </CFormFeedback>
        </CFormFloating>
      </CCol>

      <CCol md="12">
        <CFormFloating class="mb-4">
          <CFormInput
            type="text"
            id="secretCode"
            placeholder="Secret Code"
            v-model.trim="v$.secretCode.$model"
            :invalid="v$.secretCode.$error"
            @blur="v$.secretCode.$touch" />
          <CFormLabel for="secretCode">Secret Code</CFormLabel>
          <CFormFeedback
            class="d-flex ms-2"
            v-if="v$.secretCode.$error"
            :invalid="v$.secretCode.$error">
            This field is required.
          </CFormFeedback>
        </CFormFloating>
        <div v-if="showmessage">
          <CFormFeedback class="d-flex ms-2" invalid>
            {{ message }}
          </CFormFeedback>
        </div>
      </CCol>

      <CCol md="12">
        <loading v-model:active="isLoading" :is-full-page="true" />
        <CButton
          type="submit"
          class="gStartedbtn col-12 py-2 mt-3"
          :disabled="
            v$.emailAddress.$error ||
            emailAddress.length == 0 ||
            password.length == 0 ||
            v$.password.$error ||
            v$.passwordConfirm.$error ||
            username.length == 0 ||
            secretCode.length == 0 ||
            phone.length == 0
          "
          >SIGN UP</CButton
        >
      </CCol>
    </CForm>

    <div class="bottom text-center my-4">
      <h5 class="mb-3">
        Already have an account?
        <span class="link-text" @click="login"> Sign in</span>
      </h5>
      <p class="text-muted terms-and-conditions">
        By creating an account, you agree to our
        <span class="link-text">Terms</span> and have read <br />
        and acknowledged our
        <span class="link-text">Privacy Statement</span> .
      </p>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { email, minLength, required, sameAs } from '@vuelidate/validators'
import router from '@/router'
import { ref } from 'vue'

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
  name: 'Login',
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    MazPhoneNumberInput,
  },
  data() {
    return {
      passwordFieldType: 'password',
      emailAddress: '',
      username: '',
      firstName: '',
      lastName: '',
      secretCode: '',
      phoneNumber: '',
      password: '',
      passwordConfirm: '',
      showmessage: false,
      message: '',
      phone: null,
      errors: ref([]),
      isLoading: false,
    }
  },
  validations() {
    return {
      emailAddress: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirm: {
        required,
        sameAs: sameAs(this.password),
      },
      username: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      secretCode: {
        required,
      },
      phone: {
        required,
      },
    }
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    signUp() {
      let self = this
      self.isLoading = true
      self.errors.splice(0, this.errors.length)
      this.$axios
        .post('/register', {
          username: self.username,
          first_name: self.firstName,
          last_name: self.lastName,
          email: self.emailAddress,
          phone_number: self.phone,
          password: self.password,
          password_confirmation: self.passwordConfirm,
          secret_code: self.secretCode,
        })
        .then(() => {
          router.push({ name: 'VerifyEmail' })
          self.isLoading = false
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            self.errors = Object.values(error.response.data.errors)
          } else {
            self.errors = [error]
          }
          self.showmessage = true
          self.isLoading = false
        })
    },
    login() {
      this.$router.push({ name: 'Login' })
    },
  },
  mounted() {
    this.secretCode = this.$route.query.code
    var regex = new RegExp('^[A-Z0-9]{10}$')
    if (this.secretCode && regex.test(this.secretCode)) {
      this.$swal
        .fire({
          title: 'Your Secret Code',
          html:
            "<code class='d-block mb-5 mt-2' style='font-size: 38px; color: #3085d6'>" +
            this.secretCode +
            "</code> <p>This code will prove you're the owner of the card in case you lost it</p>",
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'I have saved the code',
        })
        .then((result) => {
          if (!result.isConfirmed) {
            router.go(-1)
          }
        })
    } else {
      this.secretCode = null
      console.log('Card is not valid')
    }
  },
}
</script>

<style scoped>
@import url('maz-ui/css/main.css');

@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}

.passwordContainer {
  position: relative;
}
.visibilityContainer {
  position: absolute;
  top: 20%;
  right: 7%;
  cursor: pointer;
}
</style>
