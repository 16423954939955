//TODO: Clean this file

import 'core-js/stable'
import { createApp } from 'vue'
import App from './App'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueIframe from 'vue-iframes'
import CoreuiVue from '@coreui/vue-pro'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import store from '@/stores'
import router from '@/router'
import apiClient from '@/api-client'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

const app = createApp(App)
app.config.performance = true
app.config.globalProperties.$apiAddress = process.env.VUE_APP_API_ADDRESS
app.config.globalProperties.$imgHostURL = process.env.VUE_APP_IMAGE_HOST_URL
app.config.globalProperties.$axios = apiClient
app.use(store)
app.use(router)
app.use(VueSweetalert2)
app.use(VueIframe)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('loading', VueLoading)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)

import utils from '@/utils/utils'
app.config.globalProperties.$utils = utils

// app.component("Vuelidate", Vuelidate);
app.mount('#app')
