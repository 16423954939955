<template>
  <CButton v-bind="$attrs" color="transparent">
    <slot></slot>
  </CButton>
</template>

<script>
export default {
  name: 'bl1nk-button',
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
  },
}
</script>

<style scoped></style>
