import apiClient from '@/api-client'
import router from '@/router'
import ApiService from '@/services/api.service'

export const state = {
  user: null,
  userMeta: null,
}

export const actions = {
  ['set_user'](context, user) {
    context.commit('setUserData', user)
    return user
  },
  init(context) {
    return new Promise((resolve) => {
      //TODO: This has to be refactored. This is a hack, and not a solution
      setTimeout(() => {
        context
          .dispatch('attempt_user', null, {
            root: true,
          })
          .then((response) => {
            resolve(response)
          })
      }, 500)
    })
  },
  ['attempt_user'](context) {
    return apiClient
      .get('/me')
      .then((response) => {
        context.commit('setUserData', response.data)
        return response.data.data
      })
      .catch((error) => {
        console.log('Inside vuex catch', error.response)
        // if (error.response.status === 401 || error.response.status === 403) {
        //   this.$store.dispatch('logout')
        // }
        context.commit('logoutUser')
        return null
      })
  },
  ['logout'](context) {
    return apiClient
      .delete('/logout')
      .then(() => {
        context.commit('logoutUser')
      })
      .catch(() => {})
  },
}

export const mutations = {
  setUserData: (state, userData) => {
    state.user = userData.data
    if (userData.meta) {
      state.userMeta = userData.meta
      localStorage.setItem('access_token', userData.meta.access_token)
    }
  },
  clearUserData: (state) => {
    state.user = null
    state.userMeta = null
    sessionStorage.clear()
    localStorage.removeItem('access_token')
    localStorage.removeItem('team_id')
    localStorage.clear()
  },
  logoutUser: (state) => {
    mutations.clearUserData(state)
  },
}

export const getters = {
  ['user'](state) {
    return state.user
  },
  ['team'](state) {
    return state.user?.team
  },
  ['userMeta'](state) {
    return state.userMeta
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
