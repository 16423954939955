export default {
  imagePath(path) {
    return process.env.VUE_APP_IMAGE_HOST_URL + '/' + path
  },

  uuid(length = 7) {
    let result = ''
    for (let i = 0; i < length; i++) {
      const random = Math.random()
      result += String.fromCharCode(
        Math.floor(random * 26) + (random < 0.5 ? 65 : 97),
      )
    }
    return result
  },

  getInputByType(inputs, inputType, keyName = 'component_type') {
    if (inputs == null) {
      return null
    }
    return Object.values(inputs).find(
      (e) => e[keyName] !== null && e[keyName] === inputType,
    )
  },
}
