export const state = {
  productOwner: null,
}
export const actions = {
  ['setProductOwner'](context, productOwner) {
    context.commit('setProductOwner', productOwner)
  },
}
export const mutations = {
  ['setProductOwner'](state, ProductOwner) {
    state.productOwner = ProductOwner
  },
}
export const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
