<template>
  <loading v-model:active="isLoading" :is-full-page="true" />

  <div class="row mb-3">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for profiles">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <Bl1nkButton class="w-100 bl1nk-btn" @click="createNewPersonalProfile()">
        <CIcon :icon="cilPlus" size="lg" />
        Create Profile
      </Bl1nkButton>
    </div>

    <div class="container-fluid mt-1">
      <div class="row">
        <div
          v-if="this.$store.state.linkedProductStatus.linkedProductStatus != 0"
          class="col-12 mt-3">
          <CAlert color="danger"
            >You have
            {{ this.$store.state.linkedProductStatus.linkedProductStatus }}
            unlinked product.
            <span
              @click="redirectToProducts()"
              role="button"
              class="product-status"
              >Link now</span
            >
          </CAlert>
        </div>
        <div
          class="col-md-4"
          v-for="profiles in profileItems"
          :key="profiles.id">
          <Bl1nk-Card
            v-bind="profiles"
            @edit-profile-route="(id, team) => editProfileRoute(id, team)"
            @delete-profile="
              (id, name) => deleteMyProfile(id, name)
            "></Bl1nk-Card>
        </div>
      </div>
    </div>
  </div>
  <CSmartPagination
    :activePage="1"
    :pages="maxPages"
    :arrows="false"
    @activePageChange="(page) => activePageChange(page)" />
</template>

<script>
import { CIcon } from '@coreui/icons-vue'
import { cilPlus, cilSearch, cilTrash, cilZoom } from '@coreui/icons'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import editProfileRoute from '@/mixins/editProfileRoute'
import Bl1nkCard from '@/components/bl1nk/bl1nk-card/bl1nk-squared-card'
import _ from 'lodash'
import ApiService from '@/services/api.service'
import moment from 'moment'
export default {
  name: 'myProfile.vue',
  mixins: [editProfileRoute],
  components: {
    CIcon,
    Bl1nkInput,
    Bl1nkButton,
    Bl1nkCard,
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilZoom,
    }
  },
  data() {
    return {
      isLoading: true,
      profileItems: [],
      maxPages: '',
      activePage: '',
      searchValue: '',
    }
  },
  methods: {
    getMyProfilesList: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      this.isLoading = true
      ApiService.fetchMyProfiles(itemsPerPage, searchValue, activePage).then(
        (response) => {
          this.profileItems = response.data
          self.maxPages = response.meta.last_page
          if (response.data.length > 0) {
            this.isLoading = false
            this.emptyState = false
            self.items = response.data
            self.items.forEach((value, index) => {
              self.items[index]['created_at'] = moment(
                self.items[index]['created_at'],
              ).format('MM/DD/YYYY')

              self.items[index] = {
                ...self.items[index],
                status:
                  self.items[index]?.products?.length > 0
                    ? 'Active'
                    : 'Inactive',
              }
            })
          } else {
            this.isLoading = false
            this.emptyState = true
          }
        },
      )
    },
    1000),
    deleteMyProfile(profileID, profileName) {
      this.$swal
        .fire({
          html:
            'Are you sure you want to delete ' +
            '<span style="color: #F33939">' +
            profileName +
            '</span> ?',
          showConfirmButton: false,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: `Delete`,
        })
        .then((result) => {
          if (result.isDenied) {
            this.isLoading = true
            ApiService.deleteMyProfile(profileID).then(() => {
              this.profileItems = this.profileItems.filter(function (value) {
                return value.id !== profileID
              })
              this.isLoading = false
            })
          }
        })
    },
    redirectToProducts() {
      this.$router.push({ name: 'MyProducts' })
    },
    activePageChange(page) {
      this.activePage = page
    },
  },
  mounted() {
    this.getMyProfilesList()
    this.$store.dispatch('getProducts')
  },
  watch: {
    searchValue(newValue) {
      this.getMyProfilesList(null, newValue, null)
    },
    activePage(newValue) {
      this.getMyProfilesList(null, this.searchValue, newValue)
    },
  },
}
</script>

<style scoped>
.product-status {
  text-decoration: underline;
}
</style>
