<template>
  <CFormLabel>{{ option.label }}</CFormLabel>
  <CFormInput
    :key="option"
    :placeholder="option.label"
    :aria-label="option.label"
    v-model="option.value" />
</template>

<script>
import { computed } from 'vue'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'TextOption',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const option = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { option, v$: useVuelidate() }
  },
}
</script>

<style scoped></style>
