<template>
  <div class="maincontainer">
    <CCard class="customized-card align-middle" style="width: 18rem">
      <img class="mx-auto" src="../../assets/png/Bl1nk-getstarted.png" alt="" />
      <CCardBody class="cardbody mt-4">
        <CButton class="gStartedbtn col-12" @click="nextpage"
          >LET’S GET STARTED</CButton
        >
        <CButton class="cancel-btn col-12 mt-3" @click="signIn"
          >SIGN IN</CButton
        >
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'getStarted',
  methods: {
    nextpage() {
      router.push({ path: '/secretcode' })
    },
    signIn() {
      router.push({ path: '/welcomeBack' })
    },
  },
}
</script>

<style scoped>
.maincontainer {
  height: 100vh;
  position: relative;
}
.customized-card {
  box-shadow: none;
  background-color: #f4f9ff;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customized-card img {
  width: 80%;
}
.cardbody {
  padding: 0px;
}
</style>
