<template>
  <CFormFloating class="mb-4 mt-4 col-md-11">
    <CFormInput type="text" v-model="block.value" />
    <CFormLabel for="name">This is a Test Block</CFormLabel>
    <button @click="testMe">Test me</button>
  </CFormFloating>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'TestBlock',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    testMe() {
      this.block.value = 'Tested!'
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block }
  },
}
</script>

<style scoped></style>
