<template>
  <CModal
    alignment="center"
    :visible="defaultModalVisibility"
    @close="$emit('closed')">
    <CModalHeader>
      <slot name="modalHeader"></slot>
    </CModalHeader>
    <CModalBody>
      <slot name="modalBody"></slot>
    </CModalBody>
    <CModalFooter>
      <slot name="modalFooter"></slot>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  props: ['defaultModalVisibility'],
  emits: ['closed'],
  name: 'defaultModal',
  data() {
    return {
      visibleVerticallyCenteredDemo: false,
    }
  },
}
</script>

<style scoped></style>
