<template>
  <div class="row justify-content-between">
    <div class="col-6">
      <slot name="tabs-name"></slot>
    </div>
    <div class="col-3">
      <slot name="additional-btn"></slot>
    </div>
  </div>
  <div>
    <slot name="dynamic-components"></slot>
  </div>
</template>

<script>
export default {
  name: 'bl1nk-tab',
}
</script>

<style scoped></style>
