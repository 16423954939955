<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" :caret="false">
      <img src="@/assets/v3-icons/bells-notification.svg" alt="" />
      <CBadge
        class="position-absolute top-0 end-0"
        color="danger-gradient"
        shape="rounded-pill">
        {{ itemsCount === 0 ? '' : itemsCount }}
      </CBadge>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader>
        <strong>You have {{ itemsCount }} notifications</strong>
      </CDropdownHeader>
      <div v-for="item in items" :key="item.id">
        <router-link class="routerlink-style" :to="{ path: item.data.link }">
          <CDropdownItem v-if="item.readAt === null">
            <em>
              {{ item.data.message }}
            </em>
            <CBadge class="ms-3" color="info" shape="rounded-pill">new</CBadge>
          </CDropdownItem>
          <CDropdownItem v-if="item.readAt !== null">
            <em> {{ item.data.message }}</em>
          </CDropdownItem>
        </router-link>
      </div>
      <CDropdownItem v-if="totalnotifications != 0">
        <router-link :to="{ path: '/notifications' }">
          View all + {{ totalnotifications }}</router-link
        >
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'TheHeaderDropdownNotif',
  data() {
    return {
      itemsCount: 0,
      // eslint-disable-next-line no-undef
      items: ref([]),
      totalnotifications: 0,
    }
  },
  methods: {
    // getNotifications() {
    //   let self = this
    //   this.$axios
    //     .get(this.$apiAddress + '/notifications?page=1&perPage=5')
    //     .then(function (response) {
    //       const responseData = response.data.data
    //       self.items = responseData
    //       responseData.forEach((value, index) => {
    //         self.items[index]['data'] = value.data
    //         self.items[index]['readAt'] = value.readAt
    //       })
    //       self.totalnotifications = response.data.meta.pagination.total
    //
    //     })
    // },
    // getUnreadNotification() {
    //   let self = this
    //   this.$axios
    //     .get(this.$apiAddress + '/notifications/unread-notifications?page=1&perPage=5')
    //     .then(function (response) {
    //       self.itemsCount = response.data.meta.pagination.total
    //     })
    // },
  },
  mounted() {
    // this.getNotifications()
    // this.getUnreadNotification()
  },
}
// <CIcon icon="cil-user-follow" class="text-success" /> No Notifications
// yet
</script>

<style scoped>
.routerlink-style {
  text-decoration: none;
}
</style>
