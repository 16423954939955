<template>
  <div class="content">
    <div class="widget-header">
      <div class="row align-items-center">
        <div class="col-3">
          <img class="w-100" :src="iconPath" alt="" />
        </div>
        <div class="col-9">
          <h3>{{ widgetHeaderTitle }}</h3>
        </div>
      </div>
    </div>
    <div class="widget-body my-3">
      <h5>{{ widgetBody }}</h5>
    </div>
    <div class="widget-footer">
      {{ widgetFooter }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'analytics-widgets',
  props: ['iconPath', 'widgetHeaderTitle', 'widgetBody', 'widgetFooter'],
  // props: {
  //   iconPath,
  //   widgetHeaderTitle,
  //   widgetBody,
  //   widgetFooter
  // },
}
</script>

<style scoped>
.content {
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
}
.widget-body > * {
  font-size: 40px;
  font-weight: 700;
  color: #0c0c0c;
}
.widget-footer {
  color: #acacac;
  font-size: 14px;
}
</style>
