<template>
  <loading v-model:active="isLoading" :is-full-page="false" />

  <div class="row">
    <div class="col-md-9 col-6">
      <Bl1nkInput v-model="searchValue" placeholder="Search for profiles">
        <CIcon :icon="cilSearch" size="lg" />
      </Bl1nkInput>
    </div>
    <div class="col-md-3 col-6">
      <Bl1nkButton class="w-100 bl1nk-btn" @click="triggerDefaultModal()">
        <CIcon :icon="cilPlus" size="lg" /> Create New Team
      </Bl1nkButton>
    </div>
    <div class="col-12" v-if="!emptyState">
      <Bl1nkSmartTable
        :search-value="searchValue"
        :columns="columns"
        :items="items"
        @items-per-page-change="(number) => itemsPerPageChange(number)">
        <template v-slot="slotData">
          <Bl1nkButton
            class="mx-2 bl1nk-btn d-inline-block"
            @click="manageTeam(slotData.item.id)"
            role="button">
            Manage Team
          </Bl1nkButton>
        </template>
      </Bl1nkSmartTable>
    </div>
    <div class="col-12 mt-3" v-else-if="emptyState">
      <div class="dashed-border text-center">
        <Bl1nkButton class="w-100 grey-color">
          <CIcon :icon="cilPlus" size="lg" />
          Shop BL1NK Products
        </Bl1nkButton>
      </div>
    </div>
  </div>
  <default-modal
    :default-modal-visibility="defaultModalVisibility"
    @closed="triggerDefaultModal()">
    <template #modalHeader>
      <h3>Create New Team</h3>
    </template>
    <template #modalBody>
      <CFormFloating>
        <CFormInput
          class="create-team-input"
          type="text"
          id="createTeam"
          placeholder="Team Name"
          v-model="teamName" />
        <CFormLabel for="createTeam">Team Name</CFormLabel>
      </CFormFloating>
    </template>
    <template #modalFooter>
      <CButton
        :disabled="teamName.length <= 0"
        @click="createSubTeam()"
        class="bl1nk-btn"
        >Create Team</CButton
      >
    </template>
  </default-modal>
  <CSmartPagination
    :activePage="1"
    :pages="maxPages"
    :arrows="false"
    @activePageChange="(page) => activePageChange(page)" />
</template>

<script>
import Bl1nkSmartTable from '@/components/bl1nk/bl1nk-table/bl1nk-smart-table'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import DefaultModal from '@/components/modal/defaultModal'
import { CIcon } from '@coreui/icons-vue'
import { cilTrash, cilSearch, cilPlus } from '@coreui/icons'
import ApiWorkspaceService from '@/services/api.workspace.service'
import store from '@/stores'
import _ from 'lodash'
export default {
  name: 'workspace-teams',
  components: {
    Bl1nkSmartTable,
    Bl1nkInput,
    Bl1nkButton,
    DefaultModal,
  },

  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
    }
  },
  data() {
    return {
      searchValue: '',
      defaultModalVisibility: false,
      columns: [
        {
          key: 'id',
          label: 'No',
          _style: {
            width: '10%',
            'min-width': '40px',
          },
          _props: { style: 'width: 8%' },
        },
        {
          key: 'name',
          label: 'Team Name',
          _style: {
            width: '45%',
            'min-width': '250px',
          },
          _props: { style: 'width: 45%' },
        },
        {
          key: 'members',
          label: 'Members',
          _style: {
            width: '20%',
            'min-width': '100px',
          },
          _props: { style: 'width: 10%' },
        },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
          _props: {
            class: 'd-table-cell d-none',
          },
        },
      ],
      items: [],
      teamID: store.state.auth.user?.teams[0].id,
      teamName: '',
      isLoading: true,
      emptyState: false,
      itemsPerPage: '',
      maxPages: '',
      activePage: '',
    }
  },
  methods: {
    itemsPerPageChange(number) {
      this.itemsPerPage = number
    },
    activePageChange(page) {
      this.activePage = page
    },
    triggerDefaultModal() {
      this.defaultModalVisibility = !this.defaultModalVisibility
    },
    getSubTeam: _.throttle(async function (
      itemsPerPage,
      searchValue,
      activePage,
    ) {
      let self = this
      this.isLoading = true
      ApiWorkspaceService.getSubTeams(
        this.teamID,
        itemsPerPage,
        searchValue,
        activePage,
      )
        .then((response) => {
          this.maxPages = response.meta.last_page
          if (response.length !== 0) {
            this.isLoading = false
            this.items = response.data
            this.items.forEach((value) => {
              value['members'] = value['users'].length
            })
          } else {
            this.emptyState = true
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.emptyState = true
          self.isLoading = false
          self.$swal.fire({
            icon: 'error',
            title: error?.response?.data?.message ?? 'An Error Occurred',
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    1000),
    createSubTeam() {
      this.isLoading = true
      ApiWorkspaceService.createSubTeam(this.teamID, this.teamName).then(
        (response) => {
          this.isLoading = false
          this.$swal.fire({
            icon: 'success',
            title: 'Team Successfully Created',
            showConfirmButton: false,
            timer: 1500,
          })
          this.teamName = ''
          this.defaultModalVisibility = !this.defaultModalVisibility
          this.getSubTeam()
        },
      )
    },
    manageTeam(id) {
      this.$router.push({
        name: 'Manage Team',
        params: {
          id: id,
        },
      })
    },
  },
  mounted() {
    this.getSubTeam()
  },
  watch: {
    searchValue(newValue) {
      this.getSubTeam(null, newValue, null)
    },
    itemsPerPage(newValue) {
      this.getSubTeam(newValue, this.searchValue, this.activePage)
    },
    activePage(newValue) {
      this.getSubTeam(this.itemsPerPage, this.searchValue, newValue)
    },
  },
}
</script>

<style scoped></style>
