<template>
  <CFooter class="mt-2 text-end">
    <div class="w-100">
      <a href="https://bl1nk.co" target="_blank">Bl1nk.co</a>
      <span class="ms-1">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://abiroot.com?ref=bl1nk-dashboard" target="_blank">
          Developed by Abiroot</a
        >
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style scoped>
a {
  color: #8d8d8d;
  text-decoration: none;
}
</style>
