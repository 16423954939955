export default {
  mounted: function () {
    let uuid = this.$utils.uuid()
    this.$store.commit('addValidator', {
      name: this.block.type,
      id: uuid,
      validator: this.v$,
    })

    this.block._uuid = uuid
  },
  unmounted() {
    this.$store.commit('removeValidator', {
      name: this.block.type,
      id: this.block._uuid,
    })
  },
}
