<template>
  <div class="card-content p-1 mt-3" id="{{ id }}">
    <div class="card-header position-relative">
      <!--      src="/assets/card-assets/default-company-pic.png":style="`background-image: url(${coverImg});`"-->
      <div class="header-image">
        <img class="w-100" :src="coverImg" alt="" />
      </div>
      <div class="profile-image position-absolute translate-middle">
        <div class="position-relative">
          <img :src="profileImg" alt="" />
          <div class="company-logo position-absolute"></div>
        </div>
      </div>
    </div>
    <CDropdown class="float-end mt-3 me-2 d-none d-md-block">
      <CDropdownToggle class="toggle">
        <CIcon :icon="cilOptions" size="lg"></CIcon>
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          role="button"
          @click="$emit('editProfileRoute', id, team ?? null)">
          Edit This Profile
        </CDropdownItem>
        <!--        <CDropdownItem role="button">Duplicate This Profile</CDropdownItem>-->
        <CDropdownItem role="button" @click="$emit('deleteProfile', id, name)"
          >Delete This Profile</CDropdownItem
        >
      </CDropdownMenu>
    </CDropdown>
    <Bl1nkButton
      class="float-end mt-3 me-2 d-md-none"
      @click="
        () => {
          visibleBottom = !visibleBottom
        }
      ">
      <CIcon :icon="cilOptions" size="lg"></CIcon>
    </Bl1nkButton>
    <div class="card-contents text-center">
      <h6>{{ name ?? 'N/A' }}</h6>
      <h4>
        {{ inputs_json[1]?.value?.first_name?.value ?? 'N/A' }}
        {{ inputs_json[1]?.value?.last_name?.value ?? 'N/A' }}
      </h4>
      <h5>{{ inputs_json[1]?.value?.title?.value ?? 'N/A' }}</h5>
      <hr />
      <div class="container">
        <div class="row py-2">
          <div class="col-4">
            <h4>-</h4>
            <h5>Taps</h5>
          </div>
          <div class="col-4">
            <h4>{{ products?.length ?? '0' }}</h4>
            <h5>Active Products</h5>
          </div>
          <div class="col-4">
            <h4>-</h4>
            <h5>Saves</h5>
          </div>
        </div>
      </div>
    </div>
    <COffcanvas
      placement="bottom"
      :visible="visibleBottom"
      @hide="
        () => {
          visibleBottom = !visibleBottom
        }
      ">
      <COffcanvasHeader>
        <CCloseButton
          class="text-reset"
          @click="
            () => {
              visibleBottom = false
            }
          " />
      </COffcanvasHeader>
      <COffcanvasBody>
        <Bl1nkButton
          @click="$emit('editProfileRoute', id, team ?? null)"
          class="w-100 bl1nk-btn justify-content-center"
          role="button">
          Edit This Profile
        </Bl1nkButton>
        <Bl1nkButton
          @click="$emit('deleteProfile', id, name)"
          class="w-100 bl1nk-btn-delete justify-content-center mt-3"
          role="button">
          Delete This Profile
        </Bl1nkButton>
      </COffcanvasBody>
    </COffcanvas>
  </div>
</template>

<script>
import {
  cilPlus,
  cilSearch,
  cilTrash,
  cilZoom,
  cilOptions,
} from '@coreui/icons'
import { CIcon } from '@coreui/icons-vue'

export default {
  name: 'bl1nk-card',
  components: {
    CIcon,
  },
  emits: ['editProfileRoute', 'deleteProfile'],
  props: {
    id: String,
    name: String,
    template: Object,
    inputs_json: Object,
    team: Object,
    products: Object,
  },
  data() {
    return {
      visibleBottom: false,
    }
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilZoom,
      cilOptions,
    }
  },
  computed: {
    coverImg() {
      let headerBlock = this.$utils.getInputByType(
        this.inputs_json,
        'HeaderImageBlock',
      )

      let cardCoverImage = this.$utils.getInputByType(
        headerBlock?.value,
        'cover_image',
        'imageType',
      )
      if (cardCoverImage?.serverID) {
        return (
          this.$imgHostURL +
          '/api/v1/filepond/api?load=' +
          cardCoverImage?.serverID
        )
      } else {
        return require('@/assets/card-assets/default-company-pic.png')
      }
    },
    profileImg() {
      let headerBlock = this.$utils.getInputByType(
        this.inputs_json,
        'HeaderImageBlock',
      )
      let cardProfileImage = this.$utils.getInputByType(
        headerBlock?.value,
        'profile_image',
        'imageType',
      )
      if (cardProfileImage?.serverID) {
        return (
          this.$imgHostURL +
          'api/v1/filepond/api?load=' +
          cardProfileImage?.serverID
        )
      } else {
        return require('@/assets/card-assets/default-profile-pic.png')
      }
    },
  },
}
</script>

<style scoped>
.card-content {
  background-color: white;
  border-radius: 15px;
}
.profile-image {
  left: 50%;
}
.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.company-logo {
  right: -10%;
  bottom: 10%;
}
.company-logo img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.card-contents {
  margin-top: 60px;
}
h6 {
  font-weight: 400;
  color: #1c86ff;
  font-size: 14px;
}
h4 {
  font-size: 20px;
  color: #0c0c0c;
  font-weight: 700;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #acacac;
}
hr {
  border-top: 3px solid #ececec;
}
.header-image {
  border-radius: 15px;
  min-height: 204px;
  height: 100%;
}
.header-image img {
  border-radius: 15px;
}
.toggle {
  box-shadow: none !important;
  border: none !important;
}
</style>
