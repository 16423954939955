<template>
  <div class="w-100">
    <h3 class="d-none">{{ block.data.text_label?.value ?? 'Image Upload' }}</h3>
    <span>These images will be displayed at the top of your profile</span>
    <div class="dotted-content text-start">
      <CRow class="mt-4">
        <CCol md="12">
          <h3>Cover Photo</h3>
          <file-pond
            :allowFileMetadata="true"
            :fileMetadataObject="{
              imageType: 'cover_image',
            }"
            ref="pond"
            :server="serverDetails"
            accepted-file-types="image/jpeg, image/png"
            :allowRevert="true"
            :allowProcess="false"
            :filePosterMaxHeight="150"
            :instantUpload="true"
            :allowMultiple="false"
            :files="myFiles['cover_image']"
            :required="true"
            :credits="['https://www.abiroot.com?ref=bl1nk_tura', 'Abiroot']"
            :labelIdle="'Drag & Drop your image or <span class=\'filepond--label-action\'> Browse </span>'"
            :imageEditor="coverEditor"
            :imageEditorInstantEdit="false"
            :imageEditorAllowEdit="true"
            :willRenderCanvas="willRenderCanvas"
            @:init="handleFilePondInit"
            @addfile="filePondAddedFile"
            @processfile="fileUploaded"
            v-on:removefile="fileRemoved"
            v-on:pintura:loadprogress="handleEditorLoadprogress($event)" />
        </CCol>
        <CCol md="12">
          <h3>Profile Photo</h3>
          <file-pond
            :allowFileMetadata="true"
            :fileMetadataObject="{
              imageType: 'logo_image',
            }"
            ref="pond"
            :server="serverDetails"
            accepted-file-types="image/jpeg, image/png"
            :allowRevert="true"
            :allowProcess="false"
            :filePosterMaxHeight="150"
            :instantUpload="true"
            :allowMultiple="false"
            :files="myFiles['logo_image']"
            :required="true"
            :credits="['https://www.abiroot.com?ref=bl1nk_tura', 'Abiroot']"
            :labelIdle="'Drag & Drop your image or <span class=\'filepond--label-action\'> Browse </span>'"
            :imageEditor="logoEditor"
            :imageEditorInstantEdit="false"
            :imageEditorAllowEdit="true"
            :willRenderCanvas="willRenderCanvas"
            @:init="handleFilePondInit"
            @addfile="filePondAddedFile"
            @processfile="fileUploaded"
            v-on:removefile="fileRemoved"
            v-on:pintura:loadprogress="handleEditorLoadprogress($event)" />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor'
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css'

import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

// pintura
import {
  createDefaultImageOrienter,
  createDefaultImageReader,
  createDefaultImageWriter,
  legacyDataToImageState,
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  openEditor,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  processImage,
  setPlugins,
} from '@pqina/pintura'

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate)

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileMetadata,
)

export default {
  name: 'HeaderImageBlock',
  emits: ['update:modelValue'],
  components: {
    FilePond,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      serverDetails: {
        url: this.$apiAddress + '/filepond/api',
        process: '/process',
        // revert: '/process',
        revert: null,
        patch: '?patch=',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },

      // filepond
      myEditor: {
        // map legacy data objects to new imageState objects
        legacyDataToImageState: legacyDataToImageState,

        // used to create the editor, receives editor configuration, should return an editor instance
        createEditor: openEditor,

        // Required, used for reading the image data
        imageReader: [createDefaultImageReader],

        // optionally. can leave out when not generating a preview thumbnail and/or output image
        imageWriter: [
          createDefaultImageWriter,
          {
            // We'll resize images to fit a 512 × 512 square
            // targetSize: {
            //   width: 100,
            //   height: 100,
            // },
            store: this.serverDetails,
          },
        ],

        // used to generate poster images, runs an editor in the background
        imageProcessor: processImage,

        // editor options
        editorOptions: {
          // imageCropAspectRatio: 1,
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
        },
      },
      myFiles: [],
    }
  },
  computed: {
    profileEditor() {
      return {
        ...this.myEditor,
        editorOptions: {
          imageCropAspectRatio: 1,
          imageCropLimitToImage: false,
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
        },
      }
    },
    logoEditor() {
      return {
        ...this.myEditor,
        editorOptions: {
          imageCropAspectRatio: 1,
          imageCropLimitToImage: false,
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
        },
      }
    },
    coverEditor() {
      return {
        ...this.myEditor,
        editorOptions: {
          imageCropAspectRatio: 15 / 7,
          imageCropLimitToImage: false,
          imageOrienter: createDefaultImageOrienter(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...markup_editor_locale_en_gb,
          },
        },
      }
    },
  },
  methods: {
    // Let's draw a circle on top of the editor preview when in the crop util
    willRenderCanvas: (shapes, state) => {
      const { utilVisibility, selectionRect, lineColor, backgroundColor } =
        state

      // Exit if crop utils is not visible
      if (utilVisibility.crop <= 0) return shapes

      // Get variable shortcuts to the crop selection rect
      const { x, y, width, height } = selectionRect

      return {
        // Copy all props from current shapes
        ...shapes,

        // Now we add an inverted ellipse shape to the interface shapes array
        interfaceShapes: [
          {
            x: x + width * 0.5,
            y: y + height * 0.5,
            rx: width * 0.5,
            ry: height * 0.5,
            opacity: utilVisibility.crop,
            inverted: true,
            backgroundColor: [...backgroundColor, 0.5],
            strokeWidth: 1,
            strokeColor: [...lineColor],
          },
          // Spread all existing interface shapes onto the array
          ...shapes.interfaceShapes,
        ],
      }
    },
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`
    },

    filePondAddedFile: function (error, file) {
      // file.edit()
      // file.setMetadata('test', 'value')
      // this.$refs.pond.imageEditor().then((editor) => {
      //   editor.createEditor()
      // })
    },

    handleEditorLoadprogress: function (event) {
      // logs: { src:…, dest:… , imageState:…, store:… }
    },
    fileRemoved: function (error, file) {
      this.block.value = this.block.value.filter((value) => {
        return value.serverID !== file.serverId
      })
    },
    fileUploaded: function (error, file) {
      if (!Array.isArray(this.block.value)) {
        this.block.value = []
      }

      this.block.value = this.block.value.filter((value) => {
        return value.imageType !== file.getMetadata('imageType')
      })

      this.block.value.push({
        serverID: file.serverId,
        imageType: file.getMetadata('imageType'),
      })

      // logs: { src:…, dest:… , imageState:…, store:… }
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { block, v$: useVuelidate() }
  },
  beforeMount() {
    if (Array.isArray(this.block.value)) {
      this.block.value.forEach((block) => {
        this.myFiles[block['imageType']] = [
          {
            // the server file reference
            source: block['serverID'],
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
            },
          },
        ]
      })
    }
  },
  validations() {
    return {
      block: {
        value: {
          required,
          email,
          $autoDirty: true,
        },
      },
    }
  },
}
</script>

<style scoped>
@import '@pqina/pintura/pintura.css';
</style>
