<template>
  <div class="container content">
    <h1 class="text-center">WELCOME <span class="bold-font">BACK</span>.</h1>
    <h6 class="text-muted text-center mt-3 mb-5">
      Login to your BL1NK account, and <br />
      start building your card.
    </h6>
    <CFormFloating class="mb-4">
      <CFormInput
        type="email"
        id="email"
        placeholder="name@example.com"
        v-model.trim="v$.emailaddress.$model"
        @blur="v$.emailaddress.$touch"
        :invalid="v$.emailaddress.$error" />
      <CFormLabel for="email">Email address</CFormLabel>
      <CFormFeedback
        class="d-flex ms-2"
        v-if="v$.emailaddress.$error"
        :invalid="v$.emailaddress.$error">
        Please enter a valid email
      </CFormFeedback>
    </CFormFloating>
    <CFormFloating class="mb-2 mt-4 passwordContainer">
      <CFormInput
        :type="passwordFieldType"
        id="password"
        placeholder="name@example.com"
        v-model.trim="v$.password.$model"
        :invalid="v$.password.$error"
        @blur="v$.password.$touch" />
      <CFormLabel for="password">Password</CFormLabel>
      <span
        v-if="passwordFieldType === 'password'"
        @click="showPassword"
        class="visibilityContainer">
        <img src="../../assets/vectors/Visibility.svg" alt="" />
      </span>
      <span
        v-if="passwordFieldType === 'text'"
        @click="showPassword"
        class="visibilityContainer">
        <img src="@/assets/vectors/VIsibility2.svg" alt="" />
      </span>
    </CFormFloating>
    <h6 class="link-text float-end" @click="forgotPassword">
      Forgot your password?
    </h6>
    <div v-if="showmessage" class="mt-0">
      <CFormFeedback class="d-flex ms-2" invalid>
        {{ message }}
      </CFormFeedback>
    </div>
    <loading v-model:active="isLoading" :is-full-page="true" />
    <CButton
      class="gStartedbtn col-12 py-2 mt-4 mb-3"
      @click="gotoTemplate()"
      :disabled="
        v$.emailaddress.$error ||
        emailaddress.length == 0 ||
        password.length == 0
      "
      >SIGN IN
    </CButton>
    <h5>
      Don't have an account?
      <span class="link-text" @click="signUp">Sign Up</span>
    </h5>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

export default {
  name: 'loginPage',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      emailaddress: '',
      password: '',
      showmessage: false,
      message: '',
      passwordFieldType: 'password',
      isLoading: false,
    }
  },
  validations: {
    emailaddress: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'Register' })
    },
    forgotPassword() {
      this.$router.push({ name: 'ForgotPassword' })
    },
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    // login() {
    //   ApiService.login(this.emailaddress, this.password)
    //     .then((response) => {
    //       console.log(response)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // },
    gotoTemplate: function () {
      let self = this
      self.isLoading = true
      this.$axios
        .post('login', {
          email: self.emailaddress,
          password: self.password,
        })
        .then((response) => {
          const metaData = response.data.meta
          if (metaData.verified === false) {
            this.$router.push({ name: 'VerifyEmail' })
          } else {
            this.$store.dispatch('set_user', response.data).then((response) => {
              // if (metaData.has_profile === false && metaData.verified) {
              //   this.$router.replace({ name: 'SelectTemplate' })
              // }
              if (metaData.verified === true) {
                this.$router.replace({ name: 'Dashboard' })
              }
              self.isLoading = false
            })
          }
        })
        .catch(function (error) {
          if (error.response) {
            self.message = error.response.data.message
            self.showmessage = true
            console.log(self.message)
          }
          self.isLoading = false
          console.log('error', error)
        })
    },
  },
}
</script>

<style scoped>
@media (min-width: 900px) {
  .container {
    max-width: 45%;
  }
}

.content {
  margin-top: 7rem;
}

.passwordContainer {
  position: relative;
}

.visibilityContainer {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 7%;
  cursor: pointer;
}
</style>
