<template>
  <div class="row">
    <div class="col-md-9">
      <bl1nk-tab>
        <template #tabs-name>
          <button
            v-for="tab in tabs"
            :key="tab"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab">
            {{ tab }}
          </button>
        </template>
      </bl1nk-tab>
    </div>
    <div
      class="col-md-3"
      v-if="currentTab === 'Analytics' || currentTab === 'Connections'">
      <Bl1nkButton
        class="w-auto mx-2 bl1nk-btn d-inline-block float-end"
        @click="debugTest(slotData.item)"
        role="button">
        View All {{ currentTab }}
      </Bl1nkButton>
    </div>
  </div>

  <div class="row mt-3">
    <bl1nk-tab>
      <template #dynamic-components>
        <component :is="currentTab"></component>
      </template>
    </bl1nk-tab>
  </div>
</template>
<script>
import { ref } from 'vue'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import Bl1nkTab from '@/components/bl1nk/bl1nk-tab/bl1nk-tab'

import Teams from '@/components/Tabs/workspace-teams'
import People from '@/components/Tabs/workspace-people'
import Products from '@/components/Tabs/workspace-products'
import Profiles from '@/components/Tabs/workspace-profiles'
import Analytics from '@/components/Tabs/workspace-analytics'
import Connections from '@/components/Tabs/workspace-connections'
export default {
  name: 'Workspace',
  components: {
    Products,
    Profiles,
    Bl1nkButton,
    Bl1nkTab,
    Teams,
    People,
    Analytics,
    Connections,
  },
  data() {
    return {
      productList: ref([]),
      tabs: [
        'People',
        'Teams',
        'Products',
        'Profiles',
        // 'Analytics',
        // 'Connections',
      ],
      currentTab: this.$store.state.workspaceTabs.tabState,
    }
  },
  watch: {
    currentTab(newVal) {
      this.$store.dispatch('setTabState', newVal)
    },
  },
}
</script>

<style scoped></style>
