<template>
  <div class="white-background p-2">
    <div class="row align-items-center">
      <div class="col-md-2 col-5">
        <img
          class="product-image ms-md-2"
          src="@/assets/card-assets/black-metal-card.png"
          alt="" />
      </div>
      <div class="col-md-7 col-5">
        <h6>{{ items?.type ?? 'N/A' }}</h6>
        <h4>/{{ items?.username ?? 'N/A' }}</h4>
        <h5>No: {{ items.id }}</h5>
        <h6 class="text-muted">{{ items.created_at }}</h6>
      </div>
      <div class="col-2 d-md-none d-block">
        <Bl1nkButton
          @click="
            () => {
              visibleBottom = !visibleBottom
            }
          ">
          <CIcon :icon="cilOptions" size="lg"></CIcon>
        </Bl1nkButton>
      </div>
      <div class="col-1 text-center d-md-block d-none">
        <Bl1nkButton
          @click="$emit('showQr', items)"
          class="w-auto mx-2 bl1nk-btn d-inline-block">
          <CIcon :icon="cilQrCode" size="lg" />
        </Bl1nkButton>
      </div>
      <div class="col-2 d-md-block d-none">
        <Bl1nkButton
          @click="$emit('triggerAssignModal', items)"
          class="w-100 ellipsis bl1nk-btn d-inline-block"
          :active="items?.profile?.name ? true : false"
          role="button"
          >{{ items?.profile?.name ?? 'Link a Profile' }}
        </Bl1nkButton>
      </div>
    </div>
    <COffcanvas
      placement="bottom"
      :visible="visibleBottom"
      @hide="
        () => {
          visibleBottom = !visibleBottom
        }
      ">
      <COffcanvasHeader>
        <CCloseButton
          class="text-reset"
          @click="
            () => {
              visibleBottom = false
            }
          " />
      </COffcanvasHeader>
      <COffcanvasBody>
        <Bl1nkButton
          @click="$emit('triggerAssignModal', items)"
          class="w-100 bl1nk-btn justify-content-center"
          role="button">
          Link Product
        </Bl1nkButton>
        <Bl1nkButton
          @click="$emit('showQr', items)"
          class="w-100 bl1nk-btn justify-content-center mt-3"
          role="button">
          QR
        </Bl1nkButton>
      </COffcanvasBody>
    </COffcanvas>
  </div>
</template>

<script>
import { CIcon } from '@coreui/icons-vue'
import {
  cilFilter,
  cilPlus,
  cilQrCode,
  cilSearch,
  cilTrash,
  cilOptions,
} from '@coreui/icons'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
export default {
  name: 'bl1nk-product-card',
  props: {
    items: Object,
  },
  emits: ['showQr', 'triggerAssignModal'],
  components: {
    CIcon,
    Bl1nkButton,
  },
  setup() {
    return {
      cilTrash,
      cilSearch,
      cilPlus,
      cilFilter,
      cilQrCode,
      cilOptions,
    }
  },
  data() {
    return {
      visibleBottom: false,
    }
  },
}
</script>

<style scoped>
.white-background {
  background-color: #ffffff;
  border-radius: 5px;
}
.product-image {
  max-width: 150px;
  max-height: 150px;
  width: 100%;
}
h6 {
  font-weight: 400;
  color: #1c86ff;
  font-size: 14px;
}
h4 {
  font-size: 20px;
  color: #0c0c0c;
  font-weight: 700;
}
h5 {
  font-size: 16px;
  font-weight: 400;
  color: #0c0c0c;
}
</style>
