<template>
  <CModal
    :visible="isVisible"
    size="lg"
    @close="
      () => {
        isVisible = false
      }
    ">
    <CModalHeader>
      <CModalTitle>Profiles</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <Flicking :options="{ renderOnlyVisible: true }" :plugins="plugins">
        <div
          v-for="template in this.templates"
          class="flicking-panel"
          :key="template">
          <div
            class="clearfix text-center"
            role="button"
            @click="selectTemplate(template)">
            <CImage rounded :src="template.thumbnail_url" class="figure-img" />
            <figcaption class="figure-caption">
              {{ template.name }}
            </figcaption>
          </div>
        </div>
      </Flicking>
    </CModalBody>
  </CModal>
</template>

<script>
import { computed } from 'vue'

import Flicking from '@egjs/vue3-flicking'
import { Perspective } from '@egjs/flicking-plugins'

export default {
  name: 'ProfileModal',
  emits: ['selected-template', 'update:modelValue'],
  components: {
    Flicking: Flicking,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    templates: {
      type: Object,
      default: null,
      required: false,
    },
  },
  methods: {
    selectTemplate(template) {
      template.id = template.id.toString()
      this.isVisible = false
      this.selectedTemplate = template
      this.$emit('selected-template', template)
    },
  },
  data() {
    return {
      selectedTemplate: null,
      list: [0, 1, 2, 3, 4],
      plugins: [new Perspective({ rotate: 0.5 })],
    }
  },
  setup(props, context) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value),
    })

    return { isVisible }
  },
}
</script>

<style scoped>
@import url('@egjs/vue3-flicking/dist/flicking.min.css');
@import url('@egjs/vue3-flicking/dist/flicking-inline.min.css');
@import url('@egjs/flicking-plugins/dist/flicking-plugins.css');

.flicking-panel img {
  max-width: 500px;
  max-height: 300px;
}

.flicking-panel img:hover {
  border: 2px solid black;
}
</style>
