<template>
  <div class="row">
    <div class="col-9">
      <Bl1nkTab>
        <template #tabs-name>
          <button
            v-for="tab in tabs"
            :key="tab"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab">
            {{ tab }}
          </button>
        </template>
      </Bl1nkTab>
    </div>
    <div class="col-3">
      <Bl1nkButton
        class="w-auto mx-2 bl1nk-btn d-inline-block float-end"
        @click="debugTest(slotData.item)"
        role="button">
        View All Analytics
      </Bl1nkButton>
    </div>
  </div>

  <div class="row mt-3">
    <Bl1nkTab>
      <template #dynamic-components>
        <component :is="currentTab"></component>
      </template>
    </Bl1nkTab>
  </div>
</template>
<script>
import { ref } from 'vue'
import Products from '@/components/Tabs/analytics-by-products'
import Profiles from '@/components/Tabs/analytics-by-profiles'
import Bl1nkButton from '@/components/bl1nk/bl1nk-button/bl1nk-button'
import Bl1nkTab from '@/components/bl1nk/bl1nk-tab/bl1nk-tab'
export default {
  name: 'analytics',
  components: { Products, Profiles, Bl1nkButton, Bl1nkTab },
  data() {
    return {
      productList: ref([]),
      tabs: ['Products', 'Profiles'],
      currentTab: 'Products',
    }
  },
}
</script>

<style scoped></style>
