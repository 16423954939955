<template>
  <CRow>
    <CCol md="4">
      <TextBlock
        class="mt-2"
        v-model="blockValues['first_name']"
        :key="blockValues['first_name']" />
    </CCol>
    <CCol md="4">
      <TextBlock
        class="mt-2"
        v-model="blockValues['middle_name']"
        :key="blockValues['last_name']" />
    </CCol>
    <CCol md="4">
      <TextBlock
        class="mt-2"
        v-model="blockValues['last_name']"
        :key="blockValues['last_name']" />
    </CCol>
    <CCol md="6">
      <TextBlock
        class="mt-2"
        v-model="blockValues['prefix']"
        :key="blockValues['prefix']" />
    </CCol>
    <CCol md="6">
      <TextBlock
        class="mt-2"
        v-model="blockValues['suffix']"
        :key="blockValues['suffix']" />
    </CCol>
    <CCol md="6">
      <TextBlock
        class="mt-2"
        v-model="blockValues['title']"
        :key="blockValues['title']" />
    </CCol>
    <CCol md="6">
      <TextBlock
        class="mt-2"
        v-model="blockValues['nickname']"
        :key="blockValues['nickname']" />
    </CCol>

    <CCol md="12">
      <CFormFloating class="mt-2 w-100">
        <CFormTextarea
          v-model="blockValues['address']['value']"
          id="floatingAddresTextArea"
          :floatingLabel="blockValues['address']['data']['text_label']['value']"
          placeholder="Project Manager at Abiroot"
          style="height: 100px"></CFormTextarea>
      </CFormFloating>
    </CCol>

    <CCol md="12">
      <div class="mt-4 w-100">
        <div
          class="d-flex align-items-center justify-content-between flex-wrap text-center">
          <h3>Phone Numbers</h3>
          <Bl1nkButton role="button" class="bl1nk-btn" @click="addPhone">
            <CIcon :icon="cilPlus" size="lg" class="me-1" /> Add Phone Number
          </Bl1nkButton>
        </div>

        <draggable
          class="mt-4"
          :list="blockValues['phones']['value']"
          item-key="name"
          handle=".handle"
          group="phoneNumbers">
          <template #item="{ element, index }">
            <div class="d-flex flex-sm-row flex-column mt-3">
              <div class="handle" role="button">
                <CIcon :icon="cilCursorMove" size="md" class="text-white" />
              </div>
              <PhoneBlock v-model="blockValues['phones']['value'][index]" />
              <div
                class="input-btns"
                v-if="
                  blockValues['phones']['value'] &&
                  blockValues['phones']['value'].length > 1
                ">
                <div
                  class="btn-ghost"
                  role="button"
                  @click="removePhoneElement(element)">
                  <CIcon :icon="cilTrash" size="lg" class="text-danger" />
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </CCol>

    <CCol md="12">
      <div class="mt-5 w-100">
        <div
          class="d-flex align-items-center justify-content-between flex-wrap text-center">
          <h3>Email Addresses</h3>
          <Bl1nkButton role="button" class="bl1nk-btn" @click="addEmail">
            <CIcon :icon="cilPlus" size="lg" class="me-1" /> Add Email Address
          </Bl1nkButton>
        </div>
        <CCol md="12">
          <draggable
            class="mt-4"
            :list="blockValues['emails']['value']"
            item-key="name"
            handle=".handle"
            group="emailAddresses">
            <template #item="{ element, index }">
              <div class="d-flex mt-3">
                <div class="handle" role="button">
                  <CIcon :icon="cilCursorMove" size="md" class="text-white" />
                </div>
                <EmailBlock
                  v-model="blockValues['emails']['value'][index]"
                  class="no-border-radius gray-border no-x-border"
                  :key="index" />
                <div class="input-btns">
                  <div
                    class="btn-ghost"
                    role="button"
                    @click="removeEmailElement(element)"
                    v-if="
                      blockValues['emails']['value'] &&
                      blockValues['emails']['value'].length > 1
                    ">
                    <CIcon :icon="cilTrash" size="lg" class="text-danger" />
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </CCol>
      </div>
    </CCol>
    <CCol xs="6" v-if="1 == 2">
      <div class="mt-2 w-100">
        <CDatePicker
          @date-change="dobDateChange"
          locale="en-US"
          placeholder="Date of Birth"
          :footer="false"
          :indicator="false"
          :cleaner="false" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { computed } from 'vue'

import blockValidation from '@/mixins/blockValidation'
import useVuelidate from '@vuelidate/core'
import draggable from 'vuedraggable'

import TextBlock from '@/components/Blocks/TextBlock'
import EmailBlock from '@/components/Blocks/EmailBlock'
import PhoneBlock from '@/components/Blocks/PhoneBlock'
import Bl1nkInput from '@/components/bl1nk/bl1nk-input/bl1nk-input'
import { CIcon } from '@coreui/icons-vue'
import {
  cilCog,
  cilLibraryAdd,
  cilPlus,
  cilMenu,
  cilMove,
  cilTrash,
  cilCursorMove,
  cilXCircle,
} from '@coreui/icons'

export default {
  name: 'VCardBlock',
  emits: ['update:modelValue'],
  mixins: [blockValidation],
  components: {
    draggable,
    CIcon,
    TextBlock,
    EmailBlock,
    PhoneBlock,
    Bl1nkInput,
  },
  data() {
    return {
      inputs: {
        first_name: {
          label: 'First Name',
          required: true,
        },
        middle_name: {
          label: 'Middle Name',
          required: false,
        },
        last_name: {
          label: 'Last Name',
          required: true,
        },
        nickname: {
          label: 'Nickname',
          required: false,
        },
        prefix: {
          label: 'Prefix',
          required: false,
        },
        suffix: {
          label: 'Suffix',
          required: false,
        },
        title: {
          label: 'Business Title & Position',
          required: false,
        },
        address: {
          label: 'Address',
          required: false,
        },
        date_of_birth: {
          label: 'Date of Birth',
          required: false,
        },
        phones: {
          label: 'Phone Numbers',
          required: true,
        },
        emails: {
          label: 'Emails',
          required: false,
        },
      },
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  beforeMount() {
    for (const [key, value] of Object.entries(this.inputs)) {
      this.blockValues[key] = {
        ...this.blockValues[key],
        data: {
          text_label: {
            value: value.label,
          },
        },
        settings: {
          required: value.required,
        },
      }
    }

    if (
      !this.blockValues['phones']['value'] ||
      this.blockValues['phones']['value'].length <= 0
    ) {
      this.addPhone()
    }

    if (
      !this.blockValues['emails']['value'] ||
      this.blockValues['emails']['value'].length <= 0
    ) {
      this.addEmail()
    }
  },
  methods: {
    dobDateChange(date) {
      this.blockValues['data_of_birth'] = {
        value: date,
      }
    },
    removePhoneElement(element) {
      this.$store.commit('removeValidator', {
        id: element._uuid,
      })
      this.block['value']['phones']['value'] = this.block['value']['phones'][
        'value'
      ].filter((value) => {
        return value !== element
      })
    },
    addPhone: function () {
      if (!this.blockValues['phones']['value']) {
        this.blockValues['phones']['value'] = []
      }

      this.blockValues['phones']['value'].push({})
    },
    removeEmailElement(element) {
      this.$store.commit('removeValidator', {
        id: element._uuid,
      })
      this.block['value']['emails']['value'] = this.block['value']['emails'][
        'value'
      ].filter((value) => {
        return value !== element
      })
    },
    addEmail: function () {
      if (!this.blockValues['emails']['value']) {
        this.blockValues['emails']['value'] = []
      }

      this.blockValues['emails']['value'].push({
        data: {
          text_label: {
            value: 'Email',
          },
        },
        settings: {
          required: false,
        },
      })
    },
  },
  setup(props, context) {
    const block = computed({
      get: () => props.modelValue,
      set: (value) => context.emit('update:modelValue', value.split('T')[0]),
    })

    return {
      block,
      v$: useVuelidate(),
      cilMove,
      cilTrash,
      cilCog,
      cilMenu,
      cilLibraryAdd,
      cilXCircle,
      cilCursorMove,
      cilPlus,
    }
  },
  computed: {
    blockValues() {
      if (!this.block['value']) {
        this.block['value'] = {}
      }
      return this.block['value']
    },
  },
  validations() {
    let validationRules = {
      // $autoDirty: true,
    }

    // if (this.block.settings.required) {
    //   validationRules = { ...validationRules, ...{ required } }
    // }
    return {
      block: {
        value: validationRules,
      },
    }
  },
}
</script>

<style scoped>
@import url('maz-ui/css/main.css');

.input-group-text {
  display: none !important;
}

.handle {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #acacac;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
