<template>
  <div class="maincontainer">
    <CCard
      class="customized-card align-middle text-center"
      style="width: 20rem">
      <CCardTitle>Your secret code</CCardTitle>
      <CCardText class="secretcode my-4"> 2G5FS67GJ9</CCardText>
      <CCardText class="bottom-text">
        This code will prove you’re the owner of the card in case you lost
        it</CCardText
      >
      <CCardBody class="cardbody mt-4">
        <CButton class="gStartedbtn col-12 mb-3" @click="nextpage(1)"
          >I SAVED THE CODE</CButton
        >
        <CButton class="cancel-btn col-12" @click="nextpage(2)">CANCEL</CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'secretCode',
  methods: {
    nextpage(id) {
      if (id === 2) router.push({ path: '/' })
      if (id === 1) router.push({ path: '/login' })
    },
  },
}
</script>

<style scoped>
.maincontainer {
  height: 100vh;
  position: relative;
}

.customized-card {
  box-shadow: none;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  box-shadow: 0px 4px 20px rgba(28, 134, 255, 0.1);
  border-radius: 10px;
}
.cardbody {
  padding: 0px;
}
.secretcode {
  color: #1c86ff;
  font-size: 2.3rem;
  font-weight: bold;
}

.bottom-text {
  color: #252525;
  font-size: 0.8rem;
}
</style>
