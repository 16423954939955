<template>
  <h4 class="text-center">
    You can't access this page because you're not part of a team!
  </h4>
</template>

<script>
export default {
  name: 'NoTeamPage',
}
</script>

<style scoped>
h4 {
  margin-top: 20%;
}
</style>
